// Import statements
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import _ from 'lodash';

// Constants
const MAX_RETRIES = 2;
const RETRY_DELAY = 2000;

const shouldRetryRequest = (jsonResponse, url) => {
  return _.includes(url, 'market_data')
  && _.get(jsonResponse, 'data')
  && _.isEmpty(jsonResponse.data);
};

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const isRootUrl = (url) => {
  const { BASE_URI } = getDynamicAppConfigs().envs;

  return _.includes(url, BASE_URI);
};

const getAuthorizationHeader = (url, authorization) => {
  const mobileToken = getDynamicAppConfigs().mobileToken;

  if(isRootUrl(url) && mobileToken){
    return { Authorization: `Bearer ${mobileToken}`};
  }
  if(authorization) {
    return { Authorization: authorization };
  }
  return {};
};

// Params-related functions
const formatRequestParams = (params, url) => {
  const method = _.get(params, 'method', 'GET');
  const headers = _.get(params, 'headers', {});
  const body = _.get(params, 'body', '');
  const authorization = _.get(params, 'headers.Authorization', undefined);
  const remainingParams = _.omit(params, ['method', 'headers', 'body']);

  const formattedBody = body ? (
    { body: _.isString(body) ? body : JSON.stringify(body) }
  ) : {};

  const formattedAuthorization = getAuthorizationHeader(url, authorization);

  return {
    method,
    headers: { ...defaultHeaders, ...formattedAuthorization, ...headers },
    ...formattedBody,
    ...remainingParams,
  };
};

// Utility functions
const delayPromise = () => new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));

const appendParamsToUrl = (url, newParams) => {
  const params = new URLSearchParams({ ...newParams });
  const separator = url.includes('?') ? '&' : '?';

  return `${url}${separator}${params.toString()}`;
};

// Exported functions and constants
export {
  MAX_RETRIES,
  shouldRetryRequest,
  delayPromise,
  appendParamsToUrl,
  formatRequestParams,
  isRootUrl,
  RETRY_DELAY
};
