import { getDocumentProperty, setDocumentProperty } from 'globals/utils/index';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { useEffect, useMemo, useState } from 'react';

const useUserModeColors = () => {
  const userMode = useMemo(() => getDynamicAppConfigs()?.settingPreferences?.userMode,
    [getDynamicAppConfigs()?.settingPreferences?.userMode]);

  const [originalColors] = useState({
    oiIndicatorCeBar: getDocumentProperty('--buyer-success-color'),
    oiIndicatorPeBar: getDocumentProperty('--buyer-danger-color'),
    strikeCeBg: getDocumentProperty('--buyer-success-color-80'),
    strikePeBg: getDocumentProperty('--buyer-danger-color-80'),
    success: getDocumentProperty('--success'),
    danger: getDocumentProperty('--danger'),
    callOi: getDocumentProperty('--buyer-success-color'),
    putOi: getDocumentProperty('--buyer-danger-color'),
  });

  useEffect(() => {
    if (!userMode) return;

    const {
      oiIndicatorCeBar, oiIndicatorPeBar, strikeCeBg,
      strikePeBg, success, danger, putOi, callOi
    } = originalColors;

    setDocumentProperty('--buyer-success-color', userMode === 'buyer' ? oiIndicatorCeBar : oiIndicatorPeBar);
    setDocumentProperty('--buyer-danger-color', userMode === 'buyer' ? oiIndicatorPeBar : oiIndicatorCeBar);
    setDocumentProperty('--buyer-success-color-80', userMode === 'buyer' ? strikeCeBg : strikePeBg);
    setDocumentProperty('--buyer-danger-color-80', userMode === 'buyer' ? strikePeBg : strikeCeBg);
    setDocumentProperty('--success', userMode === 'buyer' ? success : danger);
    setDocumentProperty('--danger', userMode === 'buyer' ? danger : success);
    setDocumentProperty('--buyer-success-color', userMode === 'buyer' ? callOi : putOi);
    setDocumentProperty('--buyer-danger-color', userMode === 'buyer' ? putOi : callOi);
  }, [userMode]);
};

export default useUserModeColors;
