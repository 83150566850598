const getLabelConfigs = (isEquity) => {
  return {
    placeOrderLabelConfigs: {
      mis: isEquity ? 'MARGIN' : 'INTRADAY',
      nrml: 'CARRY FORWARD',
      cnc: 'DELIVERY',
    },
  };
};

export { getLabelConfigs };
