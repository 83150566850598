import moment from 'moment';
import _ from 'lodash';
import {
  IndexToMonthNames,
  MonthLetterToIndex,
  MonthNamesToIndex,
} from 'habitual-analytics/constants/habitual-configs';
import { DATE_FORMAT } from 'habitual-analytics/dateUtils/dateFormats';
import { getInstrumentDetails, getExpiryType } from 'habitual-analytics/utils';
import { displayInstrumentToSATradingSymbol } from 'habitual-analytics/models/instrument/factory';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

const SPLIT_STRING_NUMBER_REGEX = /(\d{2})(\d|[A-Za-z]{1})(\d{2})/;

export const getPlaceOrderFormattedTradingSymbol = (tradingSymbolObj) => {
  let formattedTradingSymbol;

  if (tradingSymbolObj.isEquity()) {
    formattedTradingSymbol = `${tradingSymbolObj.toString()}-EQ`;
  } else if (tradingSymbolObj.isFuture()) {
    const { symbol, expiryDate } = tradingSymbolObj;
    const formattedExpiryDate = moment(expiryDate, DATE_FORMAT)
      .format('YYMMM')
      .toUpperCase();
    formattedTradingSymbol = `${symbol}${formattedExpiryDate}FUT`;
  } else {
    const { 
      expiryYear, 
      expiryMonthSymbol, 
      expiryDateNumber, 
      instrument 
    } = getInstrumentDetails(tradingSymbolObj.toString());
    const { strikePrice, optionType } = tradingSymbolObj.getSymbolObject();

    const expiryType = getExpiryType(tradingSymbolObj.toString());

    if (expiryType === 'weekly') {
      const expiryMonthNumber = moment(expiryMonthSymbol, 'MMM').format('M');
      const expiryMonthNumberFormat =
        expiryMonthNumber > 9
          ? _.first(IndexToMonthNames[expiryMonthNumber])
          : expiryMonthNumber;
      formattedTradingSymbol = `${instrument}${expiryYear}`;
      formattedTradingSymbol += `${expiryMonthNumberFormat}${expiryDateNumber}`;
      formattedTradingSymbol += `${strikePrice}${optionType}`;
    } else {
      formattedTradingSymbol = `${instrument}${expiryYear}`;
      formattedTradingSymbol += `${expiryMonthSymbol}${strikePrice}${optionType}`;
    }
  }
  return formattedTradingSymbol;
};

// Function to format the expiry date
const formatDate = (expiry) => {
  let fromattedMonth;
  const [year, month, day] = expiry;

  if (MonthLetterToIndex[_.toUpper(month)]) {
    fromattedMonth = MonthLetterToIndex[month];
  } else {
    fromattedMonth = month;
  }

  return moment(`${year}-${fromattedMonth}-${day}`, 'YY-M-DD').format(
    'DDMMMYY'
  );
};

// Function to find the expiry date based on the symbol
const findExpiryDate = (symbol, expiry) => {
  const expiryYear = expiry.slice(0, 2);
  const expiryMonth = MonthNamesToIndex[expiry.slice(2, 5)];

  const expiryDatesArray = MarketUtility.getExpiryDates({
    symbol,
    expiryType: 'monthly',
  });
  const formattedExpiryDate = _.first(
    _.filter(expiryDatesArray, (date) =>
      _.includes(date, `${expiryYear}-${expiryMonth}`)
    )
  );

  return moment(formattedExpiryDate, 'YYYY-MM-DD').format('DDMMMYY');
};

export const getFormattedTradingSymbolObj = (tradingsymbol) => {
  let formattedExpiryDate;
  let formattedTradingSymbol = '';

  if (_.endsWith(tradingsymbol, 'EQ')) {
    formattedTradingSymbol = tradingsymbol.replace('-EQ', '');
    return displayInstrumentToSATradingSymbol(formattedTradingSymbol);
  }

  const symbol = (tradingsymbol ?? '').match(/^[a-zA-Z-&]+/)?.[0] ?? '';
  const expiry = tradingsymbol.substring(symbol.length, symbol.length + 5);
  const optionType = _.endsWith(tradingsymbol, 'FUT')
    ? 'FUT'
    : tradingsymbol.slice(-2);
  const strikePrice = tradingsymbol.slice(
    symbol.length + 5,
    _.endsWith(tradingsymbol, 'FUT') ? -3 : -2
  );

  const splittedArray = expiry.split(SPLIT_STRING_NUMBER_REGEX).filter(Boolean);
  if (splittedArray.length === 3) {
    formattedExpiryDate = formatDate(splittedArray);
  } else {
    formattedExpiryDate = findExpiryDate(symbol, expiry);
  }

  formattedTradingSymbol =
    `${symbol}${formattedExpiryDate}${strikePrice}${optionType}`.trim();

  return displayInstrumentToSATradingSymbol(formattedTradingSymbol);
};
