export const orderTableHeaders = [
  { label: 'Instrument', key: 'instrument' },
  { label: 'Type', key: 'type' },
  { label: 'Buy/Sell', key: 'buySell' },
  { label: 'Quantity', key: 'extraDetails.qty' },
  { label: 'Traded Price', key: 'extraDetails.tradedPrice' },
  { label: 'LTP', key: 'ltp' },
  { label: 'Status', key: 'status' },
];

export const tradeTableHeaders = [
  { label: 'Instrument', key: 'instrument' },
  { label: 'Type', key: 'type' },
  { label: 'Buy/Sell', key: 'buySell' },
  { label: 'Qty', key: 'extraDetails.qty' },
  { label: 'Price', key: 'extraDetails.tradedPrice' },
  { label: 'LTP', key: 'ltp' },
  { label: 'Status', key: 'status' },
];

export const postitionTableHeaders = [
  { label: '', key: 'checkbox' },
  { label: 'Instrument', key: 'instrument' },
  { label: 'Type', key: 'extraDetails.type' },
  { label: 'Buy/Sell', key: 'buySell' },
  { label: 'Qty', key: 'qty' },
  { label: 'Buy Avg', key: 'buyAvg' },
  { label: 'Sell Avg', key: 'sellAvg' },
  { label: 'LTP', key: 'ltp' },
  { label: 'P/L', key: 'profitLoss' },
];

export const holdingTableHeaders = [
  { label: 'ISIN', key: 'extraDetails.isin' },
  { label: 'Instrument', key: 'instrument' },
  { label: 'Normal Qty/ MTF QTY', key: 'extraDetails.quantity' },
  { label: 'T1 Qty/ T1 MTF QTY', key: 'extraDetails.t1Qty' },
  { label: 'Used Qty/ MTF Used Qty', key: 'extraDetails.usedQty' },
  { label: 'LTP', key: 'ltp' },
];
