import moment from 'moment';
import _ from 'lodash';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  parseExchangeTokenTradingSymbol,
} from '../utils';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
} from '../tradingSymbolParser';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import { 
  getTradingSymbolsByExchangeTokens 
} from 'habitual-analytics/api/services/getTradingSymbolsByExchangeTokens';
import Instrument from 'habitual-analytics/models/instrument/index';
import {
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';

const parseProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'l':
      type = 'Limit';
      break;
    case 'mkt':
      type = 'Market';
      break;
    case 'sl-m':
      type = 'StopMarket';
      break;
    case 'sl':
      type = 'StopLimit';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductCode = (pcode) => {
  let productCode;
  switch (_.toLower(pcode)) {
    case 'cnc':
      productCode = 'CNC';
      break;
    case 'mis':
      productCode = 'MIS';
      break;
    case 'nrml':
      productCode = 'NRML';
      break;
    default:
      productCode = '';
  }
  return productCode;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.OrderStatus)) {
    case 'cancelled':
      status = statusConfigs.cancelled.value;
      break;
    case 'filled':
      status = statusConfigs.executed.value;
      break;
    case 'rejected':
      status = statusConfigs.failed.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.fillshares
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const isNseSegement = (exchange) => {
  return exchange === 'NSECM' || exchange === 'NSEFO';
};

const parseOrderBook = (orderDetail) => {
  const exchangeSegment = _.get(orderDetail, 'ExchangeSegment', '');

  if (!isNseSegement(exchangeSegment)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'TradingSymbol', '');
  const productType = _.get(orderDetail, 'OrderType', '');
  const productCode = _.get(orderDetail, 'ProductType', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'OrderStopPrice', 0));
  const price = convertToNumber(_.get(orderDetail, 'OrderPrice', 0));
  const tradedQty = _.get(orderDetail, 'CumulativeQuantity', 0);
  const quantity = Number(_.get(orderDetail, 'OrderQuantity', 0));
  const avgTradedPrice = _.get(orderDetail, 'OrderAverageTradedPrice', 0);
  const avgPrice = convertToNumber(
    !_.isEmpty(avgTradedPrice) ? avgTradedPrice : 0
  );
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice
        : price;
  const tradedTime = _.get(orderDetail, 'LastUpdateDateTime', '').split(
    ' '
  )?.[1];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);

  return {
    tradingSymbolObj: getFormattedTradingSymbolObject(tradingSymbol),
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'OrderSide', '') === 'SELL'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.toString(_.get(orderDetail, 'AppOrderID', '')),
    },
  };
};
const parseTradeBook = (orderDetail) => {
  const exchangeSegment = _.get(orderDetail, 'ExchangeSegment', '');

  if (!isNseSegement(exchangeSegment)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'TradingSymbol', '');
  const productType = _.get(orderDetail, 'OrderType', '');
  const productCode = _.get(orderDetail, 'ProductType', '');
  const tradedQuantity = Number(_.get(orderDetail, 'LastTradedQuantity', 0));
  const quantity = Number(_.get(orderDetail, 'OrderQuantity', 0));
  const status = statusConfigs.executed.label;

  return {
    tradingSymbolObj: getFormattedTradingSymbolObject(tradingSymbol),
    time: moment(
      _.get(orderDetail, 'LastExecutionTransactTime', '')?.split(' ')?.[1],
      TIME_FORMAT
    ).format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'OrderSide', '') === 'SELL'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'LastTradedPrice', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchangeSegment = _.get(orderDetail, 'ExchangeSegment', '');

  if (!exchangeSegment) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'TradingSymbol', '');
  const ltp = convertToNumber(_.get(orderDetail, 'ltp', 0));
  const qty = Number(_.get(orderDetail, 'Quantity', 0));
  const buyAvg = _.round(_.get(orderDetail, 'BuyAveragePrice', 0), 2);
  const sellAvg = _.round(_.get(orderDetail, 'SellAveragePrice', 0), 2);
  const netAmount = _.round(_.get(orderDetail, 'NetAmount', 0), 2);
  const realisedprofitloss = qty === 0 ? netAmount : 0;
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const currentProfitLoss = ltp * qty + netAmount;
  const profitLoss =
    qty === 0
      ? realisedprofitloss
      : _.round(currentProfitLoss, 2) + realisedprofitloss;

  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg: buyAvg,
    sellAvg: sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'ProductType', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'ProductType', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  // Not handle exchange for now
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const exchangeToken = _.get(orderDetail, 'ExchangeNSEInstrumentId', '');
  const symbol = tradingSymbolsByExchangeTokens[exchangeToken];
  const quantity = _.get(orderDetail, 'HoldingQuantity', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'BuyAvgPrice', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'ltp', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);
  const tradingSymbolObj = new Instrument({ symbol });

  const result = {
    tradingSymbolObj,
    ltp,
    Nsetsym: symbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      order: orderDetail,
    },
  };

  return result;
};

const parseHoldingsBook = async (orderDetail) => {
  let holdingsArr = [];

  if (!_.isArray(orderDetail)) {
    holdingsArr = _.flatMap(orderDetail, (nestedObject) =>
      Object.values(nestedObject)
    );
  } else {
    holdingsArr = orderDetail;
  }

  const exchangeTokens = _.map(holdingsArr, (arr) =>
    _.get(arr, 'ExchangeNSEInstrumentId', '')
  );
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const missingTokens = _.filter(exchangeTokens, (token) =>
    _.isEmpty(tradingSymbolsByExchangeTokens[token])
  );

  if (missingTokens.length > 0) {
    const fetchedTokens = await getTradingSymbolsByExchangeTokens(
      missingTokens
    );
    const mergedTokens = _.merge(
      {},
      tradingSymbolsByExchangeTokens,
      fetchedTokens
    );
    initDynamicAppConfigs({
      tradingSymbolsByExchangeTokens: mergedTokens,
    });
  }

  return holdingsArr.map((arr) => parseSubHoldingBook(arr));
};

const parsePlaceOrder = async (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);

  return {
    exchangeSegment:
      _.get(orderConfig, 'exch', '') === 'NFO' ? 'NSEFO' : 'NSECM',
    exchangeInstrumentID: symbolId,
    productType: parseProductCode(_.get(orderConfig, 'pCode', '')),
    orderType: parseProductType(_.get(orderConfig, 'prctyp', '')),
    orderSide: _.get(orderConfig, 'transactionType', '').toUpperCase(),
    timeInForce: _.get(orderConfig, 'ret', ''),
    disclosedQuantity: _.get(orderConfig, 'disCloseQty', ''),
    orderQuantity: _.get(orderConfig, 'qty', ''),
    limitPrice: _.get(orderConfig, 'price', ''),
    stopPrice: _.get(orderConfig, 'trigPrice', ''),
    orderUniqueIdentifier: brokerClientId,
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
};
