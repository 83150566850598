import qs from 'qs';

const THEME_VARIANT_QUERY_PARAM = 'theme_variant';

export const updateUrlAnalyticsQueryParams = (key, value) => {
  const queryParams = new URLSearchParams(`${window.location.hash}&${window.location.search}`);
  queryParams.set(key, value);

  // Replace current querystring with the new one.
  window.history.pushState(null, null, `?${queryParams.toString()}`);
};

export const getAnalyticsUrlParams = (isSearchParams = false) => {
  const queryString = isSearchParams ? window.location.search : window.location.hash;
  const parsedQueryString = qs.parse(queryString, { parseBooleans: true });
  const removedSpecialCharacters = _.reduce(parsedQueryString, (result, value, key) => {
     
    result[key.replace(/[^a-zA-Z0-9 ]/g, '')] = value;
    return result;
  }, {});

  return removedSpecialCharacters;
};

export const getPathWithExistingQueryParams = (existingPath) => {
  const existingParams = new URLSearchParams(window.location.search);
  if (existingParams.get(THEME_VARIANT_QUERY_PARAM)) {
    return `${existingPath}?${THEME_VARIANT_QUERY_PARAM}=${existingParams.get(THEME_VARIANT_QUERY_PARAM)}`;
  }
  return existingPath;
};

export const getRootPath = () => {
  return getPathWithExistingQueryParams('/');
};
