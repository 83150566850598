export const InstaConstants = (() => {
  const windowInstaConstants = window.instaConstants;
  return {
    get: (key) => {
      return _.get(windowInstaConstants, key, undefined);
    },
    isAdmin: () => {
      return windowInstaConstants.isAdmin;
    },
    getTheme: () => {
      return InstaConstants.get('themeVariant');
    },
    isDarkTheme: () => {
      return InstaConstants.getTheme() === 'dark';
    },
    allowBrokerStub: () => {
      return windowInstaConstants.allowBrokerStub;
    },
    brokerDomainConfigs: () => {
      return windowInstaConstants.brokerDomainConfigs;
    },
    getAll: () => {
      return windowInstaConstants;
    },
    isUserPlanActive: () => {
      return windowInstaConstants.isUserPlanActive;
    },
    historicDaysRange: () => {
      return windowInstaConstants.historicDaysRange || 90;
    }
  };
})();