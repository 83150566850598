import moment from 'moment';
import _ from 'lodash';
import { getFixedData } from 'habitual-analytics/common/formatter/number';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
  LOCAL_DATE_STRING_FORMAT,
} from '../../dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import MarketUtility from 'habitual-analytics/utils/marketUtility';
import Instrument from 'habitual-analytics/models/instrument/index';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  isValidSymbolForInsta,
  parseExchangeTokenTradingSymbol,
  exchangeSegement
} from '../utils';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
} from '../tradingSymbolParser';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import {
  getInstrumentDetailsByExchangeTokens
} from 'habitual-analytics/api/services/getInstrumentDetailsByExchangeTokens';
import {
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import { parseOrderDetailToGetTradingSymbolObj } from './workmate';
import dayjs from 'dayjs';

const brokerParseTradingSymbol = (tradingSymbolObj) => {
  if (tradingSymbolObj.isOption()) {
    return `${tradingSymbolObj.symbol}${moment(
      tradingSymbolObj.expiryDate
    // eslint-disable-next-line max-len
    ).format('DDMMMYY')}${tradingSymbolObj.strikePrice}${tradingSymbolObj.optionType === 'CE' ? 'CE' : 'PE'}`.toUpperCase();
  } else if (tradingSymbolObj.isFuture()) {
    return `${tradingSymbolObj.symbol}${moment(
      tradingSymbolObj.expiryDate
    ).format('DDMMMYY')}FUT`.toUpperCase();
  } else {
    return `${tradingSymbolObj.getSymbol()}-EQ`;
  }
};

const isNseSegement = (exchange) => {
  return exchange === 'NSE' || exchange === 'NFO';
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.orderDisplayStatus)) {
    case 'cancelled':
      status = statusConfigs.cancelled.value;
      break;
    case 'executed':
    case 'complete':
      status = statusConfigs.executed.value;
      break;
    case 'rejected':
      status = statusConfigs.failed.value;
      break;
    case 'pending':
    case 'trigger pending':
      status = statusConfigs.pending.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.fillshares
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseTradingSymbolByExchangeToken = async (exchangeToken) => {
  const exchangeTokens = _.map(exchangeToken, (arr) =>
    _.get(arr, 'scripToken', '')
  );
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const missingTokens = _.filter(exchangeTokens, (token) =>
    _.isEmpty(tradingSymbolsByExchangeTokens[token])
  );
  if (missingTokens.length > 0) {
    const fetchedDetails = await getInstrumentDetailsByExchangeTokens(
      missingTokens
    );
    const formattedFetchDetails = _.keyBy(fetchedDetails, 'exchange_token');
    const mergedDetails = _.merge({}, tradingSymbolsByExchangeTokens, formattedFetchDetails);
    initDynamicAppConfigs({
      tradingSymbolsByExchangeTokens: mergedDetails,
    });
  }
};

const parsePlaceOrder = async (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = brokerParseTradingSymbol(tradingSymbolObj);
  const exchangeSegement = tradingSymbolObj?.isEquity() ? 'NSE' : 'NFO';
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);
  const actionType =
    _.get(orderConfig, 'transactionType', '').toString() == 'buy' ? 'B' : 'S';

  return {
    productAlias:
      'NRML:NRML||CNC:CNC||SHORTSELL:SHORTSELL||MIS:MIS||CO:CO||BO:BO||MTF:MTF',
    cuserid: brokerClientId,
    tradingSymbol: formattedTradingSymbol,
    exchangeName: exchangeSegement,
    scripToken: symbolId,
    actionType: actionType,
    validity: _.get(orderConfig, 'ret', '').toString(),
    quantity: _.get(orderConfig, 'qty', '').toString(),
    disclosedQuantity: _.get(orderConfig, 'disCloseQty', '').toString(),
    price: _.get(orderConfig, 'price', '').toString(),
    triggerPrice: _.get(orderConfig, 'trigPrice', '').toString(),
    stoplossTriggerPrice: null,
    orderType: _.get(orderConfig, 'prctyp', '').toString(),
    productType: _.get(orderConfig, 'pCode', '').toString(),
    advanceOrderType: 'RGLR',
    dp: null,
    stopLossPrice: null,
    targetPrice: null,
    trailingStopLoss: null,
    isModifyOrder: false,
  };
};

const parseCancelOrder = (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = brokerParseTradingSymbol(tradingSymbolObj);
  const exchangeSegement = tradingSymbolObj?.isEquity() ? 'NSE' : 'NFO';

  return {
    cuserid: brokerClientId,
    nestOrderNumber: _.get(orderConfig, 'orderNo', ''),
    exchangeName: exchangeSegement,
    scripName: formattedTradingSymbol,
    orderStatus: _.get(
      orderConfig,
      'orderDetails.extraDetails.orderDetail.orderStatus',
      ''
    ),
    productAlias:
      'NRML:NRML||CNC:CNC||SHORTSELL:SHORTSELL||MIS:MIS||CO:CO||BO:BO||MTF:MTF',
  };
};

const parseModifyOrder = async (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = brokerParseTradingSymbol(tradingSymbolObj);

  const exchangeSegement = tradingSymbolObj?.isEquity() ? 'NSE' : 'NFO';
  const actionType =
    _.get(orderConfig, 'transactionType', '') === 'buy' ? 'B' : 'S';
  const orderType = _.get(orderConfig, 'prctyp', '').toString();

  const parseOptions = {
    cuserid: brokerClientId,
    exchangeName: exchangeSegement,
    scripName: formattedTradingSymbol,
    nestOrderNumber: _.get(orderConfig, 'orderNo', ''),
    actionType: actionType,
    orderType: orderType,
    price: _.get(orderConfig, 'actualPrice', ''),
    quantity: _.get(orderConfig, 'qty', '').toString(),
    validity: _.get(orderConfig, 'ret', '').toString(),
    orderStatus: 'Trigger Pending',
    filledQuantity: _.get(orderConfig, 'filledQuantity', 0),
    productType: _.get(orderConfig, 'pCode', '').toString(),
    productAlias:
      'NRML:NRML||CNC:CNC||SHORTSELL:SHORTSELL||MIS:MIS||CO:CO||BO:BO||MTF:MTF',
    triggerPrice: _.get(orderConfig, 'trigPrice', '').toString(),
    disclosedQuantity: _.get(orderConfig, 'disCloseQty', '').toString(),
  };

  if (_.includes(['SL-LMT', 'SL-MKT'], orderType)) {
    parseOptions.triggerPrice = getFixedData(
      _.get(orderConfig, 'trigPrice', '')
    ).toString();
  }
  return parseOptions;
};

const parseMarginCalculator = async (orderConfig, brokerClientId) => {
  const transactionType =
    _.get(orderConfig, 'transactionType', '') === 'buy' ? 'B' : 'S';
  const tradingSymbolObj = _.get(orderConfig, 'tradingSymbolObj', {});
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);

  return {
    cuserid: brokerClientId,
    brokerName: 'INDIABULLS',
    exchangeName: exchangeSegement(tradingSymbolObj),
    exchangeSegmentName: exchangeSegement(tradingSymbolObj).toLowerCase(),
    scripToken: symbolId,
    productType: _.get(orderConfig, 'pCode', ''),
    branchId: 'ESHUBH ',
    price: _.get(orderConfig, 'price', ''),
    quantity: _.get(orderConfig, 'qty', ''),
    actionType: transactionType,
    orderType: _.get(orderConfig, 'prctyp', ''),
  };
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchangeName', '');
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
  if (
    !isNseSegement(exchange) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const status = sanitizeAndParseOrderStatus(orderDetail);
  const tradedTime = _.get(orderDetail, 'exchangeDateTime', '');
  const failedReason = _.get(orderDetail, 'rejectionReason', '');
  const productType = _.get(orderDetail, 'orderType', '');
  const productCode = _.get(orderDetail, 'productCode', '');
  const isCancellableOrder = getCancellableOrderStatus(status);
  const quantity = _.get(orderDetail, 'quantity', 0);
  const tradedQty = _.get(orderDetail, 'filledQuantity', 0);
  const avgPrice = convertToNumber(_.get(orderDetail, 'averagePrice', 0));
  const price = convertToNumber(_.get(orderDetail, 'price', 0));
  const targetPrice = _.get(orderDetail, 'triggerPrice', 0);
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice.toFixed(2)
        : price.toFixed(2);
  const timeFormated = `${LOCAL_DATE_STRING_FORMAT} ${TIME_FORMAT}`;

  return {
    tradingSymbolObj,
    time: dayjs(tradedTime, timeFormated)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'actionType', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'nestOrderNumber', ''),
      exchOrderID: _.toNumber(_.get(orderDetail, 'exchangeOrderId', '')),
      defaultProductCode: getDefaultProductCode(productCode, tradingSymbolObj),
      defaultProductType: productType,
      orderDetail,
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchangeName', '');
  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }
  const ltp = _.get(orderDetail, 'ltp', 0);
  const qty = Number(_.get(orderDetail, 'openQty', 0));
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;

  const profitLoss = _.get(orderDetail, 'profitandLoss', 0);
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
  const avgPrice = _.get(orderDetail, 'avgPrice', 0);

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    ltp,
    avgPrice,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'productCode', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'productCode', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }
  const data = parseSubPositionBook(orderDetail);
  return data;
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'echangeName', '');

  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'tsym', '');
  const productType = _.get(orderDetail, 'prctyp', '');
  const productCode = _.get(orderDetail, 's_prdt_ali', '');
  const tradedQuantity = Number(_.get(orderDetail, 'flqty', 0));
  const quantity = Number(_.get(orderDetail, 'qty', 0));
  const status = statusConfigs.executed.label;
  const timeFormated = `${LOCAL_DATE_STRING_FORMAT} ${TIME_FORMAT}`;

  return {
    tradingSymbolObj: getFormattedTradingSymbolObject(tradingSymbol),
    time: moment(_.get(orderDetail, 'fltm', ''), timeFormated).format(
      PRETTIER_FORMAT_WITH_SECONDS
    ),
    type:
      _.get(orderDetail, 'trantype', '') === 'S'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'flprc', 0)),
    },
  };
};

const parseSubHoldingBook = (orderDetail) => {
  const tradingSymbolObj = new Instrument({
    symbol: _.get(orderDetail, 'displayScripName', ''),
  });
  const tradingSymbol = _.get(orderDetail, 'displayScripName', '');
  const exchange = _.get(orderDetail, 'exchange', '');
  if (
    !isValidSymbolForInsta(exchange) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const quantity = _.get(orderDetail, 'holdingQty', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'avgPrice', 0));
  const buyValue = quantity * orderValue;
  const ltp = convertToNumber(_.get(orderDetail, 'ltp', 0));
  const profitLoss = getRoundedData(_.get(orderDetail, 'profitandLoss', 0), 2);
  const netChg = getRoundedData((profitLoss / buyValue) * 100);

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${_.get(orderDetail, 'HoldingQuantity', 0)})`,
      buyAverage: orderValue,
      buyValue: buyValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'LTnse',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parseOrderDetails = async (orders, type) => {
  await parseTradingSymbolByExchangeToken(orders);

  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parsePlaceOrder,
  parseCancelOrder,
  parseModifyOrder,
  parseMarginCalculator,
  parseOrderBook,
  parsePositionBook,
  parseTradeBook,
  parseHoldingsBook,
  parseOrderDetails,
};
