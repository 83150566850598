import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';

export const REST_URLS = () => {
  const { envs } = getDynamicAppConfigs();
  const {
    ANALYTICS_TICKER_AUTH,
    ANALYTICS_TICKER_PATH,
    BASE_URI,
    IS_DEV_MODE,
  } = envs;

  return {
    TRADING_VIEW_TICKS: `${BASE_URI}/market_data/tradingview/ticks`,
    TRADING_VIEW_INSTRUMENTS: `${BASE_URI}/market_data/tradingview/instruments`,
    SEARCH_INSTRUMENTS: `${BASE_URI}/external/api/instrument_search`,
    TICKS: `${BASE_URI}/market_data/ticks/candles`,
    TICKS_POINT_IN_TIME: `${BASE_URI}/market_data/ticks/point_in_time`,
    PRE_MARKET_DATA: `${BASE_URI}/market_data/pre_market_data`,
    STRIKE_PRICE: `${BASE_URI}/market_data/instruments/strike_prices`,
    GET_EXCHANGE_TOKEN: `${BASE_URI}/external/api/exchange_token`,
    GET_EXCHANGE_TOKENS: `${BASE_URI}/external/api/exchange_tokens`,
    GET_AXIS_DIRECT_SCRIPT_IDS: `${BASE_URI}/external/api/axis_direct_script_ids`,
    GET_TRADING_SYMBOLS_BY_EXCHANGE_TOKENS: `${BASE_URI}/external/api/trading_symbols_by_exchange_tokens`,
    GET_INSTRUMENT_DETAILS_BY_EXCHANGE_TOKENS:
      `${BASE_URI}/external/api/instrument_details_by_exchange_tokens`,
    FII_AND_DII: `${BASE_URI}/fii_and_dii/index`,
    PCR_DATA: `${BASE_URI}/market_data/ticks/pcr`,
    FIND_BASKETS: `${BASE_URI}/baskets/finder/index`,
    END_OF_DAY_TICKS: `${BASE_URI}/market_data/end_of_day_ticks`,
    ANALYTICS_TICKER_AUTH,
    WATCH_LIST: `${BASE_URI}/watchlist`,
    BASKET_LIST: `${BASE_URI}/user_basket`,
    CREATE_PAYMENT: `${BASE_URI}/payment/init_payment`,
    PAYMENT_VERIFICATION: `${BASE_URI}/payment/payment_verification`,
    GET_PLANS: `${BASE_URI}/plans.json`,
    GET_LIVE_DATA:
      IS_DEV_MODE === 'true'
        ? `${BASE_URI}/external/api/get_live_data`
        : `${BASE_URI}${ANALYTICS_TICKER_PATH}/analytics/ticks`,
    ORDER_COUNTS: `${BASE_URI}/order_counts/create_or_update/`,
    GENERATE_MOBILE_TOKEN: `${BASE_URI}/api/token/generate_refresh_token`,
    GET_USER: `${BASE_URI}/user`,
    UPDATE_USER: `${BASE_URI}/user/update`,
    SIGN_OUT: `${BASE_URI}/sign_out`,
    SITE_CONFIGS: `${BASE_URI}/site_configs`,
    BROKER_PAPER:
    IS_DEV_MODE === 'true'
      ? 'http://localhost:8089'
      : `${BASE_URI}/broker-paper`,
    BROKER_PROXY:
      IS_DEV_MODE === 'true'
        ? 'http://localhost:3000/proxy-broker-api'
        : `${BASE_URI}/broker-proxy/proxy-broker-api`,
  };
};
