import React, { createContext, useState, useEffect } from 'react';
import { isMobileView } from './helper';

export const ResponsiveContext = createContext();

const withResponsiveProvider = (WrappedComponent) => {
  const ResponsiveProvider = (props) => {
    const [isMobile, setIsMobile] = useState(isMobileView());

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(isMobileView());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <ResponsiveContext.Provider value={isMobile}>
        <WrappedComponent {...props} />
      </ResponsiveContext.Provider>
    );
  };

  return React.memo(ResponsiveProvider);
};

export default withResponsiveProvider;
