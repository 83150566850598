import _ from 'lodash';
import moment from 'moment';
import Instrument from 'habitual-analytics/models/instrument/index';
import { DATE_FORMAT } from 'habitual-analytics/dateUtils/dateFormats';
import securityID from './security_id.json';

export const parseOrderDetailToGetTradingSymbolObj = (orderDetail) => {
  if (orderDetail?.instrument_segment === 'EQUITY') {
    const matchingKey = _.findKey(
      securityID,
      (value) => value === orderDetail?.underlying_symbol
    );
    if (matchingKey) {
      return new Instrument({
        symbol: matchingKey,
      });
    }
  } else {
    const instrument = _.get(orderDetail, 'instrument_segment', '');
    const isFutures = _.includes(['FUTIDX', 'FUTSTK'], instrument);
    const isOptions = _.includes(['OPTIDX', 'OPTSTK'], instrument);
    const symbol = _.get(orderDetail, 'underlying_symbol', '');
    const expiryDate =
      isOptions || isFutures
        ? moment(_.get(orderDetail, 'expiry_date', '')).format(DATE_FORMAT)
        : '';
    const strikePrice = isOptions ? _.get(orderDetail, 'strike_price', '') : '';
    const optionType = isOptions
      ? _.get(orderDetail, 'option_type', '') === 'Call'
        ? 'CE'
        : 'PE'
      : '';

    return new Instrument({
      symbol,
      expiryDate,
      strikePrice,
      optionType,
    });
  }
};
