import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import Image from 'globals/components/Image/index';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null, error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo,
      error,
    });
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children } = this.props;

    if (errorInfo && error) {
      Sentry.captureException(error); // sentry capture

      return (
        <div className="vh-100 d-flex align-items-center flex-column justify-content-center text-center p-4">
          <Image src="./images/error.svg" alt="Something went wrong" width="350" />
          <h2 className="mt-5">Something went wrong</h2>
        </div>
      );
    }
    return children;
  }
}

ErrorHandler.propTypes = propTypes;
ErrorHandler.defaultProps = defaultProps;

export default ErrorHandler;
