import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { PRETTIER_FORMAT_WITH_SECONDS } from 'habitual-analytics/dateUtils/dateFormats';
import { displayInstrumentToSATradingSymbol } from 'habitual-analytics/models/instrument/factory';
import moment from 'moment';
import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { isCurrentTimeWithinTradingHours } from 'habitual-analytics/utils/datetime';

const parsePlaceOrder = (order_detail) => {
  const isCurrentTimeValid = isCurrentTimeWithinTradingHours();

  return {
    tradingSymbol: order_detail?.tradingSymbolObj?.toString(),
    instrumentType: order_detail?.tradingSymbolObj?.getInstrumentType(),
    transactionType: order_detail?.transactionType,
    quantity: order_detail?.qty,
    productType: order_detail?.prctyp,
    productCode: order_detail?.pCode,
    price: order_detail?.ltp,
    userId: getDynamicAppConfigs()?.brokerConfigs?.brokerClientId,
    status: isCurrentTimeValid
      ? statusConfigs?.executed?.value
      : statusConfigs?.failed?.value,
    failedReason: isCurrentTimeValid ? '' : 'Market is closed',
  };
};

const parseOrderBook = (order_detail) => {
  return {
    tradingSymbolObj: displayInstrumentToSATradingSymbol(
      order_detail?.trading_symbol
    ),
    type: order_detail?.transaction_type,
    time: moment(order_detail?.created_at).format(PRETTIER_FORMAT_WITH_SECONDS),
    status: order_detail?.status,
    failedReason: order_detail?.failed_reason,
    extraDetails: {
      product: `${order_detail?.product_code} / ${order_detail?.product_type}`,
      qty: `${order_detail?.quantity} / ${order_detail?.quantity}`,
      tradedPrice: order_detail?.price,
    },
  };
};

const parseTradeBook = (order_detail) => {
  return {
    tradingSymbolObj: displayInstrumentToSATradingSymbol(
      order_detail?.trading_symbol
    ),
    time: moment(order_detail?.created_at).format(PRETTIER_FORMAT_WITH_SECONDS),
    type: order_detail?.transaction_type,
    status: statusConfigs.executed.label,
    extraDetails: {
      product: `${order_detail?.product_code} / ${order_detail?.product_type}`,
      qty: `${order_detail?.quantity} / ${order_detail?.quantity}`,
      tradedPrice: order_detail?.price,
    },
  };
};

const parsePositionBook = (orderDetails, isDBFormat) => {
  if (isDBFormat) {
    const updatedOrderDetails = getUpdatedOrderDetails(orderDetails);
    return parseSubPositionBook(updatedOrderDetails);
  } else {
    return orderDetails.map((order) => parseSubPositionBook(order));
  }
};

const getUpdatedOrderDetails = (orderDetails) => {
  if (orderDetails.length === 1) {
    return parseSingleOrderDetail(orderDetails?.[0]);
  } else {
    return parseMultipleOrderDetails(orderDetails);
  }
};

const parseSingleOrderDetail = (orderDetail) => {
  const { price, quantity, transaction_type } = orderDetail;
  const isBuy = transaction_type === transactionTypes.buy.value;

  const buyAvg = isBuy ? parseFloat(price) : 0;
  const sellAvg = !isBuy ? parseFloat(price) : 0;
  const netQty = isBuy ? quantity : -quantity;
  const soldQty = 0;

  const updatedOrderDetails = {
    ...orderDetail,
    buyAvg,
    sellAvg,
    netQty,
    soldQty,
  };

  return parseSubPositionBook(updatedOrderDetails);
};

const parseMultipleOrderDetails = (orderDetails) => {
  const buyOrders = orderDetails.filter(
    ({ transaction_type: type }) => type === transactionTypes.buy.value
  );
  const sellOrders = orderDetails.filter(
    ({ transaction_type: type }) => type === transactionTypes.sell.value
  );

  const buyQty = buyOrders.reduce((acc, order) => acc + order.quantity, 0);
  const sellQty = sellOrders.reduce((acc, order) => acc + order.quantity, 0);

  const buyTotalPrice = buyOrders.reduce(
    (acc, order) => acc + parseFloat(order.price) * order.quantity,
    0
  );
  const sellTotalPrice = sellOrders.reduce(
    (acc, order) => acc + parseFloat(order.price) * order.quantity,
    0
  );

  const buyAvg = buyQty ? buyTotalPrice / buyQty : 0;
  const sellAvg = sellQty ? sellTotalPrice / sellQty : 0;

  const netQty = buyQty - sellQty;
  const soldQty = sellQty && buyQty ? Math.min(buyQty, sellQty) : 0;

  const updatedOrderDetails = {
    ...orderDetails[0],
    buyAvg: _.round(buyAvg, 2),
    sellAvg: _.round(sellAvg, 2),
    netQty: netQty,
    soldQty,
  };

  return parseSubPositionBook(updatedOrderDetails);
};

const parseSubPositionBook = (updatedOrderDetails) => {
  const {
    sellAvg,
    buyAvg,
    soldQty,
    netQty,
    ltp = 0,
    trading_symbol,
  } = updatedOrderDetails;

  const type =
    netQty > 0 ? transactionTypes.buy.value : transactionTypes.sell.value;
  const realisedProfitLose = (sellAvg - buyAvg) * soldQty || 0;
  const currentProfitLoss =
    type === transactionTypes.buy.value ? ltp - buyAvg : sellAvg - ltp || 0;
  const profitLoss = currentProfitLoss * Math.abs(netQty) + realisedProfitLose;
  const tradingSymbolObj = displayInstrumentToSATradingSymbol(trading_symbol);

  return {
    ...updatedOrderDetails,
    tradingSymbolObj,
    buyAvg,
    sellAvg,
    qty: netQty,
    profitLoss,
    ltp,
    extraDetails: {
      type: netQty !== 0 ? type : '',
      isOpenPosition: netQty !== 0,
      product: 'MKT',
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      order: updatedOrderDetails,
    },
  };
};

const parseOrderDetails = (orders, type, isDBFormat) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'place_order':
          return parsePlaceOrder(orderDetail);
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail, isDBFormat);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parseOrderDetails,
  parseOrderBook,
  parseTradeBook,
  parsePlaceOrder,
  parsePositionBook,
  getUpdatedOrderDetails,
};
