 
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'globals/components/Button/index';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';

const AutoRefreshModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [reloadCount, setReloadCount] = useState(5);

  useEffect(() => {
    const targetTime = getCurrentDate().set('hour', 9).set('minute', 1).set('second', 0);
    const currentTime = getCurrentDate();
    const timeDifference = targetTime.diff(currentTime, 'seconds');

    if (timeDifference > 0) {
      const timeoutId = setTimeout(() => {
        setShowModal(true);
      }, timeDifference * 1000);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  useEffect(() => {
    if (showModal) {
      if (reloadCount === 1) {
        setShowModal(false);
        window.location.reload();
      } else {
        const timeoutId = setTimeout(() => {
          setReloadCount(reloadCount - 1);
        }, 1000);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [showModal, reloadCount]);

  return (
    <Modal
      size="md"
      show={showModal}
      centered
      className="strike-prices-modal"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
    >
      <Modal.Body>
        <div className="text-center">
          <div className="reloadDouble" />
          <p className="m-0">
            You are currently viewing previous market data.
            <br />
            To view today's market data, please reload the page or click the button below.
          </p>
          <div className="count-seconds">{reloadCount || ''}</div>
          <Button
            onClick={() => window.location.reload()}
            className="btn btn-primary w-50"
          >
            Reload
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AutoRefreshModal;
