import {
  UPDATE_BASKET_OPEN,
  UPDATE_COMBINED_PREMIUM,
  UPDATE_HEADER_CONFIGURATION,
  UPDATE_BASKET_DETAILS,
  UPDATE_FIND_STRATEGY,
  UPDATE_BASKET_SLIDE_OPEN,
  UPDATE_UNDERLYING_PRICE,
  UPDATE_NAVIGATE_TO_BASKET,
  UPDATE_PLACE_ORDER_CONFIG,
  UPDATE_COMMON_CONFIGS,
  UPDATE_GLOBAL_CONFIGS
} from './action/index';

 
export default function (state, action) {
  switch (action.type) {
    case UPDATE_BASKET_OPEN:
      return { ...state, commonConfigs: { ...state.commonConfigs, basketOpen: action.payload } };
    case UPDATE_BASKET_SLIDE_OPEN:
      return { ...state, commonConfigs: { ...state.commonConfigs, isBasketSlideOpen: action.payload } };
    case UPDATE_BASKET_DETAILS:
      return { ...state, basketConfigs: { ...state.basketConfigs, ...action.payload } };
    case UPDATE_COMBINED_PREMIUM:
      return { ...state, commonConfigs: { ...state.commonConfigs, combinedPremiumData: action.payload } };
    case UPDATE_HEADER_CONFIGURATION:
      return { ...state, headerConfigs: { ...state.headerConfigs, ...action.payload } };
    case UPDATE_FIND_STRATEGY:
      return { ...state, findStrategyConfig: { ...state.findStrategyConfig, ...action.payload } };
    case UPDATE_UNDERLYING_PRICE:
      return { ...state, commonConfigs: { ...state.commonConfigs, underlyingPrice: action.payload } };
    case UPDATE_NAVIGATE_TO_BASKET: 
      return { ...state, commonConfigs: { ...state.commonConfigs, isNavigateToBasket: action.payload } };
    case UPDATE_PLACE_ORDER_CONFIG:
      return { ...state, orderPlacerConfig: { ...state.orderPlacerConfig, ...action.payload } };
    case UPDATE_COMMON_CONFIGS:
      return { ...state, commonConfigs: { ...state.commonConfigs, ...action.payload } };
    case UPDATE_GLOBAL_CONFIGS:
      return {
        ...state,
        headerConfigs: { ...state.headerConfigs, ...action.payload?.headerConfigs },
        commonConfigs: { ...state.commonConfigs, ...action.payload?.commonConfigs },
        basketConfigs: { ...state.basketConfigs, ...action.payload?.basketConfigs },
      };
    default:
      return state;
  }
}
