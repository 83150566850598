import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { BROKER_CONFIG } from 'habitual-analytics/constants/habitual-configs';
import { ticker as defaultTicker } from './default/ticker';
// import { ticker as aliceblueTicker } from './aliceblue/ticker';

export const getCurrentLiveTicker = () => {
  const { broker } = getDynamicAppConfigs().brokerConfigs;

  switch (broker) {
    case BROKER_CONFIG.aliceblue.id:
      return defaultTicker(); // need to change aliceblueTicker

    default:
      return defaultTicker();
  }
};
