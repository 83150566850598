import _ from 'lodash';
import Instrument from 'habitual-analytics/models/instrument/index';
import MarketUtility from 'habitual-analytics/utils/marketUtility';
export const parseOrderDetailToGetTradingSymbolObj = (orderDetail) => {
  const instrument = _.get(orderDetail, 'instrument', '');
  const exchange = _.get(orderDetail, 'exchange', '');
  const isFutures = instrument.includes('FUT');
  const isOptions = instrument.includes('OPT');
  const symbol = _.get(orderDetail, 'symbol', '');
  const expiryDate =
    isOptions || isFutures ? _.get(orderDetail, 'expiry_date', '') : '';
  const strikePrice = isOptions
    ? _.get(orderDetail, 'strike_price', '').split('.')[0]
    : '';
  const optionType = isOptions ? _.get(orderDetail, 'option_type', '') : '';
  const isBse = _.startsWith(exchange, 'BSE');

  const tradingSymbolObj = new Instrument({
    symbol,
    expiryDate,
    strikePrice,
    optionType,
  });

  //// clean up below one
  //// exchange==BSE && future order only allow with monthly expiry
  const isNotWeeklyExpiry =
    isBse &&
    isFutures &&
    _.includes(
      MarketUtility.getExpiryDates({
        symbol,
        expiryType: 'monthly',
      }),
      expiryDate
    );

  if (isNotWeeklyExpiry) {
    return {
      isValid: true,
      tradingSymbolObj,
    };
  }

  return {
    isValid: isBse && isFutures ? false : true,
    tradingSymbolObj,
  };
};
