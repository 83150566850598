 
import moment from 'moment';
import _ from 'lodash';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { PRETTIER_FORMAT_WITH_SECONDS, TIME_FORMAT } from '../../dateUtils/dateFormats';
import { statusConfigs, transactionTypes } from '../../constants/habitual-configs';
import { getFormattedMoney } from '../../utils/money';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isValidSymbolForInsta,
  isOrderPartiallyExecuted,
} from '../utils';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
  getPlaceOrderTradingSymbol,
} from '../tradingSymbolParser';

const parseProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'l':
      type = 'LMT';
      break;
    case 'mkt':
      type = 'MKT';
      break;
    case 'sl-m':
      type = 'SL-MKT';
      break;
    case 'sl':
      type = 'SL-LMT';
      break;
    default:
      type = '';
  }
  return type;
};


const parseProductCode = (pcode) => {
  let productCode;
  switch (_.toLower(pcode)) {
    case 'cnc':
      productCode = 'C';
      break;
    case 'mis':
      productCode = 'I';
      break;
    case 'nrml':
      productCode = 'M';
      break;
    default:
      productCode = '';
  }
  return productCode;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.status)) {
    case 'canceled':
      status = statusConfigs.cancelled.value;
      break;
    case 'complete':
      status = statusConfigs.executed.value;
      break;
    case 'rejected':
      status = statusConfigs.failed.value;
      break;
    case isOrderPartiallyExecuted(orderDetail.status, orderDetail.qty, orderDetail.fillshares):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exch', '');

  if (!isValidSymbolForInsta(exchange)) { return null; }

  const tradingSymbol = _.get(orderDetail, 'tsym', '');
  const productType = _.get(orderDetail, 'prctyp', '');
  const productCode = _.get(orderDetail, 's_prdt_ali', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'trgprc', 0));
  const price = convertToNumber(_.get(orderDetail, 'prc', 0));
  const tradedQty = _.get(orderDetail, 'fillshares', 0);
  const quantity = Number(_.get(orderDetail, 'qty', 0));
  const avgPrice = convertToNumber(_.get(orderDetail, 'avgprc', 0));
  const tradedPrice = _.parseInt(targetPrice) !== 0
    ? `${price} / ${targetPrice} trg`
    : _.parseInt(avgPrice) !== 0
      ? avgPrice
      : price;
  const tradedTime = _.get(orderDetail, 'norentm', '').split(' ')?.[0];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'rejreason', '');

  return {
    tradingSymbolObj: getFormattedTradingSymbolObject(tradingSymbol),
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'trantype', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'norenordno', ''),
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exch', '');

  if (!isValidSymbolForInsta(exchange)) { return null; }

  const tradingSymbol = _.get(orderDetail, 'tsym', '');
  const productType = _.get(orderDetail, 'prctyp', '');
  const productCode = _.get(orderDetail, 's_prdt_ali', '');
  const tradedQuantity = Number(_.get(orderDetail, 'filledQty', 0));
  const quantity = Number(_.get(orderDetail, 'qty', 0));
  const status = statusConfigs.executed.label;

  return {
    tradingSymbolObj: getFormattedTradingSymbolObject(tradingSymbol),
    time: moment(_.get(orderDetail, 'fltm', '')?.split(' ')?.[1], TIME_FORMAT).format(
      PRETTIER_FORMAT_WITH_SECONDS
    ),
    type:
      _.get(orderDetail, 'trantype', '') === 'S'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'flprc', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exch', '');

  if (!isValidSymbolForInsta(exchange)) { return null; }

  const tradingSymbol = _.get(orderDetail, 'tsym', '');
  const ltp = convertToNumber(_.get(orderDetail, 'lp'));
  // const orderValue = convertToNumber(_.get(orderDetail, 'bep', 0));
  const qty = Number(_.get(orderDetail, 'netqty', 0));
  const netAvgPrice = convertToNumber(_.get(orderDetail, 'netupldprc', 0));
  const buyAvg = Number(qty) > 0 ? netAvgPrice : 0;
  const sellAvg = Number(qty) < 0 ? netAvgPrice : 0;
  // const profitAndLoss = _.round(_.get(orderDetail, 'urmtom', 0), 2);
  const realisedprofitloss = _.round(_.get(orderDetail, 'rpnl', 0), 2);
  const type = Number(qty) < 0 ? transactionTypes?.sell?.value : transactionTypes?.buy?.value;
  const currentProfitLoss = type === transactionTypes.buy.value ? ltp - netAvgPrice : netAvgPrice - ltp;
  const profitLoss = qty === 0
    ? realisedprofitloss :(_.round(currentProfitLoss, 2) * Math.abs(qty)) + realisedprofitloss;
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 's_prdt_ali', ''),
      liveUpdateDetails: { symbol: tradingSymbolObj.toString(), value: 'ltp', key: 'lp' },
      defaultProductCode: getDefaultProductCode(_.get(orderDetail, 's_prdt_ali', ''),  tradingSymbolObj),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exch_tsym[0][exch]', '');

  if (!isValidSymbolForInsta(exchange)) { return null; }

  const tradingSymbol = _.get(orderDetail, 'exch_tsym[0][tsym]', '');
  const quantity = _.get(orderDetail, 'npoadqty', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'upldprc', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'LTnse', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: { symbol: tradingSymbolObj.toString(), value: 'ltp', key: 'LTnse' },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);
  
  return {
    uid: brokerClientId,
    actid: brokerClientId,
    exch: _.get(orderConfig, 'exch', ''),
    tsym: formattedTradingSymbol,
    qty: _.get(orderConfig, 'qty', '').toString(),
    prc: _.get(orderConfig, 'price', '').toString(),
    trgprc: _.get(orderConfig, 'trigPrice', '').toString(),
    prd: parseProductCode(_.get(orderConfig, 'pCode', '')),
    trantype: _.first(_.get(orderConfig, 'transactionType', '').toUpperCase()),
    prctyp: parseProductType(_.get(orderConfig, 'prctyp', '')),
    ret: _.get(orderConfig, 'ret', ''),
    remarks: 'INSTAOPTIONS'
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
};
