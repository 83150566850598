import Image from 'globals/components/Image/index';
import React from 'react';

const LiveTickerError = () => {
  return (
    <div className="live-ticker-error">
      <div className="error-status h-100">
        <div className="col-md-6 text-center">
          <Image src="./images/error.svg" alt="Something went wrong" width="350" />
          <h1 className="my-3">DNS Server Not Responding</h1>
          <p>
            <q>
              There is some issue connecting to Live Data Servers.
              Please check your network / DNS settings and make sure your are internet connection is good.
              If you still face issues, discuss with your network admin or contact our support.
            </q>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LiveTickerError;
