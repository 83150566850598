import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isValidSymbolForInsta,
  isOrderPartiallyExecuted,
} from '../utils';
import _ from 'lodash';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import MarketUtility from 'habitual-analytics/utils/marketUtility';
import {
  parseTradingSymbolByExchangeToken,
  parseTradingSymbolObj,
} from './workmate';
import moment from 'moment';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import { getDefaultProductCode, getPlaceOrderTradingSymbol } from '../tradingSymbolParser';

const parseOrderType = (orderType) => {
  let type;
  switch (orderType) {
    case 'l':
      type = 'LIMIT';
      break;
    case 'mkt':
      type = 'MARKET';
      break;
    case 'sl':
      type = 'STOPLOSS_LIMIT';
      break;
    case 'sl-m':
      type = 'STOPLOSS_MARKET';
      break;
    default:
      type = '';
      break;
  }
  return type;
};

const parseProductType = (productType) => {
  let type;
  switch (productType) {
    case 'cnc':
      type = 'DELIVERY';
      break;
    case 'mis':
      type = 'INTRADAY';
      break;
    case 'nrml':
      type = 'CARRYFORWARD';
      break;
    default:
      type = '';
      break;
  }
  return type;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.status)) {
    case 'cancelled':
      status = statusConfigs.cancelled.value;
      break;
    case 'executed':
      status = statusConfigs.executed.value;
      break;
    case 'rejected':
      status = statusConfigs.failed.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.fillshares
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseOrderBook = async (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);

  await parseTradingSymbolByExchangeToken(orderDetail);

  if (isArrayDetails) {
    return _.map(orderDetail, parseSubOrderBook);
  }

  return parseSubOrderBook(orderDetail);
};

const parseTradeBook = async (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);

  await parseTradingSymbolByExchangeToken(orderDetail);

  if (isArrayDetails) {
    return _.map(orderDetail, parseSubTradeBook);
  }

  return parseSubTradeBook(orderDetail);
};

const parseHoldingsBook = async (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);

  await parseTradingSymbolByExchangeToken(orderDetail);

  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePositionBook = async (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);

  await parseTradingSymbolByExchangeToken(orderDetail);

  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const tradingSymbolObj = parseTradingSymbolObj(orderDetail);
  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  if (!isValidSymbolForInsta(exchange, tradingSymbolObj.toString())) {
    return null;
  }

  const tradedTime = _.get(orderDetail, 'order_date_time', '').split(' ')?.[1];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'reason', '');
  const productCode = _.get(orderDetail, 'product_type', '');
  const productType = _.get(orderDetail, 'order_type', '');
  const quantity = _.get(orderDetail, 'quantity', '');
  const tradedQuantity = _.get(orderDetail, 'traded_quantity', '');
  const price = getRoundedData(_.get(orderDetail, 'price', ''));
  const avgPrice = getRoundedData(
    _.get(orderDetail, 'trade_average_price', '')
  );
  const targetPrice = getRoundedData(_.get(orderDetail, 'trigger_price', ''));
  const orderId = _.get(orderDetail, 'order_id', '');
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice
        : price;

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT).format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'action', '').toLowerCase() ===
        transactionTypes.buy.value
        ? transactionTypes.buy.value
        : transactionTypes.sell.value,
    status: isCancellableOrder ? statusConfigs?.pending?.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice,
      orderNo: orderId,
    },
  };
};

const parseSubTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isValidSymbolForInsta(exchange, tradingSymbolObj?.toString())) {
    return null;
  }

  const tradingSymbolObj = parseTradingSymbolObj(orderDetail);
  const tradedTime = _.get(orderDetail, 'order_date_time', '');
  const status = statusConfigs.executed.label;
  const tradedQuantity = _.get(orderDetail, 'quantity', 0);
  const quantity = _.get(orderDetail, 'quantity', 0);
  const productCode = _.get(orderDetail, 'product_type', '');

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT).format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'action', '').toLowerCase() ===
        transactionTypes.buy.value
        ? transactionTypes.buy.value
        : transactionTypes.sell.value,
    status,
    extraDetails: {
      product: `${productCode}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'price', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isValidSymbolForInsta(exchange, tradingSymbolObj?.toString())) {
    return null;
  }

  const tradingSymbolObj = parseTradingSymbolObj(orderDetail);
  const qty = _.parseInt(_.get(orderDetail, 'net_quantity', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'last_trade_price', 0));
  const netAvgPrice = convertToNumber(
    _.get(orderDetail, 'net_average_price', 0)
  );
  const buyAvg = convertToNumber(_.get(orderDetail, 'buy_average_price', 0));
  const sellAvg = convertToNumber(_.get(orderDetail, 'sell_average_price', 0));
  const realisedprofitloss = _.round(_.get(orderDetail, 'net_value', 0), 2);
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const currentProfitLoss =
    type === transactionTypes.buy.value ? ltp - netAvgPrice : netAvgPrice - ltp;
  const profitLoss =
    qty === 0
      ? realisedprofitloss
      : _.round(currentProfitLoss, 2) * Math.abs(qty);

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'product_type', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'last_trade_price',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'product_type', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const tradingSymbolObj = parseTradingSymbolObj(orderDetail);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  if (!isValidSymbolForInsta(exchange, tradingSymbolObj?.toString())) {
    return null;
  }

  const quantity = _.get(orderDetail, 'quantity', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'average_price', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'last_trade_price', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbolObj?.symbol,
    profitLoss: profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'last_trade_price',
      },
      order: orderDetail,
    },
  };
};

const parsePlaceOrder = async (orderConfig) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedExchangeTokenTradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);
  const exchangeTokenSymbol = tradingSymbolObj.isEquity() ?
    formattedExchangeTokenTradingSymbol.split('-')[0] : formattedExchangeTokenTradingSymbol;
  const symbolId = await getExchangeToken(exchangeTokenSymbol);

  return {
    action: _.get(orderConfig, 'transactionType', '').toUpperCase(),
    token: symbolId.toString(),
    order_type: parseOrderType(_.get(orderConfig, 'prctyp', '').toLowerCase()),
    product_type: parseProductType(
      _.get(orderConfig, 'pCode', '').toLowerCase()
    ),
    exchange: _.get(orderConfig, 'exch', ''),
    quantity: _.get(orderConfig, 'qty', '').toString(),
    disclose_quantity: _.get(orderConfig, 'disCloseQty', '').toString(),
    price: _.get(orderConfig, 'price', '').toString(),
    trigger_price: _.get(orderConfig, 'trigPrice', '').toString(),
    validity: _.get(orderConfig, 'ret', '').toUpperCase(),
    stop_loss_price: '0',
    trailing_stop_loss: '0',
    tag: '',
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parsePlaceOrder,
  parseOrderDetails,
  parseOrderBook,
  parseHoldingsBook,
  parseTradeBook,
  parsePositionBook,
};
