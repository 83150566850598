import { genericTicker } from 'habitual-analytics/client/GenericTicker';
import { useEffect } from 'react';
import TickerObserver from './tickObserver';

const HaltingAndInitiate = ({ children }) => {
  useEffect(() => {
    const observer = new TickerObserver(
      () => {
        try {
          genericTicker.halt();
          console.log('TickerObserver: Halting ticker observer');
        } catch (err) {
          console.log('TickerObserver: Error halting Generic Ticker', err);
        }
      },
      () => {
        try {
          genericTicker.initiate();
          console.log('TickerObserver: Initiating ticker observer');
        } catch (err) {
          console.log('TickerObserver: Error initiating Generic Ticker', err);
        }
      },
    );

    const intervalId = setInterval(() => {
      observer.update(intervalId);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return children;
};

export default HaltingAndInitiate;
