import React, { startTransition } from 'react';
import { useResponsive } from 'globals/hooks/useResponsive';

const withResponsive = (MobileComponent, DesktopComponent) => {
  const ResponsiveComponent = (props) => {
    const isMobile = useResponsive();
    const [RenderComponent, setRenderComponent] = React.useState(null);

    React.useEffect(() => {
      startTransition(() => {
        setRenderComponent(isMobile ? MobileComponent : DesktopComponent);
      });
    }, [isMobile, props]);

    return RenderComponent ? <RenderComponent {...props} /> : null;
  };

  return React.memo(ResponsiveComponent);
};

export default withResponsive;
