import moment from 'moment';
import {
  DATE_FORMAT,
  EXPIRY_DATE_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

export const getAtmBasedonPremiumPrice = (instrument, premiumPrice) => {
  const currentInstrumentStepSize =
    MarketUtility.getSymbolStrikeInterval({
      symbol: instrument,
    });

  return {
    atm:
      Math.round(premiumPrice / currentInstrumentStepSize) *
      currentInstrumentStepSize,
    stepSize: currentInstrumentStepSize,
  };
};

export const getValidatedInstrumentAndExpiryType = (
  instrument,
  expiryDate = ''
) => {
  const constantReturn = { instrument, expiryDate };
  constantReturn.expiryDate = MarketUtility.getCurrentExpiryDate({
    symbol: instrument,
  });

  return constantReturn;
};

export const generateLiveDataFormatSymbols = (
  strikePrices,
  instrument,
  expiryDate
) => {
  const formattedExpiryDate = moment(expiryDate, DATE_FORMAT)
    .format(EXPIRY_DATE_FORMAT)
    .toUpperCase();

  const monthlyExpiryDate = moment(
    MarketUtility.getUpComingExpiryDates({
      symbol: instrument,
      expiryType: 'monthly',
    }),
    DATE_FORMAT
  )
    .format(EXPIRY_DATE_FORMAT)
    .toUpperCase();

  const futureInstrument = `${instrument}${monthlyExpiryDate}FUT`;

  const formattedOptionsInstrumentsList = strikePrices.map((strikePrice) => [
    `${instrument}${formattedExpiryDate}${strikePrice}CE`,
    `${instrument}${formattedExpiryDate}${strikePrice}PE`,
  ]);

  return [
    instrument,
    ...formattedOptionsInstrumentsList.flat(),
    futureInstrument,
  ];
};
