import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string.isRequired
};

const defaultProps = {
  className: ''
};

const Image = (props) => {
  const {
    src, className, alt, ...restProps
  } = props;
  return (
    <img
      src={src}
      className={className}
      alt={alt}
      {...restProps}
    />
  );
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
