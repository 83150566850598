const getLabelConfigs = (isEquity) => {
  return {
    placeOrderLabelConfigs: {
      mis: 'MIS',
      cnc: isEquity ? 'CNC' : 'NRML',
      nrml: 'NRML',
    }
  };
};

export { getLabelConfigs };
