import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import _ from 'lodash';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

dayjs.tz.setDefault('Asia/Kolkata');

export const getCurrentLocalizeDate =  _.throttle(() => dayjs.tz(), 1000);

export const getParsedDateObj = (time) => dayjs(time);
