export const orderTableHeaders = [
  { label: 'Instrument', key: 'instrument' },
  { label: 'Type', key: 'type' },
  { label: 'Buy/Sell', key: 'buySell' },
  { label: 'Qty(lot)', key: 'extraDetails.qty' },
  { label: 'Exec Price', key: 'extraDetails.tradedPrice' },
  { label: 'LTP', key: 'ltp' },
  { label: 'Status', key: 'status' },
];

export const tradeTableHeaders = [
  { label: 'Instrument', key: 'instrument' },
  { label: 'Type', key: 'type' },
  { label: 'Buy/Sell', key: 'buySell' },
  { label: 'Fill Qty(lot)', key: 'extraDetails.qty' },
  { label: 'Exec Price', key: 'extraDetails.tradedPrice' },
  { label: 'LTP', key: 'ltp' },
  { label: 'Status', key: 'status' },
];

export const postitionTableHeaders = [
  { label: '', key: 'checkbox' },
  { label: 'Instrument', key: 'instrument' },
  { label: 'Type', key: 'extraDetails.type' },
  { label: 'Buy/Sell', key: 'buySell' },
  { label: 'Qty', key: 'qty' },
  { label: 'ATP', key: 'extraDetails.averagePrice' },
  { label: 'LTP', key: 'ltp' },
  { label: 'P/L', key: 'profitLoss' },
];

export const holdingTableHeaders = [
  { label: 'Instrument', key: 'instrument' },
  { label: 'Qty', key: 'extraDetails.quantity' },
  { label: 'LTP', key: 'ltp' },
  { label: 'ATP', key: 'extraDetails.averagePrice' },
  { label: 'Net Chg', key: 'extraDetails.netChg' },
  { label: 'P&L', key: 'profitLoss' },
];
