const BASE_URI = 'https://plwave.plindia.com';
const PROFILE_PATH = '/authentication/v1/user/profile';

const RestEndPoints = {
  GET_HOLDINGS: `${BASE_URI}/transactional/v1/portfolio/holdings`,
  UPDATE_POSITION_BOOK: `${BASE_URI}/transactional/v1/portfolio/positions`,
  GET_TRADE_BOOK: `${BASE_URI}/transactional/v1/trades`,
  GET_ORDER_BOOK: `${BASE_URI}/transactional/v1/orders`,
  PLACE_ORDER: `${BASE_URI}/transactional/v1/orders/regular`,
  BASE_URI,
  PROFILE_PATH: `${BASE_URI}${PROFILE_PATH}`,
  CANCEL_PLACE_ORDER: `${BASE_URI}/transactional/v1/orders/regular`,
  SEND_OTP: `${BASE_URI}/authentication/v1/user/password/reset/send-otp`,
  MODIFY_ORDER: `${BASE_URI}/transactional/v1/orders/regular`,
  AUTHENTICATE_USER: `${BASE_URI}/authentication/v1/user/session`,
};

export default RestEndPoints;
