import _ from 'lodash';

export const getRoundedData = (data, precision = 2) => {
  if (_.isNaN(data)) {
    return 0;
  }

  return _.round(data, precision);
};

export const getFixedData = (data) => {
  if (_.isNaN(data)) {
    return 0;
  }

  return _.round(data, 2)?.toFixed(2);
};
