import moment from 'moment';
import _ from 'lodash';
import {
  getFixedData,
  getRoundedData,
} from 'habitual-analytics/common/formatter/number';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isValidSymbolForInsta,
  isOrderPartiallyExecuted,
} from '../utils';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
  getPlaceOrderTradingSymbol,
} from '../tradingSymbolParser';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs?.placed?.value;

  switch (_.lowerCase(orderDetail.status)) {
    case 'cancelled':
      status = statusConfigs?.cancelled?.value;
      break;
    case 'complete':
      status = statusConfigs?.executed?.value;
      break;
    case 'rejected':
      status = statusConfigs?.failed?.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.Status,
      orderDetail.Qty,
      orderDetail.Fillshares
    ):
      status = statusConfigs?.partiallyExecuted?.value;
      break;
    default:
      status = statusConfigs?.placed?.label;
      break;
  }

  return status;
};

const parseOrderBook = (orderDetail) => {
  let tradedPrice;
  const {
    tsym: tradingSymbol = '',
    product: productType = '',
    trgprc,
    fillshares,
    qty: quantity = 0,
    avgprc,
    orderentrytime: tradedTime = '',
    trantype: transactionType = '',
    nstordno: orderNo = '',
    exchange: exchange = '',
  } = orderDetail;

  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const targetPrice = _.isEmpty(trgprc) ? 0 : _.parseInt(trgprc);
  const avgPrice = _.isEmpty(avgprc) ? 0 : _.parseInt(avgprc);
  const price = _.isEmpty(_.get(orderDetail, 'price', 0))
    ? 0
    : _.parseInt(_.get(orderDetail, 'price', 0));

  const isSellTransaction = transactionType === 'S';
  if (_.parseInt(targetPrice) !== 0) {
    tradedPrice = `${getFixedData(price)} / ${getFixedData(targetPrice)} trg`;
  } else if (_.parseInt(avgPrice) !== 0) {
    tradedPrice = getFixedData(avgPrice);
  } else {
    tradedPrice = getFixedData(price);
  }

  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const tradedQty = _.isEmpty(fillshares) ? 0 : fillshares;
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);
  const failedReason = _.get(orderDetail, 'rejreason', '');

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    time: moment(tradedTime.split(' ')[0], TIME_FORMAT)?.format(
      PRETTIER_FORMAT_WITH_SECONDS
    ),
    type: isSellTransaction
      ? transactionTypes?.sell.value
      : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: productType,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo,
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const {
    tsym: tradingSymbol = '',
    pricetype: productType = '',
    product: productCode = '',
    qty: quantity = 0,
    trantype: transactionType = '',
    price = 0,
    exchtime: exchangeTime = '',
    exchange: exchange = '',
  } = orderDetail;

  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const isSellTransaction = transactionType === 'S';
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    time: moment(exchangeTime.split(' ')[1], TIME_FORMAT)?.format(
      PRETTIER_FORMAT_WITH_SECONDS
    ),
    type: isSellTransaction
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value,
    status: statusConfigs.executed.label,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: quantity,
      tradedPrice: price,
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const {
    tsym: tradingSymbol = '',
    ltp,
    realisedprofitloss = 0,
    product: productType = '',
    exchange: exchange = '',
  } = orderDetail;

  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const netSellAvgPrice = _.get(orderDetail, 'netsellavgprc', 0);
  const netBuyAvgPrice = _.get(orderDetail, 'netbuyavgprc', 0);

  const buyAvg = convertToNumber(
    _.isEmpty(netBuyAvgPrice) ? 0 : netBuyAvgPrice
  );
  const sellAvg = convertToNumber(
    _.isEmpty(netSellAvgPrice) ? 0 : netSellAvgPrice
  );
  const orderValue = convertToNumber(_.get(orderDetail, 'bep', 0));
  const qty = convertToNumber(_.get(orderDetail, 'netqty', 0));
  const ltpValue = convertToNumber(ltp);
  const realisedPLValue = convertToNumber(realisedprofitloss);
  const profitLoss =
    qty === 0 ? realisedPLValue : (ltpValue - orderValue) * qty;
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp: ltpValue,
    profitLoss,
    extraDetails: {
      product: productType,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(productType, tradingSymbolObj),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type:
        qty !== 0
          ? Number(qty) < 0
            ? transactionTypes?.sell.value
            : transactionTypes?.buy?.value
          : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const {
    tsym: tradingSymbol = '',
    sellable_qty: quantity = 0,
    average_price: orderValue = 0,
    ltp = 0,
    exchange: exchange = '',
  } = orderDetail;

  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const ltpValue = convertToNumber(ltp);
  const orderValueNum = convertToNumber(orderValue);
  const profitLoss = getRoundedData((ltpValue - orderValueNum) * quantity);
  const netChg = getRoundedData((profitLoss / orderValueNum) * 100);
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp: ltpValue,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValueNum,
      buyValue: orderValueNum,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = (orderConfig) => {
  const { tradingSymbolObj } = orderConfig;
  const productType = _.get(orderConfig, 'prctyp', '');
  const formattedTradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);

  const formattedOrderConfigs = {
    tsym: formattedTradingSymbol,
    exchange: tradingSymbolObj?.isEquity() ? 'NSE' : 'NFO',
    trantype: _.first(_.upperCase(orderConfig?.transactionType)),
    validity: 'DAY',
    pricetype: productType === 'SL' ? 'SL-L' : productType,
    qty: _.toString(orderConfig?.qty),
    discqty: _.toString(orderConfig?.disCloseQty),
    price: _.toString(orderConfig?.price),
    trgprc: _.toString(orderConfig?.trigPrice),
    product: _.toString(orderConfig?.pCode),
    amo: 'NO',
  };

  return formattedOrderConfigs;
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
};
