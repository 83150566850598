
import moment from 'moment';
import _ from 'lodash';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  parseExchangeTokenTradingSymbol,
  isValidSymbolForInsta
} from '../utils';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
  getPlaceOrderTradingSymbol,
} from '../tradingSymbolParser';
import { PRETTIER_FORMAT_WITH_SECONDS, TIME_FORMAT } from 'habitual-analytics/dateUtils/dateFormats';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import { statusConfigs, transactionTypes } from 'habitual-analytics/constants/habitual-configs';

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs?.placed?.value;

  switch (_.lowerCase(orderDetail.Status)) {
    case 'cancelled':
      status = statusConfigs?.cancelled?.value;
      break;
    case 'complete':
      status = statusConfigs?.executed?.value;
      break;
    case 'rejected':
      status = statusConfigs?.failed?.value;
      break;
    case isOrderPartiallyExecuted(orderDetail.Status, orderDetail.Qty, orderDetail.Fillshares):
      status = statusConfigs?.partiallyExecuted?.value;
      break;
    default:
      status = statusConfigs?.placed?.label;
      break;
  }

  return status;
};

const defaultParseOrderType = (orderType) => {
  let type;
  switch (_.toLower(orderType)) {
    case 'limit':
    case 'l':
      type = 'L';
      break;
    case 'mkt':
    case 'market':
      type = 'MARKET';
      break;
    case 'sl':
      type = 'SL';
      break;
    case 'sl-m':
      type = 'SL-M';
      break;
    default:
      type = '';
  }
  return type;
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'Exchange', '');

  if (!isValidSymbolForInsta(exchange)) { return null; }

  const tradingSymbol = _.get(orderDetail, 'Trsym', '');
  const productType = _.get(orderDetail, 'Prctype', '');
  const productCode = _.get(orderDetail, 'Pcode', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'Trgprc', ''));
  const price = convertToNumber(_.get(orderDetail, 'Prc', ''));
  const tradedQty = _.get(orderDetail, 'Fillshares', 0);
  const quantity = Number(_.get(orderDetail, 'Qty', 0));
  const avgPrice = convertToNumber(_.get(orderDetail, 'Avgprc', 0));
  const tradedPrice = _.parseInt(targetPrice) !== 0
    ? `${price} / ${targetPrice} trg`
    : _.parseInt(avgPrice) !== 0
      ? avgPrice
      : price;
  const tradedTime = _.get(orderDetail, 'OrderedTime', '').split(' ')?.[1];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'RejReason', '');
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'Trantype', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'Nstordno', ''),
      defaultProductType: defaultParseOrderType(productType),
      defaultProductCode: getDefaultProductCode(productCode, tradingSymbolObj),
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'Exchange', '');

  if (!isValidSymbolForInsta(exchange)) { return null; }

  const tradingSymbol = _.get(orderDetail, 'Tsym', '');
  const productType = _.get(orderDetail, 'Prctype', '');
  const productCode = _.get(orderDetail, 'Pcode', '');
  const tradedQuantity = Number(_.get(orderDetail, 'Filledqty', 0));
  const quantity = Number(_.get(orderDetail, 'Qty', 0));
  const status = statusConfigs.executed.label;

  return {
    tradingSymbolObj: getFormattedTradingSymbolObject(tradingSymbol),
    time: moment(_.get(orderDetail, 'Filltime', ''), TIME_FORMAT)?.format(
      PRETTIER_FORMAT_WITH_SECONDS
    ),
    type:
      _.get(orderDetail, 'Trantype', '') === 'S'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'Price', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'Exchange', '');

  if (!isValidSymbolForInsta(exchange)) { return null; }

  const tradingSymbol = _.get(orderDetail, 'Tsym', '');
  const ltp = convertToNumber(_.get(orderDetail, 'LTP'));
  const buyAvg = convertToNumber(_.get(orderDetail, 'NetBuyavgprc', 0));
  const sellAvg = convertToNumber(_.get(orderDetail, 'NetSellavgprc', 0));
  const orderValue = convertToNumber(_.get(orderDetail, 'BEP', 0));
  const qty = Number(_.get(orderDetail, 'Netqty', 0));
  const realisedprofitloss = _.round(_.get(orderDetail, 'realisedprofitloss', 0), 2);
  const type = Number(qty) < 0 ? transactionTypes?.sell?.value : transactionTypes?.buy?.value;
  const currentProfitLoss = type === transactionTypes.buy.value ? ltp - orderValue : orderValue - ltp;
  const profitLoss = qty === 0
    ? realisedprofitloss : (_.ceil(currentProfitLoss, 2) * Math.abs(qty));
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'Pcode', ''),
      liveUpdateDetails: { symbol: tradingSymbolObj.toString(), value: 'ltp', key: 'LTP' },
      defaultProductCode: getDefaultProductCode(_.get(orderDetail, 'Pcode', ''), tradingSymbolObj),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'ExchSeg1', '');

  if (!isValidSymbolForInsta(exchange)) { return null; }

  const tradingSymbol = _.get(orderDetail, 'Nsetsym', '');
  const quantity = _.get(orderDetail, 'SellableQty', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'Price', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'LTnse', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: { symbol: tradingSymbolObj.toString(), value: 'ltp', key: 'LTnse' },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = async (orderConfigs) => {
  // completely have to clean up this code
  const formattedOrderConfigs = await Promise.all(
    _.map(orderConfigs, async (orderConfig) => {
      const { tradingSymbolObj } = orderConfig;
      const formattedTradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);
      const formattedExchangeTokenTradingSymbol = parseExchangeTokenTradingSymbol(tradingSymbolObj);
      const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);

      return {
        complexty: _.get(orderConfig, 'complexty', ''),
        discqty: _.get(orderConfig, 'disCloseQty', ''),
        exch: _.get(orderConfig, 'exch', ''),
        pCode: _.get(orderConfig, 'pCode', ''),
        prctyp: _.get(orderConfig, 'prctyp', ''),
        price: _.get(orderConfig, 'price', ''),
        qty: _.get(orderConfig, 'qty', ''),
        ret: _.get(orderConfig, 'ret', ''),
        symbol_id: symbolId,
        trading_symbol: formattedTradingSymbol,
        transtype: _.get(orderConfig, 'transactionType', '').toUpperCase(),
        trigPrice: _.get(orderConfig, 'trigPrice', ''),
      };
    })
  );
  return formattedOrderConfigs;
};

const parseModifyOrder = (orderDetails) => {
  const orderDetail = orderDetails[0];
  const { tradingSymbolObj, orderNo } = orderDetail;
  const formattedTradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);

  return {
    'discqty': _.get(orderDetail, 'disCloseQty', ''),
    'exch': _.get(orderDetail, 'exch', ''),
    'filledQuantity': 0,
    'nestOrderNumber': orderNo,
    'prctyp': _.get(orderDetail, 'prctyp', ''),
    'price': _.get(orderDetail, 'price', ''),
    'qty': _.get(orderDetail, 'qty', ''),
    'trading_symbol': formattedTradingSymbol,
    'trigPrice': _.get(orderDetail, 'trigPrice', ''),
    'transtype': _.get(orderDetail, 'transactionType', '').toUpperCase(),
    'pCode': _.get(orderDetail, 'pCode', ''),
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};


export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
  parseModifyOrder
};
