import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import RestEndPoints from './RestEndPoints';
import { TOASTIFY_TYPES, reactToastify } from 'globals/utils/reactToastify';
import {
  parseCancelOrder,
  parseMarginCalculator,
  parseOrderDetails,
  parsePlaceOrder,
  parseMarginAvailable
} from './parser';
import {
  holdingTableHeaders,
  orderTableHeaders,
  postitionTableHeaders,
  tradeTableHeaders,
} from './tableHeaders';


const getBaseUri = (isProfile = false) => {
  const {
    NUVAMA_VENDOR_LOGIN_URL,
    NUVAMA_API_URL
  } = getDynamicAppConfigs().envs;

  if (isProfile) {
    return NUVAMA_VENDOR_LOGIN_URL;
  }

  return NUVAMA_API_URL;
};

const isMobAddParams = (isMobile = false) => {
  return isMobile ? '?rTyp=EQFNO' : '';
};

const injectSessionExpired = () => {
  if (window?.webkit && window?.webkit?.messageHandlers) {
    if (window?.webkit?.messageHandlers?.onSessionExpired) {
      // iPhone - Session Expired
      window.webkit.messageHandlers.onSessionExpired.postMessage({
        message: 'Session expired',
      });
    }
  } else if (window?.EDISJsBridge) {
    // Session Expired for Android
    if (window?.EDISJsBridge?.onSessionExpired) {
      window.EDISJsBridge.onSessionExpired(
        JSON.stringify({ message: 'Session expired' })
      );
    }
  } else if (window?.chrome && window?.chrome?.webview) {
    // WebView2 - Session Expired
    window.chrome.webview.postMessage('instaOptionSessionExpired');
  }
};

const getRequestHeaders = () => {
  const { NUVAMA_API_KEY, NUVAMA_APP_ID, NUVAMA_SRC_TOKEN } =
    getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  return {
    appidkey: NUVAMA_APP_ID,
    Source: NUVAMA_API_KEY,
    SourceToken: NUVAMA_SRC_TOKEN,
    Authorization: brokerClientAccessToken,
  };
};

const isValidAccessToken = () => {
  return fetchOrderBook().then(({ isValid }) => isValid || false);
  // .catch(() => {
  //   injectSessionExpired();
  //   return false;
  // });
};

const fetchOrderBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const isMobile = _.get(getDynamicAppConfigs(), 'isMobile', false);

  const parameters = {
    headers: {
      ...getRequestHeaders(),
    },
  };

  const uri =
    `${getBaseUri()}/${RestEndPoints.GET_ORDER_BOOK}/${brokerClientId}/v1${isMobAddParams(isMobile)}`;
  return wrappedFetch(uri, parameters)
    .then((response) => {
      return response.json();
    })
    .then(async (result) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(_.get(result, 'data.ord', []), 'order'),
            ['time'],
            ['desc']
          )
        ),
        headers: orderTableHeaders,
        isValid: result?.data
          ? true
          : _.includes(
            ['Seems like there are no orders in your order book'],
            result?.error?.errMsg
          ),
      };
    })
    .catch((e) => {
      injectSessionExpired();
      return [e];
    });
};

const fetchTradeBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const isMobile = _.get(getDynamicAppConfigs(), 'isMobile', false);

  const parameters = {
    headers: {
      ...getRequestHeaders(),
    },
  };

  const uri = `${getBaseUri()}/${RestEndPoints.GET_TRADE_BOOK
  }/v1/${brokerClientId}${isMobAddParams(isMobile)}`;
  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then(async (result) => {
      const { data } = result;

      return {
        tradeBookDetails: _.compact(
          _.orderBy(await parseOrderDetails(data?.trade, 'trade'), ['time'], ['desc'])
        ),
        headers: tradeTableHeaders,
      };
    })
    .catch((e) => {
      injectSessionExpired();
      return [e];
    });
};

const fetchPositionBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const isMobile = _.get(getDynamicAppConfigs(), 'isMobile', false);

  const parameters = {
    headers: {
      ...getRequestHeaders(),
    },
  };

  const uri = `${getBaseUri()}/${RestEndPoints.UPDATE_POSITION_BOOK
  }/${brokerClientId}${isMobAddParams(isMobile)}`;

  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then(async (result) => {
      const { data } = result;
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(data?.pos, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    })
    .catch((e) => {
      injectSessionExpired();
      return [e];
    });
};

const fetchHoldingBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const isMobile = _.get(getDynamicAppConfigs(), 'isMobile', false);

  const parameters = {
    headers: {
      ...getRequestHeaders(),
    },
  };

  const uri = `${getBaseUri()}/${RestEndPoints.GET_HOLDINGS
  }/${brokerClientId}${isMobAddParams(isMobile)}`;

  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then(async ({ data }) => {
      return {
        holdingBookDetails: _.compact(
          await parseOrderDetails(data?.rmsHdg, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    })
    .catch((e) => {
      injectSessionExpired();
      return [e];
    });
};

const cancelPlaceOrder = ({ orderDetails, orderNo }) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const isMobile = _.get(getDynamicAppConfigs(), 'isMobile', false);

  const parameters = {
    method: 'PUT',
    headers: {
      ...getRequestHeaders(),
    },
    body: JSON.stringify(parseCancelOrder(orderDetails, orderNo)),
  };

  const uri = `${getBaseUri()}/${RestEndPoints.CANCEL_PLACE_ORDER
  }/${brokerClientId}${isMobAddParams(isMobile)}`;
  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .catch((e) => {
      injectSessionExpired();
      return [e];
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const { NUVAMA_API_KEY } = getDynamicAppConfigs().envs;
  const isMobile = _.get(getDynamicAppConfigs(), 'isMobile', false);

  const uri = `${getBaseUri()}/${RestEndPoints.PLACE_ORDER
  }/${brokerClientId}${isMobAddParams(isMobile)}`;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const bodyDetails = await parsePlaceOrder(orderConfig, NUVAMA_API_KEY);
      const parameters = {
        method: 'POST',
        headers: {
          ...getRequestHeaders(),
        },
        body: JSON.stringify(bodyDetails),
      };

      return wrappedFetch(uri, parameters)
        .then((response) => response.json())
        .then((data) => data)
        .catch((e) => {
          const errorMessage = _.get(JSON.parse(e?.apiResponse), 'error.errMsg', '');
          if (!_.isEmpty(errorMessage)) {
            reactToastify(TOASTIFY_TYPES.ERROR, errorMessage);
          }
          injectSessionExpired();
          return [e];
        });
    })
  );

  return _.map(formattedConfig, ({ data }) => data?.oid);
};

const fetchMarginCalculator = async (orderConfigs) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  //segment URI'S
  const derivativeApiUrl = `${getBaseUri()}/${RestEndPoints.MARGIN_CALCULATOR_DERIVATIVE}`;
  const equityApiUrl = `${getBaseUri()}/${RestEndPoints.MARGIN_CALCULATOR_EQUITY}/${brokerClientId}`;
  //headers
  const parameters = {
    method: 'POST',
    headers: getRequestHeaders(),
  };

  const formattedOrderDetails = await Promise.all(_.map(orderConfigs, parseMarginCalculator));
  const equityPromises = _.chain(formattedOrderDetails)
    .filter((order) => order?.exchange !== 'NFO')
    .map((obj) => _.omit(obj, ['exchange']))
    .map((orderDetail) => {
      parameters['body'] = JSON.stringify(orderDetail);
      return wrappedFetch(equityApiUrl, parameters)
        .then((response) => response.json())
        .then(({ data }) => {
          return data;
        })
        .catch((e) => {
          injectSessionExpired();
          return {};
        });
    })
    .value();


  const derivativePromises = _.chain(formattedOrderDetails)
    .filter((order) => order?.exchange === 'NFO')
    .map((obj) => _.omit(obj, ['exchange']))
    .thru((finalBodyDetails) => {
      if (_.isEmpty(finalBodyDetails)) {
        return [];
      }

      parameters['body'] = JSON.stringify({ ct: finalBodyDetails[0], lst: finalBodyDetails });
      return [
        wrappedFetch(derivativeApiUrl, parameters)
          .then((response) => response.json())
          .then(({ data }) => {
            return data;
          })
          .catch((e) => {
            injectSessionExpired();
            return {};
          })

      ];
    })
    .value();

  const totalMarginAvailable = await getAvailableMargin();
  const totalMarginRequired = await Promise.all([...equityPromises, ...derivativePromises])
    .then((result) => {
      return _.chain(result)
        .flattenDeep()
        .thru((data) => {
          const marginUsedForEquity = _.sumBy(data, ({ marginUsed }) => parseFloat(marginUsed) || 0) || 0;
          const marginUsedForDerivative = _.sumBy(data, ({ tot }) => parseFloat(tot) || 0) || 0;
          return marginUsedForEquity + marginUsedForDerivative;
        })
        .value();
    });

  return { totalMarginRequired, totalMarginAvailable };
};

const getAvailableMargin = async () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const ApiUrl = `${getBaseUri()}/${RestEndPoints.MARGIN_AVAILABLE}/${brokerClientId}`;

  const orderDetails = parseMarginAvailable();
  const parameters = {
    method: 'POST',
    headers: getRequestHeaders(),
    body: JSON.stringify(orderDetails),
  };
  const formattedResponse = await wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ data }) => data)
    .catch((e) => {
      injectSessionExpired();
      return [e];
    });
  const totalMarginAvailable =
    Number(formattedResponse.availCash || 0) +
    Number(formattedResponse.ordMargin || 0) -
    Number(formattedResponse.marginUsed || 0);

  return totalMarginAvailable;
};

const fetchMarginAvailable = async () => await fetchMarginCalculator();

export {
  isValidAccessToken,
  fetchOrderBook,
  fetchTradeBook,
  fetchPositionBook,
  placeOrder,
  fetchHoldingBook,
  cancelPlaceOrder,
  fetchMarginCalculator,
  fetchMarginAvailable
};
