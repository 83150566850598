import { fetchBrokerConfigs } from 'habitual-analytics/brokers/index';
import { placeOrderModes } from 'habitual-analytics/constants/habitual-configs';

export const getPlaceOrderEligibleLists = (
  isEquity = true,
  isFuture = false,
  isOption = false,
  placeOrderMode = placeOrderModes.live
) => {
  const { mis, nrml, cnc } = fetchBrokerConfigs('getLabelConfigs', isEquity)?.placeOrderLabelConfigs ?? {};
  const isLive = () => placeOrderMode === placeOrderModes.live;

  const productCodeLists = [
    {
      name: mis ?? 'MIS',
      value: 'MIS',
      key: 'pCode',
      isShown: true,
      expandLabel: 'Margin Intrady Square-Off',
    },
    {
      name: cnc ?? 'CNC',
      isShown: isEquity && isLive(),
      value: 'CNC',
      key: 'pCode',
      expandLabel: 'Cash and Carry',
    },
    // {
    //   name: 'MTF',
    //   isShown: isEquity,
    //   value: 'MTF',
    //   key: 'pCode',
    //   expandLabel: 'Margin Trading Funding',
    // },
    {
      name: nrml ?? 'NRML',
      value: 'NRML',
      key: 'pCode',
      isShown: (isOption || isFuture) && isLive(),
      expandLabel: 'Normal',
    },
  ];

  const productTypeLists = [
    {
      name: 'Limit',
      value: 'L',
      key: 'prctyp',
      isShown: isLive(),
      expandLabel: 'Limit',
    },
    {
      name: 'MKT',
      value: 'MKT',
      key: 'prctyp',
      isShown: true,
      expandLabel: 'Market',
    },
    {
      name: 'SL',
      value: 'SL',
      key: 'prctyp',
      isShown: isLive(),
      expandLabel: 'Stop Loss',
    },
    {
      name: 'SL-M',
      value: 'SL-M',
      isShown: (isEquity || isFuture) && isLive(),
      key: 'prctyp',
      expandLabel: 'Stop Loss Market',
    },
  ];

  const showMoreLists = [
    {
      name: 'DAY',
      value: 'DAY',
      key: 'ret',
      isShown: true,
      expandLabel: 'Day',
    },
    {
      name: 'IOC',
      value: 'IOC',
      key: 'ret',
      isShown: true,
      expandLabel: 'Immediate or Cancel',
    },
  ];

  return { productCodeLists, productTypeLists, showMoreLists };
};
