import { wrappedFetch } from 'habitual-analytics/api/services/base/index';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  parseHoldingsBook,
  parseOrderBook,
  parsePlaceOrder,
  parsePositionBook,
  parseTradeBook,
} from './parser';
import { REST_URLS } from 'habitual-analytics/api/resturls/index';
import _ from 'lodash';
import {
  holdingTableHeaders,
  orderTableHeaders,
  postitionTableHeaders,
  tradeTableHeaders,
} from './tableHeaders';

const getRequestHeaders = () => {
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const { SMC_GLOBAL_API_KEY } = getDynamicAppConfigs().envs;

  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Client-Id': brokerClientId,
    'X-Access-Token': brokerClientAccessToken,
    'X-Platform': 'api',
    'X-Api-Key': SMC_GLOBAL_API_KEY,
  };
};

const getRequestUri = (method, qs = '', path = '') => {
  const baseUrl = REST_URLS().BROKER_PROXY;

  const queryString = qs ? `&${qs}` : '';
  const pathString = path ? `&path=${path}` : '';

  return `${baseUrl}?broker=smc_global&method=${method}${queryString}${pathString}`;
};

const isValidAccessToken = () => {
  const parameters = {
    method: 'POST',
    headers: {
      ...getRequestHeaders(),
    },
  };

  return wrappedFetch(getRequestUri('authenticateUser'), parameters)
    .then((response) => response.json())
    .then((result) => result?.status === 'success')
    .catch(() => false);
};

const fetchOrderBook = async () => {
  const parameters = {
    method: 'POST',
    headers: {
      ...getRequestHeaders(),
    },
  };

  const orderBookResponse = await wrappedFetch(
    getRequestUri('orderBook'),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result?.data);

  const formattedOrderConfigs = await Promise.all(
    await parseOrderBook(orderBookResponse)
  );

  return {
    orderBookDetails: _.compact(
      _.orderBy(formattedOrderConfigs, ['time'], ['desc'])
    ),
    headers: orderTableHeaders,
  };
};

const fetchTradeBook = async () => {
  const parameters = {
    method: 'POST',
    headers: {
      ...getRequestHeaders(),
    },
  };

  const tradeBookResponse = await wrappedFetch(
    getRequestUri('tradeBook'),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result?.data);

  const formattedTradeConfigs = await Promise.all(
    await parseTradeBook(tradeBookResponse)
  );

  return {
    tradeBookDetails: _.compact(
      _.orderBy(formattedTradeConfigs, ['time'], ['desc'])
    ),
    headers: tradeTableHeaders,
  };
};

const fetchPositionBook = async () => {
  const parameters = {
    method: 'POST',
    headers: {
      ...getRequestHeaders(),
    },
  };

  const positionBookResponse = await wrappedFetch(
    getRequestUri('positionBook'),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result?.data);

  const formattedPositionConfigs = await Promise.all(
    await parsePositionBook(positionBookResponse)
  );

  return {
    positionBookDetails: _.compact(
      _.orderBy(
        formattedPositionConfigs,
        ['extraDetails.isOpenPosition'],
        ['desc']
      )
    ),
    headers: postitionTableHeaders,
  };
};

const fetchHoldingBook = async () => {
  const parameters = {
    method: 'POST',
    headers: {
      ...getRequestHeaders(),
    },
  };

  const holdingBookResponse = await wrappedFetch(
    getRequestUri('holdings'),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result?.data);

  const formattedHoldingConfigs = await Promise.all(
    await parseHoldingsBook(holdingBookResponse)
  );

  return {
    holdingBookDetails: _.compact(formattedHoldingConfigs),
    headers: holdingTableHeaders,
  };
};

const placeOrder = async (orderDetails) => {
  const formattedConfig = await Promise.all(
    _.map(orderDetails, async (orderConfig) => {
      const bodyDetails = await parsePlaceOrder(orderConfig);

      const parameters = {
        method: 'POST',
        headers: {
          ...getRequestHeaders(),
        },
        body: JSON.stringify(bodyDetails),
      };

      return wrappedFetch(getRequestUri('placeOrder'), parameters)
        .then((response) => response.json())
        .catch((e) => [e]);
    })
  );

  return _.map(formattedConfig, ({ data }) => data?.order_id);
};

const cancelPlaceOrder = ({ orderNo }) => {
  const parameters = {
    method: 'POST',
    headers: {
      ...getRequestHeaders(),
    },
  };

  return wrappedFetch(
    getRequestUri('cancelPlaceOrder', null, orderNo),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((e) => [e]);
};

export {
  fetchOrderBook,
  placeOrder,
  isValidAccessToken,
  fetchHoldingBook,
  fetchTradeBook,
  fetchPositionBook,
  cancelPlaceOrder,
};
