import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import {
  parseOrderDetails,
  parsePlaceOrder,
  parseMarginCalculator,
} from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import qs from 'qs';
import { REST_URLS } from 'habitual-analytics/api/resturls';

export const REQUEST_PHASE_URL = () => {
  // todo for mobile app
  return '';
};

const getRequestUri = (method, qs = '', path = '') => {
  const baseUrl = REST_URLS().BROKER_PROXY;

  const queryString = qs ? `&${qs}` : '';
  const pathString = path ? `&path=${path}` : '';

  return `${baseUrl}?broker=religare&method=${method}${queryString}${pathString}`;
};

const fetchAccessToken = (loginCredentials) => {
  const { brokerClientId, brokerClientPassword, otp } = loginCredentials;
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;

  const requestBody = {
    user_id: brokerClientId,
    login_type: 'PASSWORD',
    password: brokerClientPassword,
    second_auth_type: 'OTP',
    second_auth: otp,
    api_key: RELIGARE_API_KEY,
    source: 'WEBAPI',
  };

  const parameters = {
    method: 'POST',
    headers: {
      'x-api-key': RELIGARE_API_KEY,
      'Content-type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  };

  return wrappedFetch(getRequestUri('fetchAccessToken'), parameters)
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(() => {
      return { status: 'error' };
    });
};



const authenticateUser = (loginCredentials) => {
  const { brokerClientId, brokerClientPassword } = loginCredentials;
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;

  const requestBody = {
    user_id: brokerClientId,
    login_type: 'PASSWORD',
    password: brokerClientPassword,
    second_auth_type: 'REGISTER',
    second_auth: '',
    api_key: RELIGARE_API_KEY,
    source: 'WEBAPI',
  };

  const parameters = {
    method: 'POST',
    body: JSON.stringify(requestBody),
  };

  return wrappedFetch(getRequestUri('authenticateUser'), parameters)
    .then((res) => res.json())
    .then((result) => {
      return { status: result?.status };
    })
    .catch((res) => {
      return { status: 'error', message: res?.apiResponse?.message };
    });
};

const sendOtp = (brokerClientId) => {
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;
  const requestBody = {
    user_id: brokerClientId,
    api_key: RELIGARE_API_KEY,
    source: 'WEBAPI',
  };

  const parameters = {
    method: 'POST',
    body: JSON.stringify(requestBody),
  };

  return wrappedFetch(getRequestUri('sendOtp'), parameters)
    .then((res) => res.ok)
    .catch(() => {
      return false;
    });
};

const isValidAccessToken = () => {
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'POST',
    headers: {
      'x-api-key': RELIGARE_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
  };

  return wrappedFetch(getRequestUri('validateUser'), parameters)
    .then((res) => res.json())
    .then(({ data }) => _.toLower(JSON.parse(data)?.message) === 'true')
    .catch(() => {
      return false;
    });
};

const fetchOrderBook = () => {
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'POST',
    headers: {
      'x-api-key': RELIGARE_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
  };
  const queryString = qs.stringify({ offset: 1, limit: 200 });

  return wrappedFetch(getRequestUri('orderBook', queryString), parameters)
    .then((response) => response.json())
    .then((result) => ({
      orderBookDetails: _.compact(
        _.orderBy(parseOrderDetails(result?.data, 'order'), ['time'], ['desc'])
      ),
      headers: orderTableHeaders,
    }));
};

const fetchTradeBook = () => {
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'POST',
    headers: {
      'x-api-key': RELIGARE_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
  };

  const queryString = qs.stringify({ offset: 1, limit: 100 });

  return wrappedFetch(getRequestUri('tradeBook', queryString), parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result?.data, 'trade'),
            ['time'],
            ['desc']
          )
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'POST',
    headers: {
      'x-api-key': RELIGARE_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
  };

  return wrappedFetch(getRequestUri('positionBook'), parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result?.data, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'POST',
    headers: {
      'x-api-key': RELIGARE_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
  };

  return wrappedFetch(getRequestUri('holdings'), parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        holdingBookDetails: _.compact(
          parseOrderDetails(result?.data, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const orderDetails = await parsePlaceOrder(orderConfig);
      const parameters = {
        method: 'POST',
        headers: {
          'x-api-key': RELIGARE_API_KEY,
          Authorization: `Bearer ${brokerClientAccessToken}`,
        },
        body: JSON.stringify(orderDetails),
      };

      return wrappedFetch(getRequestUri('placeOrder'), parameters)
        .then((response) => response.json())
        .catch((e) => [e]);
    })
  );

  return _.chain(formattedConfig)
    .map(({ status, data }) => (status === 'success' ? data?.orderId : null))
    .value();
};

const cancelPlaceOrder = ({ orderNo, tradingSymbolObj }) => {
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const exchangeSegement =
    tradingSymbolObj?.isEquity() === 'NSE' ? 'NSE_EQ' : 'NSE_FO';

  const parameters = {
    method: 'POST',
    headers: {
      'x-api-key': RELIGARE_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
    redirect: 'follow',
  };

  return wrappedFetch(
    getRequestUri('cancelPlaceOrder', null, `${exchangeSegement}/${orderNo}`),
    parameters
  ).then((response) => response.json());
};

const fetchMarginCalculator = async (orderConfigs) => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { RELIGARE_API_KEY } = getDynamicAppConfigs().envs;

  const marginCalculatorConfigs = await parseMarginCalculator(orderConfigs);

  const parameters = {
    method: 'POST',
    headers: {
      'x-api-key': RELIGARE_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
    body: JSON.stringify(marginCalculatorConfigs),
  };

  const { totalMarginRequired = 0, totalMarginAvailable = 0 } =
    await wrappedFetch(getRequestUri('marginCalculator'), parameters)
      .then((response) => response.json())
      .then(({ data }) => {
        return {
          totalMarginRequired:
            Number(_.round(data?.result?.ApproxMargin, 2)) || 0,
          totalMarginAvailable:
            Number(_.round(data?.result?.AvailableMargin, 2)) || 0,
        };
      })
      .catch(() => 0);

  return { totalMarginRequired, totalMarginAvailable };
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  fetchAccessToken,
  cancelPlaceOrder,
  sendOtp,
  authenticateUser,
  fetchMarginCalculator,
};
