export const UPDATE_HEADER_CONFIGURATION = 'update_header_configuration';
export const UPDATE_COMBINED_PREMIUM = 'update_combined_premium';
export const UPDATE_BASKET_OPEN = 'update_basket_open';
export const UPDATE_BASKET_DETAILS = 'update_basket_details';
export const UPDATE_FIND_STRATEGY = 'update_find_strategy';
export const UPDATE_BASKET_SLIDE_OPEN = 'update_basket_slide_open';
export const UPDATE_UNDERLYING_PRICE = 'update_underlying_price';
export const UPDATE_NAVIGATE_TO_BASKET = 'update_navigate_to_basket';
export const UPDATE_PLACE_ORDER_CONFIG = 'update_place_order_config';
export const UPDATE_COMMON_CONFIGS = 'update_common_configs';
export const UPDATE_GLOBAL_CONFIGS = 'update_global_configs';

export const updateHeaderConfiguration = (payload) => {
  return {
    type: UPDATE_HEADER_CONFIGURATION,
    payload
  };
};

export const updateCombinedPremiumData = (payload) => {
  return {
    type: UPDATE_COMBINED_PREMIUM,
    payload
  };
};

export const updateBasketOpen = (payload) => {
  return {
    type: UPDATE_BASKET_OPEN,
    payload
  };
};

export const updateBasketDetails = (payload) => {
  return {
    type: UPDATE_BASKET_DETAILS,
    payload
  };
};

export const updateFindStrategy = (payload) => {
  return {
    type: UPDATE_FIND_STRATEGY,
    payload
  };
};

export const updateBaskeSlideOpen = (payload) => {
  return {
    type: UPDATE_BASKET_SLIDE_OPEN,
    payload
  };
};

export const updateUnderlyingPrice = (payload) => {
  return {
    type: UPDATE_UNDERLYING_PRICE,
    payload
  };
};

export const updateNavigateToBasket = (payload) => {
  return {
    type: UPDATE_NAVIGATE_TO_BASKET,
    payload
  };
};

export const updatePlaceOrderConfig = (payload) => {
  return {
    type: UPDATE_PLACE_ORDER_CONFIG,
    payload
  };
};

export const updateCommonConfigs = (payload) => {
  return {
    type: UPDATE_COMMON_CONFIGS,
    payload
  };
};

export const updateGlobalConfigs = (payload) => {
  return {
    type: UPDATE_GLOBAL_CONFIGS,
    payload
  };
};
