const BASE_URI = 'https://orca-api.enrichmoney.in';
const HOLDINGS_API_PATH = '/client-holdings-api/v1';
const ORDERS_PATH = '/order-api/v1';
const PROFILE_PATH = '/client-handler-api/v1';

const RestEndPoints = {
  GET_HOLDINGS: `${BASE_URI}${HOLDINGS_API_PATH}/holdings`,
  UPDATE_POSITION_BOOK: `${BASE_URI}${HOLDINGS_API_PATH}/positions`,
  GET_TRADE_BOOK: `${BASE_URI}${HOLDINGS_API_PATH}/trade/book`,
  GET_ORDER_BOOK: `${BASE_URI}${HOLDINGS_API_PATH}/order/book`,
  PLACE_ORDER: `${BASE_URI}${ORDERS_PATH}/order/place`,
  PROFILE_PATH: `${BASE_URI}${PROFILE_PATH}/client/details`,
  CANCEL_PLACE_ORDER: `${BASE_URI}${ORDERS_PATH}/order/cancel`,
};

export default RestEndPoints;
