const baseURI = 'https://apiconnect.angelbroking.com/rest/secure/angelbroking';

const RestEndPoints = {
  GET_HOLDINGS: `${baseURI}/portfolio/v1/getHolding`,
  GET_POSITION_BOOK: `${baseURI}/order/v1/getPosition`,
  GET_TRADE_BOOK: `${baseURI}/order/v1/getTradeBook`,
  GET_ORDER_BOOK: `${baseURI}/order/v1/getOrderBook`,
  GET_USER_PROFILE: `${baseURI}/user/v1/getProfile`,
  PLACE_ORDER: `${baseURI}/order/v1/placeOrder`,
  CANCEL_PLACE_ORDER: `${baseURI}/order/v1/cancelOrder`,
  REQUEST_PHASE_URI: 'https://smartapi.angelbroking.com/publisher-login',
};

export default RestEndPoints;
