import moment from 'moment';
import {
  DATE_FORMAT,
  EXPIRY_DATE_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import {
  NUMBER_REGEX,
  instrumentFromSATradingSymbol,
} from 'habitual-analytics/models/instrument/factory';
import _ from 'lodash';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

const getMappingTimeObj = (data, time) => {
  return _.find(data, (obj) => obj.datetime === time) || {};
};

const getHistoricFormattedData = (responseData, timeConfigs) => {
  const finalData = { marketData: {} };
  const {
    marketStartTime,
    firstOiStartTime,
    marketCurrentTime,
    marketPrevDayEndTime,
  } = timeConfigs;

  _.map(responseData, (data, key) => {
    let formattedTradingSymbol;
    const instrument = _.replace(key, '-I', '');

    if (_.includes(key, '-I')) {
      const monthlyExpiryDate = moment(
        MarketUtility.getUpComingExpiryDates({
          symbol: instrument,
          expiryType: 'monthly',
        }),
        DATE_FORMAT
      )
        .format(EXPIRY_DATE_FORMAT)
        .toUpperCase();
      formattedTradingSymbol = `${instrument}${monthlyExpiryDate}FUT`;
    } else if (_.some(key, (char) => NUMBER_REGEX.test(char))) {
      formattedTradingSymbol = instrumentFromSATradingSymbol(key).toString();
    } else formattedTradingSymbol = key;

    const marketStartObj = getMappingTimeObj(data, marketStartTime);
    const firstOiObj = getMappingTimeObj(data, firstOiStartTime);
    const marketCurrentObj = getMappingTimeObj(data, marketCurrentTime);
    const marketPrevDayObj = getMappingTimeObj(data, marketPrevDayEndTime);

    const firstOpen = marketStartObj.open || marketPrevDayObj?.close || 0;
    const firstOi =
      firstOiObj.open_interest || marketPrevDayObj.open_interest || 0;

    finalData.marketData[`${formattedTradingSymbol}`] = {
      ltp: _.get(marketCurrentObj, 'close', 0),
      oi: _.get(marketCurrentObj, 'open_interest', 0),
      firstOi: firstOi,
      firstOpen: firstOpen,
      prevDayLtp: _.get(marketPrevDayObj, 'close', 0),
      prevDayOi: _.get(marketPrevDayObj, 'open_interest', 0),
    };
  });

  return finalData;
};

export default getHistoricFormattedData;
