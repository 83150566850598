import {
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import _ from 'lodash';
import {
  convertToNumber,
  getCancellableOrderStatus,
  parseExchangeTokenTradingSymbol,
} from '../utils';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import moment from 'moment';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { 
  getTradingSymbolsByExchangeTokens 
} from 'habitual-analytics/api/services/getTradingSymbolsByExchangeTokens';
import { parseOrderDetailToGetTradingSymbolObj } from './workmate';
import { getDefaultProductCode } from '../tradingSymbolParser';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import dayjs from 'dayjs';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

const isNseSegement = (exchange) => {
  return exchange === 'N';
};
const formattedTime = (time) => {
  let cleanedStr = time.replace('/Date(', '').replace(')', '');
  let parts = cleanedStr.split('+');
  let timestamp = parts[0];
  return _.parseInt(timestamp);
};

const parseProductCode = (pCode) => {
  let productCode;
  switch (_.toLower(pCode)) {
    case 'i':
      productCode = _.toUpper('intraday');
      break;
    case 'd':
      productCode = _.toUpper('delivery');
      break;
    default:
      productCode = '';
      break;
  }
  return productCode;
};

const parseProductType = (orderDetail) => {
  const atMarket = _.get(orderDetail, 'AtMarket', '') === 'Y';
  const withSL = _.get(orderDetail, 'WithSL', '') === 'Y';
  let productType;
  switch (true) {
    case atMarket:
      productType = 'MKT';
      break;
    case withSL:
      productType = 'SL';
      break;
    default:
      productType = 'LIMIT';
      break;
  }
  return productType;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let placedOrderStatus = statusConfigs.placed.value;
  const status = _.get(orderDetail, 'OrderStatus', '');

  switch (status) {
    case 'Fully Executed':
      placedOrderStatus = statusConfigs.executed.value;
      break;
    case 'Cancelled':
      placedOrderStatus = statusConfigs.cancelled.value;
      break;
    case 'Rejected By 5P':
      placedOrderStatus = statusConfigs.failed.value;
      break;
    default:
      placedOrderStatus = '';
      break;
  }
  return placedOrderStatus;
};

const parserOrderDetails = async (orderConfig) => {
  const { FIVEPAISA_API_APP_SOURCE } = getDynamicAppConfigs().envs;
  const { tradingSymbolObj } = orderConfig;
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolID = await getExchangeToken(formattedExchangeTokenTradingSymbol);

  return {
    Exchange: 'N',
    ExchangeType: tradingSymbolObj.isEquity() ? 'C' : 'D',
    Qty: _.get(orderConfig, 'qty', 0),
    Price: _.get(orderConfig, 'price', 0),
    OrderType: _.capitalize(_.get(orderConfig, 'transactionType', '')),
    IsIntraday: _.get(orderConfig, 'pCode', '') === 'MIS',
    StopLossPrice: _.get(orderConfig, 'trigPrice', 0),
    DisQty: 0,
    IsStopLossOrder: _.get(orderConfig, 'prctyp', '') === 'SL',
    IsIOCOrder: false,
    IsAHOrde: 'N',
    AppSource: FIVEPAISA_API_APP_SOURCE,
    ScripCode: symbolID,
  };
};

const parseTradingSymbolByExchangeToken = async (exchangeToken) => {
  const exchangeTokens = _.map(exchangeToken, (arr) =>
    _.get(arr, 'ScripCode', '')
  );
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const missingTokens = _.filter(exchangeTokens, (token) =>
    _.isEmpty(tradingSymbolsByExchangeTokens[token])
  );

  if (missingTokens.length > 0) {
    const fetchedTokens = await getTradingSymbolsByExchangeTokens(
      missingTokens
    );
    const mergedTokens = _.merge(
      {},
      tradingSymbolsByExchangeTokens,
      fetchedTokens
    );
    initDynamicAppConfigs({
      tradingSymbolsByExchangeTokens: mergedTokens,
    });
  }
};

const parseOrderBook = async (orderDetails) => {
  await parseTradingSymbolByExchangeToken(orderDetails);

  const formattedOrderBook = _.map(orderDetails, (orderDetail) => {
    const exchange = _.get(orderDetail, 'Exch', '');
    const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
    if (
      !isNseSegement(exchange) ||
      !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
    ) {
      return null;
    }
    const status = sanitizeAndParseOrderStatus(orderDetail);
    const tradedTime = formattedTime(_.get(orderDetail, 'BrokerOrderTime', ''));
    const failedReason = _.get(orderDetail, 'Reason', '');
    const productType = parseProductType(orderDetail);
    const productCode = parseProductCode(_.get(orderDetail, 'DelvIntra', ''));
    const isCancellableOrder = getCancellableOrderStatus(status);
    const quantity = _.get(orderDetail, 'Qty', 0);
    const tradedQty = _.get(orderDetail, 'TradedQty', 0);
    const avgPrice = convertToNumber(_.get(orderDetail, 'AveragePrice', 0));
    const price = convertToNumber(_.get(orderDetail, 'Rate', 0));
    const targetPrice = _.get(orderDetail, 'SLTriggerRate', 0);
    const tradedPrice =
      _.parseInt(targetPrice) !== 0
        ? `${price} / ${targetPrice} trg`
        : _.parseInt(avgPrice) !== 0
          ? avgPrice.toFixed(2)
          : price.toFixed(2);

    return {
      tradingSymbolObj,
      time: dayjs(tradedTime)?.format(PRETTIER_FORMAT_WITH_SECONDS),
      type:
        _.get(orderDetail, 'BuySell', '') === 'S'
          ? transactionTypes?.sell.value
          : transactionTypes?.buy?.value,
      status: isCancellableOrder ? statusConfigs.pending.value : status,
      isCancellableOrder,
      failedReason,
      extraDetails: {
        product: `${productCode} / ${productType}`,
        qty: `${Math.abs(tradedQty)} / ${Math.abs(quantity)}`,
        tradedPrice,
        orderNo: _.get(orderDetail, 'BrokerOrderId', ''),
        exchOrderID: _.toNumber(_.get(orderDetail, 'ExchOrderID', '')),
      },
    };
  });
  return formattedOrderBook;
};

const getFormattedTime = (time) => {
  const regex = /(\d+)/;
  const match = regex.exec(time);
  const timestamp = parseInt(match[1]);

  return moment(timestamp).utcOffset('+0530').format('HH:mm:ss');
};

const parseTradeBook = async (orderDetails) => {
  await parseTradingSymbolByExchangeToken(orderDetails);
  const formattedOrderBook = _.map(orderDetails, (orderDetail) => {
    const exchange = _.get(orderDetail, 'Exch', '');
    const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
    if (
      !isNseSegement(exchange) ||
      !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
    ) {
      return null;
    }
    const tradedTime = getFormattedTime(
      _.get(orderDetail, 'ExchangeTradeTime', '')
    );
    const productType = parseProductType(orderDetail);
    const productCode = parseProductCode(_.get(orderDetail, 'DelvIntra', ''));
    const tradedQuantity = Number(_.get(orderDetail, 'OrgQty', 0));
    const quantity = Number(_.get(orderDetail, 'Qty', 0));
    const status = statusConfigs.executed.label;

    if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
      return null;
    }

    return {
      tradingSymbolObj,
      time: moment(tradedTime, TIME_FORMAT)?.format(
        PRETTIER_FORMAT_WITH_SECONDS
      ),
      type:
        _.get(orderDetail, 'BuySell', '') === 'B'
          ? transactionTypes.buy.value
          : transactionTypes.sell.value,
      status,
      extraDetails: {
        product: `${productCode} / ${productType}`,
        qty: `${tradedQuantity} / ${quantity}`,
        tradedPrice: convertToNumber(_.get(orderDetail, 'Rate', 0)),
      },
    };
  });
  return formattedOrderBook;
};

const parsePositionBook = async (orderDetails) => {
  await parseTradingSymbolByExchangeToken(orderDetails);
  const formattedOrderBook = _.map(orderDetails, (orderDetail) => {
    const exchange = _.get(orderDetail, 'Exch', '');
    const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
    if (
      !isNseSegement(exchange) ||
      !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
    ) {
      return null;
    }

    let avgRate = _.get(orderDetail, 'AvgRate', 0);
    const qty = Number(_.get(orderDetail, 'NetQty', 0));

    if (avgRate === 0) {
      avgRate =
        qty > 0
          ? _.get(orderDetail, 'BuyAvgRate', 0)
          : _.get(orderDetail, 'SellAvgRate', 0);
    }

    const ltp = convertToNumber(_.get(orderDetail, 'ltp'));
    const realisedprofitloss = _.round(_.get(orderDetail, 'BookedPL', 0), 2);
    const unRealisedProfitLoss = (ltp - avgRate) * qty;
    const type =
      Number(qty) < 0
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value;
    const profitLoss = qty === 0 ? realisedprofitloss : unRealisedProfitLoss;

    return {
      ...orderDetail,
      tradingSymbolObj,
      qty,
      avgRate,
      ltp,
      profitLoss,
      extraDetails: {
        product: parseProductCode(_.get(orderDetail, 'OrderFor', '')),
        liveUpdateDetails: {
          symbol: tradingSymbolObj.toString(),
          value: 'ltp',
          key: 'ltp',
        },
        defaultProductCode: getDefaultProductCode(
          parseProductCode(_.get(orderDetail, 'OrderFor', '')),
          tradingSymbolObj
        ),
        order: orderDetail,
        isOpenPosition: qty !== 0,
        type: qty !== 0 ? type : '',
      },
    };
  });
  return formattedOrderBook;
};

const parseHoldingsBook = (orderDetail) => {
  const tradingSymbol = _.get(orderDetail, 'Symbol', '');
  const exchange = _.get(orderDetail, 'Exch', '');
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);

  if (
    !isNseSegement(exchange) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }
  const quantity = _.get(orderDetail, 'Quantity', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'AvgRate', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'CurrentPrice', 0));
  const profitLoss = getRoundedData((ltp - orderValue) * quantity);
  const netChg = getRoundedData((profitLoss / orderValue) * 100);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${_.get(orderDetail, 'PoolQty', 0)})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'CurrentPrice',
      },
      order: orderDetail,
    },
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parserOrderDetails,
  parseOrderBook,
  parsePositionBook,
  parseTradeBook,
  parseHoldingsBook,
  parseOrderDetails,
};
