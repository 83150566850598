import _ from 'lodash';
import { SOCKET_SERVER_EVENTS } from 'habitual-analytics/constants/habitual-configs';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { io } from 'socket.io-client';
import Instrument from 'habitual-analytics/models/instrument';
import { displayInstrumentToSATradingSymbol } from 'habitual-analytics/models/instrument/factory';
import pRetry from 'p-retry';
import { DummyEmit } from '../dummyEmit';

const tickProcessor = (rawTicks, additionalData, previousStateTicks) => {
  const { marketData = {} } = rawTicks;
  const newMarketData = _.reduce(
    marketData,
    (result, obj, symbol) => {
       
      result[symbol] = {
        ltp: obj?.price || _.get(previousStateTicks, `marketData.${symbol}.ltp`, 0),
        oi: obj?.openInterest || _.get(previousStateTicks, `marketData.${symbol}.oi`, 0),
        firstOpen: additionalData[symbol]?.firstOpen || 0,
        prevDayLtp: additionalData[symbol]?.prevDayLtp || 0,
        prevDayOi: additionalData[symbol]?.prevDayOi || 0,
        firstOi: additionalData[symbol]?.firstOi || 0,
      };
      return result;
    },
    {}
  );

  return { ...rawTicks, marketData: newMarketData };
};

export const ticker = () => {
  let genericTicker;

  const onState = (rawTicks) => {
    const ticks = tickProcessor(
      _.omit(rawTicks, 'marketDataExtras'),
      genericTicker.additionalData,
      genericTicker.state
    );
    genericTicker.state = ticks;

    DummyEmit.runWhenRequired(genericTicker);
    genericTicker.onState([ticks]);
  };

  const processTicks = (rawTicks) => {
    const ticks = tickProcessor(rawTicks, genericTicker.additionalData, genericTicker.state);
    genericTicker.state = ticks;
    
    genericTicker.onTick([ticks]);
  };

  // Debounce the processTicks function to wait before emitting the last tick
  const onData = _.debounce(processTicks, 400);  // 400ms delay (adjust as needed);

  return {
    init(argGenericTicker) {
      genericTicker = argGenericTicker;
    },

    async start() {
      // To Avoid MaxListenersExceededWarning, as we expect at the max 100 jobs to listen to
      // a single singletonLiveTickerClient.
      // this.setMaxListeners(250);

      return new Promise((resolve, reject) => {
        genericTicker.socket = io(
          getDynamicAppConfigs().envs.ANALYTICS_TICKER_URI,
          _.merge(
            {
              query: { instruments: genericTicker.instruments.join(','), clientName: 100 },
              transports: ['websocket'],
              reconnection: true,
              reconnectionDelay: 5 * 1000,
            },
            {
              Authorization: `Bearer ${getDynamicAppConfigs().envs.ANALYTICS_TICKER_AUTH}`,
              path: `/${getDynamicAppConfigs().envs.ANALYTICS_TICKER_PATH}/socket.io`,
            }
          )
        );

        genericTicker.socket
          .on(SOCKET_SERVER_EVENTS.STATE, onState)
          .on(SOCKET_SERVER_EVENTS.TICKS, onData)
          .on(SOCKET_SERVER_EVENTS.ERROR, (error) => {
            genericTicker.debugLog(`[LT] ${SOCKET_SERVER_EVENTS.ERROR}... ${error}`);
          })
          .once('connect', () => {
            genericTicker.debugLog('[LT] connected');
            resolve();
          })
          .once('connect_error', (err) => {
            console.log('[LT] connect_error', err, err && err.description);
            genericTicker.debugLog('[LT] connect_error', err);
            reject(new Error('connect_error'));
          })
          .on('disconnect', (status) => {
            // Do not destroy or stop here, as socket io will automatically try to reconnect...
            genericTicker.debugLog(`[LT] socket disconnecting... status: ${status}`);
          });

        genericTicker.socket.io
          .on('reconnect_attempt', () => {
            genericTicker.socket.io.opts.query.instruments = genericTicker.instruments.join(',');
          })
          .on('reconnect', () => {
            genericTicker.debugLog('[LT] reconnect success');
          })
          .once('connect_timeout', () => {
            genericTicker.debugLog('[LT] connect_timeout');
            reject(new Error('connect_timeout'));
          });
      });
    },

    // https://socket.io/docs/v4/client-offline-behavior/ -> Volatile Events
    async subscribe(newInstruments) {
      const subscribeToServer = () => {
        const subscribePromise = new Promise((resolve, reject) => {
          try {
            genericTicker.socket
              .timeout(4 * 1000)
              .volatile.emit(
                'subscribe',
                newInstruments,
                100,
                (err, { message, state: additionalState, additionalData } = {}) => {
                  if (err || message === 'error') {
                    const errMsg = `[LT-${100}] server subscribe failed. ${err} Server Error: ${message}`;
                    return reject(new Error(errMsg));
                  }
                  const tradingSymbols = _.keys(additionalData);

                  const additionalTradingSymbolObjs = _.map(tradingSymbols, (tradingSymbol) => ({
                    tradingSymbolObj: new Instrument({
                      ...displayInstrumentToSATradingSymbol(tradingSymbol),
                    }),
                  }));
                  if (_.isEmpty(additionalTradingSymbolObjs)) {
                    return resolve([]);
                  }

                  const newAdditionalData = _.reduce(
                    additionalData,
                    (result, obj, symbol) => {
                      result[symbol] = {
                        firstOpen: Number(obj?.firstOpen) || Number(obj?.prevDayLtp),
                        prevDayLtp: Number(obj?.prevDayLtp) || 0,
                        firstOi: Number(obj?.firstOi) || Number(obj?.prevDayOi), 
                        prevDayOi: Number(obj?.prevDayOi) || 0,
                      };
                      return result;
                    },
                    {}
                  );

                  genericTicker.additionalData = _.merge(
                    genericTicker.additionalData,
                    newAdditionalData
                  );
                  const ticks = tickProcessor(
                    {
                      marketData: _.merge(
                        _.get(genericTicker.state, 'marketData', {}),
                        additionalState
                      ),
                    },
                    genericTicker.additionalData,
                    genericTicker.state
                  );
                  genericTicker.debugLog(
                    `[LT-${100}] server subscribe success. Instruments ${newInstruments}`
                  );
                  genericTicker.state = ticks;
                  genericTicker.onState([ticks]);
                  return resolve([ticks]);
                }
              );
          } catch (e) {
            genericTicker.debugLog(e, 'Rejected by Error');
            reject(e);
          }
        });
        return subscribePromise;
      };
      return pRetry(subscribeToServer, {
        retries: 5,
        onFailedAttempt: (error) => {
          genericTicker.debugLog(`[LT] server subscribe failed attempt: ${error}.`);
        },
      });
    },

    async unsubscribe(staleInstruments) {
      return new Promise((resolve, reject) => {
        genericTicker.socket
          .timeout(10 * 1000)
          .volatile.emit('unsubscribe', staleInstruments, 100, (err, { message } = {}) => {
            if (err || message === 'error') {
              const errMsg = '[LT] server unsubscribe failed.'
                + `Error: ${JSON.stringify(err)} Server Error: ${message}`;
              genericTicker.debugLog(errMsg);
              return reject(new Error(errMsg));
            }
            genericTicker.debugLog(
              '[LT] INSTRUMENTS AFTER UNSUBSCRIBED: ',
              genericTicker.instruments
            );
            genericTicker.debugLog('[LT] server unsubscribe success.');
            return resolve();
          });
      });
    },

    destroy() {
      genericTicker.socket.disconnect();
    },
  };
};
