import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'react-bootstrap';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  children: null,
  isLoading: false,
  className: '',
};

const LoadingSpinner = (props) => {
  const { children, isLoading, className } = props;
  const combinedClassName = classNames('common-component-loading-spinner', className);
  const spinnerContainerClassNames = classNames('spinner-container', { show: isLoading });

  return (
    <div className={combinedClassName}>
      <div className={spinnerContainerClassNames}>
        <Spinner variant="primary" className="spinner" animation="border" />
      </div>
      {children}
    </div>
  );
};

LoadingSpinner.propTypes = propTypes;
LoadingSpinner.defaultProps = defaultProps;

export default LoadingSpinner;
