export const DATE_FORMAT = 'YYYY-MM-DD';
export const ANALYTICS_MARKET_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:59';
// TODO - changed from this YYYY-MM-DD to this [DD-MMM-YYYY] 
export const PRETTIER_FORMAT_WITH_SECONDS = 'DD-MMM-YYYY HH:mm:ss';
export const TABLE_FORMAT = 'DD MMM YYYY, HH:mm:ss';
export const TIME_FORMAT = 'HH:mm:ss';
export const EXPIRY_DATE_FORMAT = 'DDMMMYY';
export const DISPLAY_FORMAT = 'DD MMM YYYY';
export const WATCHLIST_DISPLAY_FORMAT = 'DD MMM YYYY';
export const TICKS_DB_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:00.000+00:00';
export const CHART_TIME_FORMAT = 'HH:mm';
export const NEW_DISPLAY_FORMAT = 'DD MMM YY';
export const NEW_DISPLAY_FORMAT_WITH_TIME = 'DD MMM YY, HH:mm';
export const ENTRY_OR_EXIT_DATE_FORMAT = 'DD MMM YY, HH:mm:ss';
export const TRADING_VIEW_LIVE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:00+00';
export const DAY_NAME = 'dddd';
export const TIME_SCALE_DB_FORMAT = 'YYYY-MM-DD HH:mm:ss+00';
export const LOCAL_DATE_STRING_FORMAT = 'DD/MM/YYYY';
export const TABLE_FORMAT_SHORT_DATE = 'DD-MMM-YYYY';
export const MOBILE_HISTORIC_DATE_FORMAT = 'DD-MM-YY';
export const HISTORIC_DATE_AND_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
