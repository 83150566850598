const getLabelConfigs = (isEquity) => {
  return {
    placeOrderLabelConfigs: {
      mis: 'INTRADAY',
      cnc: isEquity ? 'DELIVERY' : 'OVERNIGHT',
      nrml: 'OVERNIGHT',
    },
  };
};

const getDefaultConfigs = (isEquity, isFuture) => {
  return {
    productCode: (isEquity || isFuture ) ? 'CNC' : 'NRML',
  };
};

export { getDefaultConfigs, getLabelConfigs };


