import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import RestEndPoints from './RestEndPoints';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import { parseOrderDetails } from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';

export const REQUEST_PHASE_URL = () => {};

const getHeaders = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  return {
    Authorization: `Bearer ${brokerClientAccessToken}`,
    'Api-Version': 2.0
  };
};

const isValidAccessToken = () => {
  const parameters = { headers: getHeaders() };

  const uri = RestEndPoints.PROFILE_PATH;
  return wrappedFetch(uri, parameters)
    .then((res) => {
      return res.ok;
    })
    .catch(() => false);
};

const fetchAccessToken = () => {};

const fetchOrderBook = () => {
  const ApiUrl = RestEndPoints.GET_ORDER_BOOK;
  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(parseOrderDetails(result?.data, 'order'), ['time'], ['desc'])
        ),
        headers: orderTableHeaders,
      };
    });
};

const fetchTradeBook = () => {
  const ApiUrl = RestEndPoints.GET_TRADE_BOOK;
  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(parseOrderDetails(result?.data, 'trade'), ['time'], ['desc'])
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const ApiUrl = RestEndPoints.UPDATE_POSITION_BOOK;
  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result?.data, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const ApiUrl = RestEndPoints.GET_HOLDINGS;
  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        holdingBookDetails: _.compact(parseOrderDetails(result?.data, 'holdings')),
        headers: holdingTableHeaders,
      };
    });
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  isValidAccessToken,
  fetchAccessToken,
};
