const getLabelConfigs = () => {
  return {
    placeOrderLabelConfigs: {
      mis: 'INTRADAY',
      nrml: 'MARGIN',
    },
    tabsLabelConfigs: {
      orderBook: 'Orders',
      tradeBook: 'Trades',
      positionBook: 'All Positions',
      holdingBook: 'Holdings',
      basketOrders: 'Baskets',
    },
  };
};

export { getLabelConfigs };
