import React, { useEffect, useState } from 'react';
import { genericTicker } from 'habitual-analytics/client/GenericTicker';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { PropTypes } from 'prop-types';
import { getCurrentLiveTicker } from 'habitual-analytics/client/BrokerTickers/index';
import LoadingSpinner from 'globals/components/LoadingSpinner/index';
import HaltingAndInitiate from './HaltingAndInitiate';
import LiveTickerError from './LiveTickerError';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultProps = {};

const SingletonLiveTickerWrapper = ({ children }) => {
  const [isLiveTickerStarted, setIsLiveTickerStarted] = useState(false);
  const [isLiveTickerError, setIsLiveTickerError] = useState(false);

  useEffect(() => {
    const currentLiveTicker = getCurrentLiveTicker();
    // started singleton live ticker client
    genericTicker
      .start(getDynamicAppConfigs().brokerConfigs, currentLiveTicker)
      .then(() => {
        setIsLiveTickerStarted(true);
        console.log('Generic Ticker Started Successfully');
      })
      .catch((err) => {
        setIsLiveTickerError(true);
        console.log('Generic Ticker not Started Issue', err);
      });

    // when app close we have to destroy singleton live ticker client
    return () => genericTicker.silentDestroy();
  }, []);

  if (isLiveTickerError) {
    return <LiveTickerError />;
  }

  if (!isLiveTickerStarted) {
    return <LoadingSpinner />;
  }

  return (
    <HaltingAndInitiate>{children}</HaltingAndInitiate>
  );
};

SingletonLiveTickerWrapper.propTypes = propTypes;
SingletonLiveTickerWrapper.defaultProps = defaultProps;

export default SingletonLiveTickerWrapper;
