import {
  CHART_TIME_FORMAT,
  DATE_FORMAT,
  HISTORIC_DATE_AND_TIME_FORMAT
} from 'habitual-analytics/dateUtils/dateFormats';
import { getMarketTimingConfigs } from 'habitual-analytics/dateUtils/index';
import moment from 'moment';
import { getLocalStorage } from 'globals/utils/localStorage';
import { getCurrentLocalizeDate } from 'habitual-analytics/utils/timezone';
import { getAnalyticsUrlParams } from 'globals/utils/url';
import { userRunTypes } from 'habitual-analytics/constants/habitual-configs';

const getDefaultHistoricDateTime = () => {
  return getMarketTimingConfigs()?.marketPrevDayEndTime.replace('T', ' ');
};

const isValidHistoricalDateTime = (dateTime = '') => {
  if (!dateTime) return false;

  const [date, time] = dateTime.split(' ');
  const isDateValid = moment(date, DATE_FORMAT, true).isValid();
  const isTimeValid = moment(time, CHART_TIME_FORMAT, true).isValid();

  const currentLocalDateAndTime = getCurrentLocalizeDate().subtract(1, 'day').toDate();
  const isAfterDate = moment(date, DATE_FORMAT).startOf('day')
    .isAfter(moment(currentLocalDateAndTime).startOf('day'), 'day');

  return !isAfterDate && isDateValid && isTimeValid;
};

export const getProcessedHistoricalDateTime = () => {
  const { historicalDateAndTime: urlHistoricDateAndTime } = getAnalyticsUrlParams() || {};
  const { historicalDateAndTime: storedHistoricDateAndTime } =
    JSON.parse(getLocalStorage('historicalConfigs') || '{}');

  const prioritizedDatetime = urlHistoricDateAndTime || storedHistoricDateAndTime;
  const dateTime = isValidHistoricalDateTime(prioritizedDatetime)
    ? prioritizedDatetime
    : getDefaultHistoricDateTime();

  return {
    historicalDate: moment(dateTime, HISTORIC_DATE_AND_TIME_FORMAT).format(DATE_FORMAT),
    historicalTime: moment(dateTime, HISTORIC_DATE_AND_TIME_FORMAT).format(CHART_TIME_FORMAT)
  };
};

export const getDefaultHistoricConfigs = () => {
  const { userRunType: urlUserRunType } = getAnalyticsUrlParams() || {};
  const { userRunType: storedUserRunType } =
    JSON.parse(getLocalStorage('historicalConfigs') || '{}');

  const { historicalDate, historicalTime } = getProcessedHistoricalDateTime();

  const isLive =
    (urlUserRunType === undefined || urlUserRunType === userRunTypes.live) &&
    (storedUserRunType === undefined || storedUserRunType === userRunTypes.live);


  return {
    userRunType: isLive ? userRunTypes.live : userRunTypes.historical,
    historicalDateAndTime: `${historicalDate} ${historicalTime}`,
  };
};
