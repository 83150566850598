import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.element,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  size: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
  customWidth: PropTypes.string,
  shouldConfirmClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  okBtnTitle: PropTypes.string,
  onCancel: PropTypes.func,
  cancelBtnTitle: PropTypes.string,
  btnClassName: PropTypes.string,
  backdrop: PropTypes.string,
  onSaveid: PropTypes.string,
  modalSaveButtonProps: PropTypes.shape({}),
  isOkBtnDisabled: PropTypes.bool,
  shouldShow: PropTypes.bool,
  isShowCloseButton: PropTypes.bool,
  titleClassName: PropTypes.string
};
const defaultProps = {
  children: null,
  onSave: null,
  title: '',
  okBtnTitle: 'Save',
  size: '',
  message: null,
  className: '',
  customWidth: '',
  onClose: _.noop,
  shouldConfirmClose: false,
  onCancel: null,
  cancelBtnTitle: 'Cancel',
  btnClassName: '',
  backdrop: '',
  onSaveid: '',
  modalSaveButtonProps: {},
  isOkBtnDisabled: false,
  shouldShow: true,
  isShowCloseButton: true,
  titleClassName: ''
};

export default class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isClosing: false };
  }

  onHide = () => {
    const { onClose, shouldConfirmClose } = this.props;

    if (_.isFunction(shouldConfirmClose) ? () => shouldConfirmClose() : shouldConfirmClose) {
      this.setState({ isClosing: true });
    } else {
      onClose();
    }
  };

  onConfirmedClose = () => {
    const { onClose } = this.props;

    this.setState({ isClosing: false });
    onClose();
  };

  renderCloseConfirmation = () => (
    <>
      <div className="modal-body">
        <div className="confirmation-window text-center">
          <h5>Confirmation</h5>
          <span className="material-icons-outlined tx-25 rounded-circle p-2 alert-info my-2">
            info
          </span>

          <p className="m-0">Are you sure you want to cancel.</p>
        </div>
      </div>

      <div className="modal-footer d-flex align-items-center flex-nowrap">
        <button
          type="button"
          className="btn btn-outline-primary btn-block mr-3"
          onClick={this.onConfirmedClose}
        >
          Yes
        </button>

        <button
          type="button"
          autoFocus
          className="btn btn-primary btn-block m-0"
          onClick={() => this.setState({ isClosing: false })}
        >
          No
        </button>
      </div>
    </>
  );

  render() {
    const {
      children, title, onSave, size, className, customWidth, okBtnTitle,
      btnClassName, message, onCancel, cancelBtnTitle, backdrop, onSaveid,
      modalSaveButtonProps, isOkBtnDisabled, shouldShow, isShowCloseButton, titleClassName
    } = this.props;
    const { isClosing } = this.state;

    let modalProps = {
      size: isClosing ? 'sm' : size,
      show: shouldShow,
      dialogClassName: customWidth,
      onHide: this.onHide,
      className,
      centered: true,
      'aria-labelledby': 'contained-modal-title-vcenter',
    };

    if (backdrop) modalProps = { ...modalProps, backdrop };

    const renderModalFooter = () => {
      const isShow = message || onSave || onCancel;
      if (!isShow) return null;

      return (
        <Modal.Footer>
          {message && <div className="alert alert-danger">{message}</div>}
          {onCancel && (
            <Button variant="secondary mr-auto" onClick={onCancel}>{cancelBtnTitle}</Button>
          )}
          {onSave && (
            <Button
              {...modalSaveButtonProps}
              variant="primary"
              className={btnClassName}
              onClick={onSave}
              id={onSaveid}
              disabled={isOkBtnDisabled}
            >
              {okBtnTitle}
            </Button>
          )}
        </Modal.Footer>
      );
    };

    return (
      <Modal {...modalProps}>
        {isClosing ? this.renderCloseConfirmation() : (
          <>
            <Modal.Header closeButton={isShowCloseButton}>
              <Modal.Title className={titleClassName} id="contained-modal-title-vcenter">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            {renderModalFooter()}
          </>
        )}
      </Modal>
    );
  }
}

ModalComponent.propTypes = propTypes;
ModalComponent.defaultProps = defaultProps;
