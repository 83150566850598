import { wrappedFetch } from './base/index';
import { REST_URLS } from 'habitual-analytics/api/resturls';

export const getTradingSymbolsByExchangeTokens = (exchangeTokens) => {
  const { GET_TRADING_SYMBOLS_BY_EXCHANGE_TOKENS } = REST_URLS();

  const uri = encodeURI(`${GET_TRADING_SYMBOLS_BY_EXCHANGE_TOKENS}?exchangeTokens=${exchangeTokens}`);

  const parameters = {
    method: 'GET',
    credentials: 'same-origin'
  };

  return wrappedFetch(uri, parameters)
    .then((response) => response.json());
};
