 
import { transactionTypes } from 'habitual-analytics/constants/habitual-configs';
import moment from 'moment';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import _ from 'lodash';
import { parsePlaceOrderTradingSymbol } from './workmate';
import { convertToNumber, getTradingSymbolObj } from '../utils';

const getorderTypeNumber = (orderTYpe) => {
  let orderTypeNUmber;
  if (orderTYpe === 'l') {
    orderTypeNUmber = 1;
  } else if (orderTYpe === 'mkt') {
    orderTypeNUmber = 2;
  } else if (orderTYpe === 'sl-m') {
    orderTypeNUmber = 3;
  } else if (orderTYpe === 'sl-l') {
    orderTypeNUmber = 4;
  }
  return orderTypeNUmber;
};

// const getParsedOrderType = (typeNumer) => {
//   let orderType;
//   if (typeNumer === '1') {
//     orderType = 'l';
//   } else if (typeNumer === '2') {
//     orderType = 'mkt';
//   } else if (typeNumer === '3') {
//     orderType = 'sl-m';
//   } else if (typeNumer === '4') {
//     orderType = 'sl-l';
//   }
//   return orderType;
// };

const parseOrderBook = (orderDetail) => {
  const tradingSymbol = _.get(orderDetail, 'symbol', '');
  const productType = _.get(orderDetail, 'productType', '');
  // const productCode = _.get(orderDetail, 'type', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'tradedPrice', ''));
  const price = convertToNumber(_.get(orderDetail, 'limitPrice', ''));
  const tradedQty = _.get(orderDetail, 'filled_qty', 0);
  const quantity = _.get(orderDetail, 'qty', 0);
  const avgPrice = convertToNumber(_.get(orderDetail, 'Avgprc', 0));
  const tradedPrice = targetPrice !== 0 ? `${price} / ${targetPrice} trg` : avgPrice !== 0 ? avgPrice : price;
  const tradedTime = _.get(orderDetail, 'orderTimeStamp', '').split(' ')?.[1];

  return {
    ...getTradingSymbolObj(tradingSymbol),
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'side', '') === '1'
        ? transactionTypes?.buy.value
        : transactionTypes?.sell?.value,
    status: _.get(orderDetail, 'status', ''),
    extraDetails: {
      product: productType,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
    },
  };
};

const parsePlaceOrder = async (item) => {
  const { tradingSymbolObj } = item;
  const formattedTradingSymbol = parsePlaceOrderTradingSymbol(tradingSymbolObj);
  const transactionType = _.get(item, 'transactionType', '');
  const productType = _.get(item, 'prctyp', '');

  return {
    symbol: formattedTradingSymbol,
    qty: _.get(item, 'qty', ''),
    type: getorderTypeNumber(_.toLower(productType)),
    side: _.toLower(transactionType) === 'buy' ? 1 : -1,
    productType: _.get(item, 'pCode', ''),
    limitPrice: _.get(item, 'price', ''),
    stopPrice: _.get(item, 'trigPrice', ''),
    disclosedQty: 0,
    validity: 'DAY',
    offlineOrder: 'False',
    stopLoss: 0,
    takeProfit: 0,
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export { parseOrderDetails, parsePlaceOrder };
