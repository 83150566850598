import _ from 'lodash';
import { getEncryptSha256Hex } from 'habitual-analytics/utils';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import RestEndPoints from './RestEndPoints';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import { parseModifyOrder, parseOrderDetails, parsePlaceOrder } from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { getPlaceOrderTradingSymbol } from '../tradingSymbolParser';

export const REQUEST_PHASE_URL = () => {
  const apiKey = getDynamicAppConfigs().envs.ALICEBLUE_API_KEY;

  return `${RestEndPoints.BASE_URI}?appcode=${apiKey}`;
};

const getHeaders = () => {
  const { brokerClientId, brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  return {
    Authorization: `Bearer ${brokerClientId} ${brokerClientAccessToken}`,
  };
};

const isValidAccessToken = () => {
  const parameters = { headers: getHeaders() };

  const uri = RestEndPoints.PROFILE_PATH;
  return wrappedFetch(uri, parameters)
    .then((res) => {
      return res.ok;
    })
    .catch(() => false);
};

const fetchAccessToken = (configs) => {
  const secretKey = getDynamicAppConfigs().envs.ALICEBLUE_SECRET_KEY;
  // configs = { authCode, userId }
  const authCode = _.get(configs, 'authCode', '');
  const brokerClientId = _.get(configs, 'userId', '');

  const uri = RestEndPoints.VALID_AUTHCODE_PATH;

  const parameters = {
    method: 'POST',
    body: JSON.stringify({
      checkSum: getEncryptSha256Hex(`${brokerClientId}${authCode}${secretKey}`),
    }),
  };

  return wrappedFetch(uri, parameters).then((response) => response.json());
};

const fetchOrderBook = () => {
  const ApiUrl = RestEndPoints.GET_ORDER_BOOK;
  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(parseOrderDetails(result, 'order'), ['time'], ['desc'])
        ),
        headers: orderTableHeaders,
      };
    });
};

const fetchTradeBook = () => {
  const ApiUrl = RestEndPoints.GET_TRADE_BOOK;
  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(parseOrderDetails(result, 'trade'), ['time'], ['desc'])
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const ApiUrl = RestEndPoints.UPDATE_POSITION_BOOK;

  const request = {
    ret: 'NET',
  };

  const parameters = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(request),
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const ApiUrl = RestEndPoints.GET_HOLDINGS;
  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ HoldingVal }) => {
      return {
        holdingBookDetails: _.compact(parseOrderDetails(HoldingVal, 'holdings')),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const ApiUrl = RestEndPoints.PLACE_ORDER;

  const bodyDetails = await parsePlaceOrder(placeOrderConfigs);

  const parameters = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(bodyDetails)
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => _.map(result, ({ NOrdNo }) => NOrdNo))
    .catch((e) => [e]);
};

const cancelPlaceOrder = ({ orderNo, tradingSymbolObj }) => {
  const ApiUrl = RestEndPoints.CANCEL_PLACE_ORDER;
  const bodyDetails = {
    exch: 'NSE',
    nestOrderNumber: orderNo,
    trading_symbol: getPlaceOrderTradingSymbol(tradingSymbolObj),
  };

  const parameters = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(bodyDetails),
    redirect: 'follow',
  };

  return wrappedFetch(ApiUrl, parameters).then((response) => response.json());
};

const modifyPlaceOrder = (orderDetails) => {
  const ApiUrl = RestEndPoints.MODIFY_PLACE_ORDER;
  const bodyDetails = parseModifyOrder(orderDetails);
  const parameters = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(bodyDetails),
    redirect: 'follow',
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json()
      .then(({ stat, nestOrderNumber }) => stat === 'Ok' ? [nestOrderNumber] : null));
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  fetchAccessToken,
  cancelPlaceOrder,
  modifyPlaceOrder
};
