import { isLiveMode } from 'habitual-analytics/utils/index';
import LiveDataAdaptor from './live';
import HistoricDataAdaptor from './historic';
import { genericTicker } from 'habitual-analytics/client/GenericTicker';
import { userRunTypes } from 'habitual-analytics/constants/habitual-configs';
const keysToCheck = [
  'ltp',
  'oi',
  'firstOpen',
  'prevDayLtp',
  'prevDayOi',
  'firstOi',
];

const getLiveDataFromTicker = (instruments) => {
  return _.chain(genericTicker.state?.marketData)
    .reduce((result, obj, instrument) => {
      if (
        instruments.includes(instrument) &&
        _.every(keysToCheck, (key) => _.has(obj, key))
      ) {
        result[instrument] = {
          ltp: obj.ltp,
          oi: obj.oi,
          firstOi: Number(obj?.firstOi) || Number(obj?.prevDayOi) || 0,
          firstOpen: obj.firstOpen || Number(obj?.prevDayLtp) || 0,
          prevDayLtp: obj.prevDayLtp || 0,
          prevDayOi: obj.prevDayOi || 0,
        };
      }

      return result;
    }, {})
    .value();
};

const doResolveQueue = (
  currentRequestQueue,
  instrumentWithLiveData = {},
  instrumentsWithApiData = { marketData: {} }
) => {
  currentRequestQueue.forEach(({ instruments, resolve }) => {
    const filteredMarketData = {};

    instruments.forEach((instrument) => {
      if (instrumentWithLiveData[instrument]) {
        filteredMarketData[instrument] = instrumentWithLiveData[instrument];
      } else if (instrumentsWithApiData.marketData[instrument]) {
        filteredMarketData[instrument] =
          instrumentsWithApiData.marketData[instrument];
      }
    });

    resolve({
      marketData: filteredMarketData,
    });
  });
};

const executeCombinedRequest = (queueType, currentRequestQueue, adaptor) => {
  const allInstruments = Array.from(
    new Set(currentRequestQueue.flatMap(({ instruments }) => instruments))
  );
  const instrumentWithLiveData = queueType === userRunTypes.live
    ? getLiveDataFromTicker(allInstruments)
    : {};
  const remainingInstruments = _.difference(
    allInstruments,
    _.keys(instrumentWithLiveData)
  );

  if (_.isEmpty(remainingInstruments)) {
    doResolveQueue(currentRequestQueue, instrumentWithLiveData);
    return;
  }

  return adaptor
    .getData(remainingInstruments)
    .then((instrumentsWithApiData) => {
      return doResolveQueue(
        currentRequestQueue,
        instrumentWithLiveData,
        instrumentsWithApiData
      );
    });
};

export const InstrumentDataAdaptor = (() => {
  const getMode = () =>
    isLiveMode() ? userRunTypes.live : userRunTypes.historical;
  const getAdaptor = () =>
    isLiveMode() ? LiveDataAdaptor : HistoricDataAdaptor;

  const requestQueues = {
    [userRunTypes.live]: [],
    [userRunTypes.historical]: [],
  };

  const processRequestQueueConfig = _.debounce((queueType, adaptor) => {
    if (requestQueues[queueType]?.length > 0) {
      executeCombinedRequest(queueType, requestQueues[queueType], adaptor);

      requestQueues[queueType] = [];
    }
  }, 500);

  const addToQueue = (queueType, instruments, adaptor) => {
    return new Promise((resolve, reject) => {
      requestQueues[queueType].push({
        instruments: _.split(instruments, ','),
        resolve,
        reject,
      });
      processRequestQueueConfig(queueType, adaptor);
    });
  };

  return {
    getData: async (instruments) => {
      return addToQueue(getMode(), instruments, getAdaptor());
    }
  };
})();
