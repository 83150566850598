import React from 'react';
import PropTypes, { oneOfType, node, string } from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  children: oneOfType([node, string]),
  onClick: PropTypes.func,
  isProcessing: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  iconName: PropTypes.string,
};

const defaultProps = {
  children: '',
  onClick: () => {},
  isProcessing: false,
  disabled: false,
  className: '',
  iconName: '',
};

const Button = ({
  children,
  onClick,
  isProcessing,
  disabled,
  className,
  iconName,
  ...restProps
}) => {
  const divClassName = classNames('btn', {
    [className]: className,
  });

  return (
    <button
      type="button"
      className={divClassName}
      onClick={onClick}
      disabled={isProcessing || disabled}
      {...restProps}
    >
      {iconName && <i className={iconName}></i>}
      {children}
      {isProcessing && <span className="spinner" />}
    </button>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
