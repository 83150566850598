 
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import _ from 'lodash';
import { getEncryptSha256Hex } from 'habitual-analytics/utils';
import RestEndPoints from './RestEndPoints';
import {
  orderTableHeaders,
  tradeTableHeaders,
  posititionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import { parseMarginCalculator, parseOrderDetails, parsePlaceOrder, parsePostbackOrder } from './parse';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import {
  sanitizePlaceOrdersBeforeMarginCalculation,
} from './workmate';

export const REQUEST_PHASE_URL = () => {
  const { FYERS_APP_ID, BASE_URI } = getDynamicAppConfigs().envs;
  const redirectUrl = _.replace(BASE_URI, '.in/', '.fyers.in/');

  return (
    `${RestEndPoints.AUTH_CODE_URL}?client_id=${FYERS_APP_ID}`
    + `&redirect_uri=${redirectUrl}fyers/callback&response_type=code&state=state`
  );
};

const fetchAccessToken = (configs) => {
  const { FYERS_APP_ID, FYERS_SECRET_ID } = getDynamicAppConfigs().envs;
  const authCode = _.get(configs, 'auth_code', '');

  const uri = RestEndPoints.VALIDATE_AUTH_CODE_URL;

  const parameters = {
    method: 'POST',
    body: JSON.stringify({
      appIdHash: getEncryptSha256Hex(`${FYERS_APP_ID}:${FYERS_SECRET_ID}`),
      grant_type: 'authorization_code',
      code: authCode,
    }),
  };

  return wrappedFetch(uri, parameters).then((response) => response.json());
};

const fetchProfile = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { FYERS_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    headers: {
      Authorization: `${FYERS_APP_ID}:${brokerClientAccessToken}`,
    },
  };

  const uri = RestEndPoints.PROFILE_PATH;
  return wrappedFetch(uri, parameters).then((res) => res.json());
};

const isValidAccessToken = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { FYERS_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    headers: {
      Authorization: `${FYERS_APP_ID}:${brokerClientAccessToken}`,
    },
  };

  const uri = RestEndPoints.PROFILE_PATH;
  return wrappedFetch(uri, parameters)
    .then((res) => res.json())
    .then((res) => res.s === 'ok')
    .catch(() => false);
};

const fetchOrderBook = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { FYERS_APP_ID } = getDynamicAppConfigs().envs;

  const ApiUrl = RestEndPoints.GET_ORDER_BOOK;

  const parameters = {
    headers: {
      Authorization: `${FYERS_APP_ID}:${brokerClientAccessToken}`,
    },
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ orderBook }) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(parseOrderDetails(orderBook, 'order'), ['time'], ['desc'])
        ),
        headers: orderTableHeaders,
      };
    });
};

const fetchTradeBook = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { FYERS_APP_ID } = getDynamicAppConfigs().envs;
  const ApiUrl = RestEndPoints.GET_TRADE_BOOK;

  const parameters = {
    headers: {
      Authorization: `${FYERS_APP_ID}:${brokerClientAccessToken}`,
    },
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ tradeBook }) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(parseOrderDetails(tradeBook, 'trade'), ['time'], ['desc'])
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { FYERS_APP_ID } = getDynamicAppConfigs().envs;

  const ApiUrl = RestEndPoints.GET_POSITION_BOOK;

  const parameters = {
    headers: {
      Authorization: `${FYERS_APP_ID}:${brokerClientAccessToken}`,
    },
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ netPositions }) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(netPositions, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: posititionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { FYERS_APP_ID } = getDynamicAppConfigs().envs;

  const ApiUrl = RestEndPoints.GET_HOLDINGS;

  const parameters = {
    headers: {
      Authorization: `${FYERS_APP_ID}:${brokerClientAccessToken}`,
    },
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ holdings }) => {
      return {
        holdingBookDetails: _.compact(parseOrderDetails(holdings, 'holdings')),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const ApiUrl = RestEndPoints.PLACE_ORDER;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { FYERS_APP_ID } = getDynamicAppConfigs().envs;

  const bodyDetails = await parsePlaceOrder(placeOrderConfigs);

  const headers = {
    Authorization: `${FYERS_APP_ID}:${brokerClientAccessToken}`,
  };

  const parameters = {
    method: 'POST',
    headers,
    body: JSON.stringify(bodyDetails)
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ data }) => _.map(data, (item) => item?.body?.id))
    .catch((e) => _.map(placeOrderConfigs, () => e));
};

const cancelPlaceOrder = ({ orderNo }) => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { FYERS_APP_ID } = getDynamicAppConfigs().envs;

  const bodyDetails = {
    id: orderNo,
  };

  const parameters = {
    method: 'DELETE',
    headers: {
      Authorization: `${FYERS_APP_ID}:${brokerClientAccessToken}`,
    },
    body: JSON.stringify(bodyDetails),
  };

  const uri = RestEndPoints.CANCEL_ORDER;

  return wrappedFetch(uri, parameters).then((response) => response.json());
};

const fetchMarginCalculator = async (orderConfigs) => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { FYERS_APP_ID } = getDynamicAppConfigs().envs;
  const ApiUrl = RestEndPoints.MARGIN_CALCULATOR;

  const marginCalculatorConfigs = await parseMarginCalculator(
    sanitizePlaceOrdersBeforeMarginCalculation(orderConfigs)
  );

  const bodyDetails = {
    data: marginCalculatorConfigs,
  };

  const parameters = {
    method: 'POST',
    headers: {
      Authorization: `${FYERS_APP_ID}:${brokerClientAccessToken}`,
    },
    body: JSON.stringify(bodyDetails),
  };

  const { totalMarginRequired = 0, totalMarginAvailable = 0 } =  await wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ data }) => {
      return {
        totalMarginRequired: Number(_.round(data?.margin_total, 2)) || 0,
        totalMarginAvailable: Number(_.round(data?.margin_avail, 2)) || 0
      };
    })
    .catch(() => 0);

  return { totalMarginRequired, totalMarginAvailable  };
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  isValidAccessToken,
  placeOrder,
  cancelPlaceOrder,
  fetchMarginCalculator,
  fetchAccessToken,
  fetchProfile,
  parsePostbackOrder,
};
