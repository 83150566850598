export const parsePlaceOrderTradingSymbol = (tradingSymbolObj) => {
  let formattedTradingSymbol;
  if (tradingSymbolObj.isEquity()) {
    formattedTradingSymbol = `${tradingSymbolObj.toString()}-EQ`;
  } else if (tradingSymbolObj.isFuture()) {
    formattedTradingSymbol = `${tradingSymbolObj.toString()}`;
  } else {
    formattedTradingSymbol = `${tradingSymbolObj.toString()}`;
  }
  return formattedTradingSymbol;
};
