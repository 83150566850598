
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux_store/store';

import App from 'pages/App';

document.addEventListener('DOMContentLoaded', () => {
  const div = document.createElement('div');
  div.className = 'master-app-container';
  document.body.appendChild(div);
  const root = createRoot(div);

  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  );
});
