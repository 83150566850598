import { BSE_INDEXES, segmentConfigs } from 'habitual-analytics/constants/habitual-configs';
import {
  EXPIRY_DATE_FORMAT,
  DATE_FORMAT,
  WATCHLIST_DISPLAY_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import _ from 'lodash';
import moment from 'moment';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

export default class Instrument {
  constructor({
    symbol, expiryDate, strikePrice, optionType
  }) {
    this.symbol = _.toUpper(symbol);
    this.expiryDate = expiryDate;
    this.strikePrice = strikePrice ? parseFloat(strikePrice) : undefined;
    this.optionType = optionType ? _.toUpper(optionType) : undefined;
  }

  isEquity = () => {
    return !this.expiryDate;
  };

  isOption = () => {
    return this.expiryDate && this.strikePrice && this.optionType && this.symbol ? true : false;
  };

  getExchange = () => {
    return _.includes(BSE_INDEXES, this.symbol) ? 'BSE' : 'NSE';
  };

  getExchangeSegment = () => {
    if (this.isEquity()) {
      return this.getExchange();
    }
    return this.getExchange() === 'NSE' ? 'NFO' : 'BFO';
  };

  getSymbol = () => {
    return this.symbol;
  };

  isFuture = () => {
    return this.expiryDate && !this.strikePrice && !this.optionType;
  };

  isIndex = _.memoize(() => {
    return _.includes(MarketUtility.getIndexSymbols(), this.symbol);
  });

  isTradeable = _.memoize(() => {
    const nonTradable = this.isIndex() && this.isEquity();
    return !nonTradable;
  });

  segment = _.memoize(() => {
    if (this.isEquity()) {
      return segmentConfigs.equity.value;
    }
    if (this.isOption()) {
      return segmentConfigs.options.value;
    }

    return segmentConfigs.futures.value;
  });

  expiryDateMmt = _.memoize(() => {
    return moment(this.expiryDate, DATE_FORMAT);
  });

  toString = () => {
    const formattedExpiryDate = this.expiryDate
      ? this.expiryDateMmt().format(EXPIRY_DATE_FORMAT)
      : '';

    return [
      this.symbol,
      formattedExpiryDate,
      this.strikePrice,
      this.isFuture() ? 'FUT' : this.optionType,
    ]
      .join('')
      .toUpperCase();
  };

  getSymbolObject = _.memoize(() => {
    return {
      expiryDate: this.expiryDate || '',
      symbol: this.symbol,
      optionType: this.isFuture() ? 'FUT' : this.optionType,
      strikePrice: this.strikePrice,
    };
  });

  getSymbolDisplayObject = () => {
    const formattedExpiryDate = this.expiryDate
      ? this.expiryDateMmt().format(EXPIRY_DATE_FORMAT)
      : '';
    return {
      expiryDate: formattedExpiryDate || '',
      symbol: this.symbol,
      optionType: this.isFuture() ? 'FUT' : this.optionType,
      strikePrice: this.strikePrice,
      exchange: this.getExchange(),
    };
  };

  getAPIinstrumentFormat = () => {
    return [
      this.symbol,
      this.isFuture() ? '' : this.expiryDate || '',
      this.strikePrice,
      this.isFuture() ? '-I' : this.optionType,
    ]
      .join('')
      .toUpperCase();
  };

  getDisplayFormat = _.memoize(() => {
    const formattedExpiryDate = this.expiryDate
      ? this.expiryDateMmt().format(EXPIRY_DATE_FORMAT).toUpperCase()
      : '';

    return [
      this.symbol,
      formattedExpiryDate,
      this.strikePrice,
      this.isFuture() ? 'FUT' : this.optionType,
    ]
      .join(' ')
      .toUpperCase();
  });

  getInstrumentType = () => {

    return this.isEquity() ? 'EQ' : this.isFuture() ? 'FUT' : 'OPT';
  };

  getStrikePriceDisplayFormat = () => {
    return getFormattedMoney(this.strikePrice, false, false, 'INR', false);
  };

  getWatchListDisplayFormat = () => {
    const formattedExpiryDate = this.expiryDate
      ? this.expiryDateMmt().format(WATCHLIST_DISPLAY_FORMAT)
      : '';

    return [
      this.symbol,
      formattedExpiryDate,
      this.strikePrice,
      this.isFuture() ? 'FUT' : this.optionType,
    ]
      .join(' ')
      .toUpperCase();
  };
}
