import moment from 'moment';
import _ from 'lodash';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from '../../dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { getFormattedMoney } from '../../utils/money';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  parseExchangeTokenTradingSymbol,
  isValidSymbolForInsta,
} from '../utils';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import Instrument from 'habitual-analytics/models/instrument/index';
import { parseOrderDetailToGetTradingSymbolObj } from './workmate';
import { getDefaultProductCode } from '../tradingSymbolParser';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

const parseProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'l':
      type = 'RL';
      break;
    case 'mkt':
      type = 'RL-MKT';
      break;
    case 'sl-m':
      type = 'SL-MKT';
      break;
    case 'sl':
      type = 'SL';
      break;
    default:
      type = '';
  }
  return type;
};

const parseTraceId = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const formattedData = moment().format('mmYYYYHHMMss');
  const currentDay = moment().format('dd').slice(0, 2);
  return `${brokerClientId}_${currentDay}${formattedData}`;
};

const getDefaultProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'rl':
      type = 'L';
      break;
    case 'rl-mkt':
      type = 'MKT';
      break;
    case 'sl-mkt':
      type = 'SL-M';
      break;
    case 'sl':
      type = 'SL';
      break;
    default:
      type = '';
  }
  return type;
};

const getMarginProductCode = (productCode) => {
  let pCode;
  switch (_.toLower(productCode)) {
    case 'mis':
      pCode = 'M';
      break;
    case 'cnc':
      pCode = 'D';
      break;
    default:
      pCode = 'M';
  }
  return pCode;
};

const parseProductCode = (pcode) => {
  let productCode;
  switch (_.toLower(pcode)) {
    case 'cnc':
    case 'nrml':
      productCode = 'DELIVERY';
      break;
    case 'mis':
      productCode = 'INTRADAY';
      break;
    default:
      productCode = '';
  }
  return productCode;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.status)) {
    case 'cancelled':
      status = statusConfigs.cancelled.value;
      break;
    case 'executed':
      status = statusConfigs.executed.value;
      break;
    case 'adminreject':
    case 'rejected':
    case 'ordererror':
    case 'gatewayreject':
      status = statusConfigs.failed.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.fillshares
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseOrderBook = (orderDetail) => {
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
  const exchange = _.get(orderDetail, 'exchange', '');

  if (
    !isValidSymbolForInsta(exchange, tradingSymbolObj.toString()) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const productType = _.get(orderDetail, 'order_type', '');
  const productCode = _.get(orderDetail, 'product_type', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'trigger_price', 0));
  const price = convertToNumber(_.get(orderDetail, 'order_price', 0));
  const tradedQty = _.get(orderDetail, 'traded_quantity', 0);
  const quantity = Number(_.get(orderDetail, 'total_quantity', 0));
  const avgPrice = convertToNumber(_.get(orderDetail, 'order_price', 0));
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice
        : price;
  const tradedTime = _.get(orderDetail, 'order_timestamp', '').split(' ')?.[1];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'error_reason', '');

  return {
    tradingSymbolObj: tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'transaction_type', '') === 'SELL'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'order_id', ''),
      defaultProductCode: getDefaultProductCode(productCode, tradingSymbolObj),
      defaultProductType: getDefaultProductType(productType),
      orderDetail,
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
  const exchange = _.get(orderDetail, 'exchange', '');

  if (
    !isValidSymbolForInsta(exchange, tradingSymbolObj.toString()) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const productType = _.get(orderDetail, 'order_type', '');
  const productCode = _.get(orderDetail, 'product_type', '');
  const tradedQuantity = Number(_.get(orderDetail, 'trade_quantity', 0));
  const quantity = Number(_.get(orderDetail, 'trade_quantity', 0));
  const status = statusConfigs.executed.label;

  return {
    tradingSymbolObj,
    time: moment(
      _.get(orderDetail, 'trade_timestamp', '')?.split(' ')?.[1],
      TIME_FORMAT
    ).format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'transaction_type', '') === 'SELL'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'trade_price', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
  const exchange = _.get(orderDetail, 'preferred_exchange', '');
  const dailyOrExpiry = _.get(orderDetail, 'daily_or_expiry', '');

  if (dailyOrExpiry === 'DAILY') {
    return null;
  }

  if (
    !isValidSymbolForInsta(exchange, tradingSymbolObj.toString()) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const ltp = _.round(convertToNumber(_.get(orderDetail, 'ltp')), 2);
  const qty = Number(_.get(orderDetail, 'net_quantity', 0));
  const netAvgPrice = convertToNumber(_.get(orderDetail, 'net_price', 0));
  const buyAvg = convertToNumber(_.get(orderDetail, 'avg_buy_price', 0));
  const sellAvg = convertToNumber(_.get(orderDetail, 'avg_sell_price', 0));
  const realisedprofitloss = _.round(_.get(orderDetail, 'net_value', 0), 2);
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const currentProfitLoss =
    type === transactionTypes.buy.value ? ltp - netAvgPrice : netAvgPrice - ltp;
  const profitLoss =
    qty === 0
      ? realisedprofitloss
      : _.round(currentProfitLoss, 2) * Math.abs(qty);

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'product_type', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'product_type', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const tradingSymbol = _.get(orderDetail, 'security_info[0][symbol]', '');
  const quantity = _.get(orderDetail, 'total_free', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'average_price', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'last_price', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);
  const tradingSymbolObj = new Instrument({ symbol: tradingSymbol });
  const exchange = _.get(orderDetail, 'security_info[0][exchange]', '');

  if (
    !isValidSymbolForInsta(exchange, tradingSymbolObj.toString()) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'last_price',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = async (orderConfig) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(
    formattedExchangeTokenTradingSymbol,
    tradingSymbolObj?.getExchange()
  );
  const exchangeSegement = tradingSymbolObj?.isEquity() ? 'NSE_EQ' : 'NSE_FO';

  return {
    scrip_info: {
      exchange: exchangeSegement,
      scrip_token: symbolId,
      symbol: tradingSymbolObj.symbol,
      series: null,
      expiry_date: tradingSymbolObj.expiryDate,
      strike_price: tradingSymbolObj.strikePrice,
      option_type: tradingSymbolObj.optionType,
    },
    transaction_type: _.get(orderConfig, 'transactionType', '').toUpperCase(),
    product_type: parseProductCode(_.get(orderConfig, 'pCode', '')),
    order_type: parseProductType(_.get(orderConfig, 'prctyp', '')),
    quantity: _.get(orderConfig, 'qty', 0),
    price: _.get(orderConfig, 'price', 0),
    trigger_price: _.get(orderConfig, 'trigPrice', 0),
    disclosed_quantity: _.get(orderConfig, 'disclosedQty', 0),
    validity: _.get(orderConfig, 'ret', ''),
    is_amo: false,
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

const parseModifyOrder = (orderConfig) => {
  const prodcutType = parseProductType(_.get(orderConfig, 'prctyp', ''));

  return {
    order_type: prodcutType,
    quantity: _.get(orderConfig, 'qty', 0),
    price: _.get(orderConfig, 'price', 0),
    traded_quantity: _.get(
      orderConfig,
      'extraDetails.orderDetail.traded_quantity',
      0
    ),
    trigger_price: _.get(orderConfig, 'trigPrice', 0),
    disclosed_quantity: _.get(orderConfig, 'disclosedQty', 0),
    validity: _.get(orderConfig, 'ret', ''),
    validity_days: _.get(orderConfig, 'ret', '') === 'DAY' ? 0 : 365,
  };
};

const parseMarginCalculator = async (orderConfigs) => {
  const FETraceId = parseTraceId();
  const formattedMarginCalculatorConfigs = await Promise.all(
    _.map(orderConfigs, async (orderConfig, idx) => {
      const { tradingSymbolObj } = orderConfig;
      const formattedExchangeTokenTradingSymbol =
        parseExchangeTokenTradingSymbol(tradingSymbolObj);
      const symbolId = await getExchangeToken(
        formattedExchangeTokenTradingSymbol,
        tradingSymbolObj?.getExchange()
      );
      const exchangeSegement =
        tradingSymbolObj?.isEquity() &&
        tradingSymbolObj?.getExchange() === 'NSE'
          ? 'NSE_EQ'
          : tradingSymbolObj?.getExchange() === 'BSE'
            ? 'BSE_FO'
            : 'NSE_FO';
      const productType = _.toLower(_.get(orderConfig, 'prctyp', ''));
      const productCode = _.toLower(_.get(orderConfig, 'pCode', ''));

      return {
        LegNo: idx + 1,
        BuyOrSell: _.get(orderConfig, 'transactionType', '') === 'buy' ? 1 : 2,
        MarketSegment: exchangeSegement,
        Token: symbolId,
        Quantity: _.parseInt(_.get(orderConfig, 'qty', '')),
        Price: _.multiply(_.get(orderConfig, 'price', ''), 100),
        MktFlag: _.includes(['mkt', 'sl-m'], productType) ? 1 : 0,
        ProductType: getMarginProductCode(productCode),
        LegIndicator: 0,
      };
    })
  );
  return {
    NoOfLegs: _.size(formattedMarginCalculatorConfigs),
    Mode: 'N',
    FETraceId,
    LegDetails: formattedMarginCalculatorConfigs,
  };
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
  parseModifyOrder,
  parseMarginCalculator,
};
