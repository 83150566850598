const URI = 'https://api.gwcindia.in/v1';

const RestEndPoints = {
  GET_HOLDINGS: `${URI}/holdings`,
  UPDATE_POSITION_BOOK: `${URI}/positions`,
  GET_TRADE_BOOK: `${URI}/tradebook`,
  GET_ORDER_BOOK: `${URI}/orderbook`,
  PLACE_ORDER: `${URI}/placeorder`,
  PROFILE_PATH: `${URI}/profile`,
  CANCEL_PLACE_ORDER: `${URI}/cancelorder`,
};

export default RestEndPoints;
