import moment from 'moment';
import _ from 'lodash';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

import {
  convertToNumber,
  getCancellableOrderStatus,
  getTradingSymbolObj,
  isValidSymbolForInsta,
  isOrderPartiallyExecuted,
} from '../utils';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
  getPlaceOrderTradingSymbol,
} from '../tradingSymbolParser';

const parseProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'l':
      type = 'LMT';
      break;
    case 'mkt':
      type = 'MKT';
      break;
    case 'sl-m':
      type = 'SL-MKT';
      break;
    case 'sl':
      type = 'SL-LMT';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductCode = (pcode) => {
  let productCode;
  switch (_.toLower(pcode)) {
    case 'cnc':
      productCode = 'C';
      break;
    case 'mis':
      productCode = 'I';
      break;
    case 'nrml':
      productCode = 'M';
      break;
    default:
      productCode = '';
  }
  return productCode;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.order_status)) {
    case 'canceled':
      status = statusConfigs.cancelled.value;
      break;
    case 'complete':
      status = statusConfigs.executed.value;
      break;
    case 'rejected':
      status = statusConfigs.failed.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.fillshares
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseOrderBook = (orderDetail) => {
  if (!_.isObject(orderDetail)) {
    return null;
  }
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'trading_symbol', '');
  const productType = _.get(orderDetail, 'price_type', '');
  const productCode = _.get(orderDetail, 'product_display_name', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'trigger_price', 0));
  const price = convertToNumber(_.get(orderDetail, 'price', 0));
  const tradedQty = _.get(orderDetail, 'retention_quantity', 0);
  const quantity = Number(_.get(orderDetail, 'quantity', 0));
  const avgPrice = convertToNumber(_.get(orderDetail, 'average_price', 0));
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice
        : price;
  const tradedTime = _.get(orderDetail, 'order_execution_date_time', '').split(
    ' '
  )?.[0];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'rejection_reason', '');
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'transaction_type', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'oms_partner_order_no', ''),
    },
  };
};

const parseTradeBook = (orderDetail) => {
  if (!_.isObject(orderDetail)) {
    return null;
  }
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'trading_symbol', '');
  const productType = _.get(orderDetail, 'price_type', '');
  const productCode = _.get(orderDetail, 'product_display_name', '');
  const tradedQuantity = Number(_.get(orderDetail, 'fill_shares', 0));
  const quantity = Number(_.get(orderDetail, 'fill_quantity', 0));
  const status = statusConfigs.executed.label;
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    time: moment(
      _.get(orderDetail, 'fill_time', '')?.split(' ')?.[1],
      TIME_FORMAT
    ).format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'transaction_type', '') === 'S'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'fill_price', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  if (!_.isObject(orderDetail)) {
    return null;
  }
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'trading_symbol', '');
  const ltp = convertToNumber(_.get(orderDetail, 'ltp'));
  const qty = Number(_.get(orderDetail, 'net_quantity', 0));
  const netAvgPrice = convertToNumber(
    _.get(orderDetail, 'net_average_price', 0)
  );
  const buyAvg = _.get(orderDetail, 'day_buy_average_price', 0);
  const sellAvg = _.get(orderDetail, 'day_sell_average_price', 0);
  const realisedprofitloss = _.round(_.get(orderDetail, 'realized_pnl', 0), 2);
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const currentProfitLoss =
    type === transactionTypes.buy.value ? ltp - netAvgPrice : netAvgPrice - ltp;
  const profitLoss =
    qty === 0
      ? realisedprofitloss
      : _.round(currentProfitLoss, 2) * Math.abs(qty) + realisedprofitloss;
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'product_display_name', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'product_display_name', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  if (!_.isObject(orderDetail)) {
    return null;
  }
  const exchange = _.get(
    orderDetail,
    'exchange_trading_symbol[0][exchange]',
    ''
  );

  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(
    orderDetail,
    'exchange_trading_symbol[0][trading_symbol]',
    ''
  );
  const quantity = _.get(orderDetail, 'non_poa_quantity', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'uploaded_price', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'LTnse', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);
  const { tradingSymbolObj } = getTradingSymbolObj(tradingSymbol);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'LTnse',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);
  const priceType = _.get(orderConfig, 'prctyp', '');
  const isMarketOrSlMarketOrder = priceType === 'MKT' || priceType === 'SL-M';

  return {
    user_id: brokerClientId,
    trading_symbol: formattedTradingSymbol,
    exchange: tradingSymbolObj?.isEquity() ? 'NSE' : 'NFO',
    // for market order price should be 1
    price: _.toString(isMarketOrSlMarketOrder ? 1 : orderConfig.price),
    transaction_type: _.first(_.upperCase(orderConfig.transactionType)),
    price_type: parseProductType(priceType),
    quantity: _.toString(orderConfig.qty),
    disclosed_quantity: _.toString(orderConfig.disCloseQty),
    product_type: parseProductCode(orderConfig.pCode),
    trigger_price: _.toString(orderConfig.trigPrice),
    instrument_name: tradingSymbolObj?.symbol,
    retention_type: _.toString(orderConfig.ret),
    after_market_order: 'No',
    remarks: 'INSTAOPTIONS',
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
};
