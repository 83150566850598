import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from 'habitual-analytics/api/resturls';
import moment from 'moment';
import { getMarketTimingConfigs } from 'habitual-analytics/dateUtils/index';
import {
  DATE_FORMAT,
  EXPIRY_DATE_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import { DISPLAY_TRADING_SYMBOL_TO_OBJECT_REGEX } from 'habitual-analytics/models/instrument/factory';
import _ from 'lodash';
import getHistoricFormattedData from './formatter';

const HistoricDataAdaptor = (() => {
  const getParsedParameters = (datetimes, instruments) => {
    return {
      method: 'POST',
      body: JSON.stringify({
        instruments: _.join(instruments, ','),
        datetimes: `${datetimes}`,
        candleInterval: '1m',
      }),
    };
  };

  const formatTime = (time) => {
    return time.split('T').join(' ');
  };

  const fetchData = (parameters) => {
    const { TICKS_POINT_IN_TIME } = REST_URLS();

    return wrappedFetch(TICKS_POINT_IN_TIME, parameters).then((response) =>
      response.json().then(({ data }) => data)
    );
  };

  const getApiFormatInstrument = (instrument) => {
    const matches = instrument.match(DISPLAY_TRADING_SYMBOL_TO_OBJECT_REGEX);
    const [, symbol, expiryDate, strikePrice, optionType] = matches || [];
    const formattedExpiryDate = moment(expiryDate, EXPIRY_DATE_FORMAT).format(
      DATE_FORMAT
    );

    if (optionType === 'FUT') return `FUT|${symbol}|I`;
    else if (strikePrice)
      return `OPT|${symbol}|${formattedExpiryDate}|${strikePrice}|${optionType}`;
    else return `EQ|${instrument}`;
  };

  const getApiFormatInstruments = (instruments) => {
    // Split instruments and map each one to its components
    const parsedInstruments = _.chain(instruments)
      .split(',')
      .map(getApiFormatInstrument)
      .map((instrument) => {
        const [
          type, 
          symbol, 
          expiryDate = '', 
          strike = '', 
          optionType = ''
        ] = instrument.split('|');
        return { type, symbol, expiryDate, strike, optionType };
      })
      .value();

    // Group instruments by type, symbol, expiryDate, and optionType
    const groupedInstruments = _.groupBy(
      parsedInstruments,
      ({ type, symbol, expiryDate, optionType }) =>
        `${type}|${symbol}|${expiryDate}|${optionType}`
    );

    // Format the instruments, combining strike prices
    const formattedInstruments = _.map(groupedInstruments, (group) => {
      const { type, symbol, expiryDate, optionType } = group[0];
      const strikePrices = _.uniq(
        group.map((instrument) => instrument.strike)
      ).join('_');

      // Build the final instrument string
      const instrument = [type, symbol, expiryDate, strikePrices]
        .filter(Boolean)
        .join('|');

      return { instrument, optionType };
    });

    // Group by the instrument string and combine optionTypes if needed
    return _.chain(formattedInstruments)
      .groupBy('instrument')
      .map((group, instrumentKey) => {
        const optionTypes = _.uniq(group.map((inst) => inst.optionType)).join(
          '_'
        );
        return optionTypes ? `${instrumentKey}|${optionTypes}` : instrumentKey;
      })
      .value();
  };

  return {
    getData: async (instruments) => {
      const formattedInstruments = getApiFormatInstruments(instruments);
      const {
        marketStartTime,
        firstOiStartTime,
        marketCurrentTime,
        marketPrevDayEndTime,
      } = getMarketTimingConfigs();

      return fetchData(
        getParsedParameters(
          [
            marketStartTime,
            firstOiStartTime,
            marketCurrentTime,
            marketPrevDayEndTime,
          ],
          formattedInstruments
        )
      ).then((responseData) => {
        return getHistoricFormattedData(responseData, {
          marketStartTime: formatTime(marketStartTime),
          firstOiStartTime: formatTime(firstOiStartTime),
          marketCurrentTime: formatTime(marketCurrentTime),
          marketPrevDayEndTime: formatTime(marketPrevDayEndTime),
        });
      });
    },
    getApiFormatInstruments,
  };
})();

export default HistoricDataAdaptor;
