import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import _ from 'lodash';
import RestEndPoints from './RestEndPoints';
import { parseOrderDetails, parsePlaceOrder } from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';

const getHeaders = () => {
  const { brokerConfigs, envs } = getDynamicAppConfigs();
  const { brokerClientAccessToken } = brokerConfigs;
  const { ANGEL_BROKING_API_KEY } = envs;

  return {
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    Authorization: `Bearer ${brokerClientAccessToken}`,
    'X-PrivateKey': ANGEL_BROKING_API_KEY,
  };
};

export const REQUEST_PHASE_URL = () => {
  const apiKey = getDynamicAppConfigs().envs.ANGEL_BROKING_API_KEY;

  return `${RestEndPoints.REQUEST_PHASE_URI}?api_key=${apiKey}`;
};

const isValidAccessToken = () => {
  const parameters = { headers: getHeaders() };

  const uri = RestEndPoints.GET_USER_PROFILE;
  return wrappedFetch(uri, parameters)
    .then((res) => res.json())
    .then((res) => res.status)
    .catch(() => false);
};

const fetchProfile = () => {
  const parameters = { headers: getHeaders() };

  const uri = RestEndPoints.GET_USER_PROFILE;
  return wrappedFetch(uri, parameters).then((res) => {
    return res.json();
  });
};

const fetchOrderBook = () => {
  const ApiUrl = RestEndPoints.GET_ORDER_BOOK;
  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ data }) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(parseOrderDetails(data, 'order'), ['time'], ['desc'])
        ),
        headers: orderTableHeaders,
      };
    })
    .catch((e) => e);
};

const fetchTradeBook = () => {
  const ApiUrl = RestEndPoints.GET_TRADE_BOOK;
  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ data }) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(parseOrderDetails(data, 'trade'), ['time'], ['desc'])
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const ApiUrl = RestEndPoints.GET_POSITION_BOOK;

  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ data }) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(parseOrderDetails(data, 'position'), ['extraDetails.isOpenPosition'], ['desc'])
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const ApiUrl = RestEndPoints.GET_HOLDINGS;
  const parameters = { headers: getHeaders() };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ data }) => {
      return {
        holdingBookDetails: _.compact(parseOrderDetails(data, 'holdings')),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const ApiUrl = RestEndPoints.PLACE_ORDER;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const bodyDetails = await parsePlaceOrder(orderConfig);

      const parameters = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(bodyDetails)
      };

      return wrappedFetch(ApiUrl, parameters)
        .then((response) => response.json())
        .then((result) => result)
        .catch((e) => [e]);
    })
  );

  return _.map(formattedConfig, ({ data }) => data?.orderid);
};

const cancelPlaceOrder = ({ orderNo, orderType }) => {
  const ApiUrl = RestEndPoints.CANCEL_PLACE_ORDER;
  const formattedOrderType = _.split(orderType, ' ')?.[1];

  const bodyDetails = {
    orderid: orderNo,
    variety: _.includes(['MARKET', 'LIMIT'], formattedOrderType) ? 'NORMAL' : 'STOPLOSS',
  };

  const parameters = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(bodyDetails),
  };

  return wrappedFetch(ApiUrl, parameters).then((response) => response.json());
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  isValidAccessToken,
  placeOrder,
  cancelPlaceOrder,
  fetchProfile,
};
