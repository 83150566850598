import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import * as Sentry from '@sentry/react';

export const initializeSentry = () => {
  const sentryDsn = getDynamicAppConfigs()?.envs?.SENTRY_DSN;
  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
      integrations: [new Sentry.BrowserTracing()],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      // Called for transaction events
      ignoreErrors: [
        // Add the error message or part of it related to chunk loading error
        'ChunkLoadError: Loading chunk',
        'Unhandled Promise Rejection: Failed to fetch',
        'Unhandled Promise Rejection: Load failed',
        'Cannot read properties of null (reading \'template\')',
        'Non-Error promise rejection captured',
        'Failed to execute \'insertBefore\' on \'Node\'',
        'NotAllowedError'
        // Add more patterns if necessary
      ],
      beforeSend(event) {
        // Check if the event is related to the specified path or condition
        if (
          event.exception
          && event.exception.values[0]?.stacktrace?.frames.some(
            (frame) => frame.filename && frame.filename.includes('charting_library/bundles/library')
          )
        ) {
          // Don't send the event to Sentry
          return null;
        }

        // You can add more conditions here if needed

        return event;
      },
      tracesSampleRate: 1.0,
    });
  }
};
