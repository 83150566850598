import React from 'react';
import ErrorHandler from './ErrorHandler';

const propTypes = {};

const defaultProps = {};

export default function withErrorBoundary(WrappedComponent) {
  return function fromWithErrorBoundary(props) {
    return (
      <ErrorHandler>
        <WrappedComponent {...props} />
      </ErrorHandler>
    );
  };
}

withErrorBoundary.propTypes = propTypes;
withErrorBoundary.defaultProps = defaultProps;
