import _ from 'lodash';
import RestEndPoints from './RestEndPoints';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { parseOrderDetails, parsePlaceOrder } from './parse';
import { orderTableHeaders } from './tableHeaders';

const userId = '524419';

const requestHeaders = {
  User: `${userId}`,
};

const fetchOrderBook = () => {
  const ApiUrl = RestEndPoints.ORDERS;

  const parameters = {
    headers: requestHeaders,
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ orderBook }) => {
      return {
        orderBookDetails: _.orderBy(parseOrderDetails(orderBook, 'order'), ['time'], ['desc']),
        headers: orderTableHeaders,
      };
    });
};

const fetchTradeBook = () => {
  return { data: [], headers: [] };
};

const fetchPositionBook = () => {
  return { data: [], headers: [] };
};

const fetchHoldingBook = () => {
  return { data: [], headers: [] };
};

const placeOrder = async (orderConfigs) => {
  _.map(orderConfigs, async (order) => {
    const bodyDetails = await parsePlaceOrder(order);

    const ApiUrl = encodeURI(`${RestEndPoints.PLACE_ORDER}`);

    const parameters = {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(bodyDetails)
    };

    return wrappedFetch(ApiUrl, parameters).then((response) => response.json());
  });
};

export {
  fetchOrderBook, fetchTradeBook, fetchPositionBook, fetchHoldingBook, placeOrder
};
