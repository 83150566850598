import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from '../resturls';

export const getExchangeToken = (tradingSymbol, exchangeType = 'NSE') => {
  const { GET_EXCHANGE_TOKEN } = REST_URLS();
  const queryParams = encodeURIComponent(tradingSymbol);
  const uri = encodeURI(`${GET_EXCHANGE_TOKEN}?tradingSymbol=${queryParams}&exchangeType=${exchangeType}`);
  return wrappedFetch(uri).then((response) => response.json());
};

export const getExchangeTokens = (tradingSymbols) => {
  const { GET_EXCHANGE_TOKENS } = REST_URLS();
  const queryParams = tradingSymbols.map((symbol) => encodeURIComponent(symbol)).join(',');
  const uri = encodeURI(`${GET_EXCHANGE_TOKENS}?tradingSymbols=${queryParams}`);

  const parameters = {
    method: 'GET',
    credentials: 'same-origin',
  };

  return wrappedFetch(uri, parameters).then((response) => response.json());
};

export const getAxisDirectScriptIds = (tradingSymbols) => {
  const { GET_AXIS_DIRECT_SCRIPT_IDS } = REST_URLS();
  const queryParams = tradingSymbols.map((symbol) => encodeURIComponent(symbol)).join(',');
  const uri = encodeURI(`${GET_AXIS_DIRECT_SCRIPT_IDS}?tradingSymbols=${queryParams}`);

  const parameters = {
    method: 'GET',
    credentials: 'same-origin',
  };

  return wrappedFetch(uri, parameters).then((response) => response.json());
};