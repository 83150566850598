import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import RestEndPoints from './RestEndPoints';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import {
  parseHoldingsBook,
  parseOrderDetails,
  parsePlaceOrder,
} from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';

export const REQUEST_PHASE_URL = () => {
  // todo for mobile app
  return '';
};

const fetchAccessToken = () => {
  // todo for mobile app
  return {};
};

const isValidAccessToken = () => {
  const { brokerClientId, brokerClientAccessToken, brokerExtras } =
    getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'GET',
    headers: {
      Authorization: brokerClientAccessToken,
    },
  };

  const uri = encodeURI(
    `${brokerExtras.api_base_uri}${RestEndPoints.PROFILE_PATH}?clientID=${brokerClientId}`
  );
  return wrappedFetch(uri, parameters)
    .then((res) => res.ok)
    .catch((error) => {
      console.log('error', error);
      return false;
    });
};

const fetchOrderBook = async () => {
  const { brokerClientAccessToken, brokerExtras } =
    getDynamicAppConfigs().brokerConfigs;
  const ApiUrl = `${brokerExtras.api_base_uri}${RestEndPoints.ORDERS_PATH}`;

  const parameters = {
    method: 'GET',
    headers: {
      Authorization: brokerClientAccessToken,
    },
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ result }) => ({
      orderBookDetails: _.orderBy(
        parseOrderDetails(result, 'order'),
        ['time'],
        ['desc']
      ),
      headers: orderTableHeaders,
    }));
};

const fetchTradeBook = () => {
  const { brokerClientId, brokerClientAccessToken, brokerExtras } =
    getDynamicAppConfigs().brokerConfigs;
  const ApiUrl = encodeURI(
    `${brokerExtras.api_base_uri}${RestEndPoints.TRADE_BOOK_PATH}?clientID=${brokerClientId}`
  );

  const parameters = {
    method: 'GET',
    headers: {
      Authorization: brokerClientAccessToken,
    },
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ result }) => {
      return {
        tradeBookDetails: _.orderBy(
          parseOrderDetails(result, 'trade'),
          ['time'],
          ['desc']
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const { brokerClientId, brokerClientAccessToken, brokerExtras } =
    getDynamicAppConfigs().brokerConfigs;
  const ApiUrl = encodeURI(
    `${brokerExtras.api_base_uri}${RestEndPoints.POSITION_BOOK_PATH}?clientID=${
      brokerClientId
    }&dayOrNet=DayWise`
  );
    

  const parameters = {
    method: 'GET',
    headers: {
      Authorization: brokerClientAccessToken,
    },
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ result }) => {
      return {
        positionBookDetails: _.orderBy(
          parseOrderDetails(_.get(result, 'positionList', []), 'position'),
          ['extraDetails.isOpenPosition'],
          ['desc']
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = async () => {
  const { brokerClientId, brokerClientAccessToken, brokerExtras } =
    getDynamicAppConfigs().brokerConfigs;
  const ApiUrl = encodeURI(
    `${brokerExtras.api_base_uri}${RestEndPoints.HOLDINGS_PATH}?clientID=${brokerClientId}`
  );

  const parameters = {
    method: 'GET',
    headers: {
      Authorization: brokerClientAccessToken,
    },
  };

  const holdings = await wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => result);

  const holdingsBookDetails = await parseHoldingsBook(
    _.get(holdings, 'result.RMSHoldings', [])
  );

  return {
    holdingBookDetails: _.orderBy(
      holdingsBookDetails,
      ['extraDetails.isOpenPosition'],
      ['desc']
    ),
    headers: holdingTableHeaders,
  };
};

const placeOrder = async (placeOrderConfigs) => {
  const { brokerClientAccessToken, brokerExtras } =
    getDynamicAppConfigs().brokerConfigs;
  const ApiUrl = `${brokerExtras.api_base_uri}${RestEndPoints.ORDERS_PATH}`;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const bodyDetails = await parsePlaceOrder(orderConfig);

      const parameters = {
        method: 'POST',
        headers: {
          Authorization: brokerClientAccessToken,
        },
        body: JSON.stringify(bodyDetails),
      };

      return wrappedFetch(ApiUrl, parameters)
        .then((response) => response.json())
        .catch((e) => e);
    })
  );

  return _.chain(formattedConfig)
    .map(({ type, result }) => type === 'success' ? _.toInteger(_.get(result, 'AppOrderID', '')) : null)
    .value();
};

const cancelPlaceOrder = ({ orderNo }) => {
  const { brokerClientAccessToken, brokerExtras } =
    getDynamicAppConfigs().brokerConfigs;
  const ApiUrl = encodeURI(
    `${brokerExtras.api_base_uri}${RestEndPoints.ORDERS_PATH}?appOrderID=${orderNo}`
  );

  const parameters = {
    method: 'DELETE',
    headers: {
      Authorization: brokerClientAccessToken,
    },
  };

  return wrappedFetch(ApiUrl, parameters).then((response) => response.json());
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  fetchAccessToken,
  cancelPlaceOrder,
};
