import React, { useEffect, useState } from 'react';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';
import Image from 'globals/components/Image/index';
import { getParsedDateObj } from 'habitual-analytics/utils/timezone';
import { DATE_FORMAT } from 'habitual-analytics/dateUtils/dateFormats';

const TelegramPopUp = () => {
  const [showModal, setShowModal] = useState(false);
  const today = getCurrentDate().toISOString().slice(0, 10);

  useEffect(() => {
    const lastModalDate = localStorage.getItem('telegramPopupLastModalDate');

    const storedDate = getParsedDateObj(lastModalDate);
    const lastWeek = getCurrentDate().subtract(7, 'days').format(DATE_FORMAT);

    if (_.isEmpty(lastModalDate) || storedDate < lastWeek) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem('telegramPopupLastModalDate', today);
    setShowModal(false);
  };

  return (
    showModal && (
      <div className="popup">
        <button
          type="button"
          className="btn btn-link close"
          onClick={handleCloseModal}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
        <div className="d-flex flex-column align-items-center gap-20">
          <Image
            src="./images/telegram.svg"
            alt="telegram"
            className="telegram-img mb-3"
          />
          <h5 className="text-uppercase fw-bold mb-3">Join us on telegram</h5>
          <a
            href="https://t.me/+i0XY7ZmHOGU2Yzg1"
            className="telegram-btn"
            target="blank"
            onClick={handleCloseModal}
          >
            Join Now
          </a>
        </div>
      </div>
    )
  );
};

export default TelegramPopUp;
