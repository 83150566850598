import _ from 'lodash';

export const getFormattedMoney = _.memoize(
  (
    money = 0,
    shouldAvoidDecimals = false,
    isAttachCurrency = false,
    currencyFormat = 'INR',
    isCompact = true,
    decimalPoints = 2
  ) => {
    const formattedMoney = Number(money);
    if (_.isNaN(formattedMoney) || !_.isFinite(formattedMoney)) {
      return 'N/A';
    }

    let formatedMoney = '';
    // const decimals = Number(money) - Math.floor(money);
    // minimumFractionDigits: !(shouldAvoidDecimals) && decimals ? decimalPoints : 0,
    // maximumFractionDigits: !shouldAvoidDecimals && decimals ? decimalPoints : 0,

    const formattedMoneyOptions = {
      minimumFractionDigits: decimalPoints,
      maximumFractionDigits: decimalPoints,
      ...(isCompact ? { notation: 'compact' } : {}),
      ...(isAttachCurrency
        ? { style: 'currency', currency: currencyFormat }
        : {}),
    };

    try {
      formatedMoney = new Intl.NumberFormat(
        'en-IN',
        formattedMoneyOptions
      )?.format(money);
      formatedMoney = _.replace(formatedMoney, 'T', 'K');
    } catch (error) {
      // case - If the Intl not load, in any browser or slow load.. catch will return the money
      // to avoid errors
      console.error(error);
      formatedMoney = money;
    }

    return formatedMoney;
  },
  (...args) => args.join(',')
);

// Reset cache every 30 minutes
const cacheResetInterval = 30 * 60 * 1000; // 30 minutes in milliseconds

setInterval(() => {
  getFormattedMoney.cache.clear();
}, cacheResetInterval);
