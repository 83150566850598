const BASE_URI = 'https://api.upstox.com/v2/';

const RestEndPoints = {
  BASE_URI,
  PROFILE_PATH: `${BASE_URI}user/profile`,
  GET_ORDER_BOOK: `${BASE_URI}order/retrieve-all`,
  GET_TRADE_BOOK: `${BASE_URI}order/trades/get-trades-for-day`,
  UPDATE_POSITION_BOOK: `${BASE_URI}portfolio/short-term-positions`,
  GET_HOLDINGS: `${BASE_URI}portfolio/long-term-holdings`,
  PLACE_ORDER: `${BASE_URI}uplink/order/place`,
  CANCEL_PLACE_ORDER: `${BASE_URI}uplink/order/cancel`,
  MODIFY_ORDER: `${BASE_URI}uplink/order/modify`,
};

export default RestEndPoints;
