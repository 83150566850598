const BASE_URI = 'https://web.gopocket.in';
const PROFILE_PATH = '/client-rest/api/profile/getclientdetails';

const RestEndPoints = {
  PROFILE_PATH: `${BASE_URI}${PROFILE_PATH}`,
  PLACE_ORDER: `${BASE_URI}/od-rest/orders/execute`,
  GET_ORDER_BOOK: `${BASE_URI}/od-rest/api/info/orderbook`,
  GET_POSITION_BOOK: `${BASE_URI}/po-rest/positions`,
  GET_TRADE_BOOK: `${BASE_URI}/od-rest/info/tradebook`,
  CANCEL_PLACE_ORDER: `${BASE_URI}/od-rest/orders/cancel`,
  MODIFY_ORDER: `${BASE_URI}/od-rest/orders/modify`,
  GET_HOLDINGS: `${BASE_URI}/ho-rest/holdings`,
  GET_MARGINS: `${BASE_URI}/bo-rest/basketorder/getmargin`,
  GET_MARGINS_AVAILABLE: `${BASE_URI}/od-rest/orders/getmargin`,
};

export default RestEndPoints;