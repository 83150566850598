const BASE_URI = 'https://v2api.tradesmartonline.in/NorenWClientTP';
const PROFILE_PATH = '/ClientDetails';

const RestEndPoints = {
  GET_HOLDINGS: `${BASE_URI}/Holdings`,
  UPDATE_POSITION_BOOK: `${BASE_URI}/PositionBook`,
  GET_TRADE_BOOK: `${BASE_URI}/TradeBook`,
  GET_ORDER_BOOK: `${BASE_URI}/OrderBook`,
  PLACE_ORDER: `${BASE_URI}/PlaceOrder`,
  BASE_URI,
  PROFILE_PATH: `${BASE_URI}${PROFILE_PATH}`,
  CANCEL_PLACE_ORDER: `${BASE_URI}/CancelOrder`,
  SEND_OTP: `${BASE_URI}/FgtPwdOTP`,
  MODIFY_ORDER: `${BASE_URI}/ModifyOrder`,
  MARGIN_CALCULATION: `${BASE_URI}/GetBasketMargin`,
  AVAILABLE_MARGIN: `${BASE_URI}/GetOrderMargin`,
};

export default RestEndPoints;
