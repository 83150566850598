import React, { Suspense } from 'react';

const withSuspense = (WrapperComponent, FallbackComponent) => {
  const RenderComponent = (props) => {

    return (
      <Suspense fallback={<div className='suspense__wrapper'></div>}>
        <WrapperComponent {...props} />
      </Suspense>
    );
  };

  return React.memo(RenderComponent);
};

export default withSuspense;