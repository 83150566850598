import { getDynamicAppConfigs } from '../../constants/dynamicAppConfigs';
import { createDecipheriv, createCipheriv, Buffer } from 'browser-crypto';
import randomBytes from 'randombytes';

export const encryptAes = (payload) => {
  const { brokerExtras } = getDynamicAppConfigs()?.brokerConfigs || {};
  const secretKey = brokerExtras?.secret_key;

  const nonce = randomBytes(16);
  const cipher = createCipheriv('aes-256-gcm', secretKey, nonce);
  let ciphertext = cipher.update(payload, 'utf8');
  ciphertext = Buffer.concat([ciphertext, cipher.final()]);
  const authTag = cipher.getAuthTag();

  const encodedNonce = nonce.toString('base64');
  const encodedTag = authTag.toString('base64');
  const encodedCiphertext = ciphertext.toString('base64');

  return `${encodedNonce}.${encodedTag}.${encodedCiphertext}`;
};


export const decryptAes = (encoded_payload) => {

  const { brokerExtras } = getDynamicAppConfigs()?.brokerConfigs;
  const secret_key = brokerExtras?.secret_key;

  let decrypted_data;
  const [encoded_nonce, encoded_tag, encoded_ciphertext] = encoded_payload?.split('.');
  const decipher = createDecipheriv(
    'aes-256-gcm',
    Buffer.from(secret_key, 'utf8'), Buffer.from(encoded_nonce, 'base64')
  );
  decipher.setAuthTag(Buffer.from(encoded_tag, 'base64'));
  try {
    const buffer = Buffer.from(encoded_ciphertext, 'base64');
    const decryptedDataPart = decipher.update(buffer, 'binary', 'utf8');
    const decryptedDataFinal = decipher.final('utf8');
    decrypted_data = decryptedDataPart + decryptedDataFinal;
  } catch (error) {
    console.error('Decryption failed:', error.message);
    return null;
  }
  return decrypted_data;
};