import {
  segmentConfigs,
  nomenClatures,
  predictionTypes,
  trendingOIComparisonTypes,
  placeOrderModes,
} from 'habitual-analytics/constants/habitual-configs';
import moment from 'moment';
import { defaultLegConfig } from 'habitual-analytics/utils/strategyConfigs';
import { defaultSettingPreferences } from 'habitual-analytics/constants/dynamicAppConfigs';
import { getMarketTimingConfigs } from 'habitual-analytics/dateUtils/index';
import { getAnalyticsUrlParams } from 'globals/utils/url';
import MarketUtility from 'habitual-analytics/utils/marketUtility';
import { minimizeOrderPlacerBrokers } from 'habitual-analytics/constants/habitual-configs';
import { InstaConstants } from 'habitual-analytics/constants/instaConstants';
import { getDefaultHistoricConfigs } from 'habitual-analytics/utils/getHistoricalConfigs';
import { getDeserializedLegs } from '../habitual-analytics/formProvider/BasketFormProvider/processor';

const { setting_preferences: sp, broker } = InstaConstants.get('userDetails');
const { marketCurrentTime, marketStartTime } = getMarketTimingConfigs();
const timeDifference = moment(marketCurrentTime).diff(
  moment(marketStartTime),
  'minutes'
);

const urlParams = getAnalyticsUrlParams();

const { expiryDate, instrument } = urlParams;

const defaultInstrument = instrument || 'BANKNIFTY';
const defaultExpiryType = MarketUtility.getValidExpiryDate({
  symbol: defaultInstrument,
  expiryDate: expiryDate,
});

const initialHeaderConfiguration = {
  instrument: defaultInstrument,
  segment: segmentConfigs.options.value,
  expiryDate: defaultExpiryType,
  strikePrices: [],
  strikePrice: '',
  trendingOiStrikePrices: [],
  currentDate: null,
  timeInterval: timeDifference > 5 ? 3 : 1,
  comparisonInterval: 0,
  trendingOIComparisonType: trendingOIComparisonTypes.previousDay.value,
};

const findStrategyConfig = {
  type: nomenClatures.positive.value,
  predictionBegin: 1,
  predictionEnd: 1,
  predictionType: predictionTypes.percentage.value,
};

const initialCommonConfigs = {
  combinedPremiumData: null,
  basketOpen: false,
  isBasketSlideOpen: false,
  marketActiveTab: 'ALL',
  OiSpurtActiveTab: 'longBuildUp',
  underlyingPrice: '',
  isNavigateToBasket: false,
  settingPreferenceChangeFromType:
    sp?.changeFromType ?? defaultSettingPreferences?.changeFromType,
  isCombinedPremiumChartActive: false,
  isNavigateToPayoutChart: false,
  isNavigateToBasketOrder: false,
  isNavigateToOptionChain: false,
  currentBrokerName: window?.instaConstants?.userDetails?.broker,
  isModifyOrder: false,
  noDataMessage: 'New Data will be updated at 9.18am',
  ...getDefaultHistoricConfigs(),
  indexContributorActiveInstrument: 'BANKNIFTY',
  historicUniqId: _.uniqueId('id-'),
  placeOrderMode: placeOrderModes.live,
};

const initialOrderPlacerConfig = {
  isOrderPlacerActive: false,
  orderPlacerData: null,
  handleAfterOrderPlacer: null,
  shouldMinimizeModal: _.includes(minimizeOrderPlacerBrokers, broker),
  basketName: null,
  basketId: null,
};

const initialBasketConfigs = {
  legs: getDeserializedLegs(
    _.get(urlParams, 'legs', [defaultLegConfig]),
    defaultInstrument
  ),
  currentStrategy: _.get(urlParams, 'currentStrategy', 'shortStrangle'),
};

const initialState = {
  basketConfigs: {
    ...initialBasketConfigs,
  },
  headerConfigs: {
    ...initialHeaderConfiguration,
  },
  commonConfigs: {
    ...initialCommonConfigs,
  },
  findStrategyConfig: {
    ...findStrategyConfig,
  },
  orderPlacerConfig: {
    ...initialOrderPlacerConfig,
  },
};

export default initialState;
