import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { wrappedFetch } from 'habitual-analytics/api/services/base/index';
import {
  parseOrderBook,
  parseOrderDetails,
  parsePositionBook,
  parseTradeBook,
  parserOrderDetails,
} from './parser';
import _ from 'lodash';
import {
  holdingTableHeaders,
  orderTableHeaders,
  postitionTableHeaders,
  tradeTableHeaders,
} from './tableHeaders';
import { REST_URLS } from 'habitual-analytics/api/resturls/index';

const getApplicationHeaders = (reqBody) => {
  const { FIVEPAISA_API_USER_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken, brokerClientId } =
    getDynamicAppConfigs().brokerConfigs;

  return {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${brokerClientAccessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      head: {
        key: FIVEPAISA_API_USER_KEY,
      },
      body: {
        ClientCode: brokerClientId,
        ...reqBody,
      },
    }),
  };
};

const getRequestUri = (method, qs = '', path = '') => {
  const baseUrl = REST_URLS().BROKER_PROXY;

  const queryString = qs ? `&${qs}` : '';
  const pathString = path ? `path = ${path}` : '';

  return `${baseUrl}?broker=fivepaisa&method=${method}${queryString}&${pathString}`;
};

const isValidAccessToken = async () => {
  const parameters = getApplicationHeaders();

  return wrappedFetch(getRequestUri('validateUser'), parameters)
    .then((response) => response.json())
    .then((result) => result?.body?.Status === 0)
    .catch(() => false);
};

const placeOrder = async (orderDetails) => {
  const fetchedOrderDetails = await Promise.all(
    _.map(orderDetails, async (orderConfig) => {
      const formattedConfig = await parserOrderDetails(orderConfig);
      const paramaters = getApplicationHeaders(formattedConfig);

      return wrappedFetch(getRequestUri('placeOrder'), paramaters)
        .then((res) => res.json())
        .then((result) => result?.body)
        .catch((e) => [e]);
    })
  );

  return _.chain(fetchedOrderDetails)
    .map(({ Status, BrokerOrderID }) => (Status === 0 ? BrokerOrderID : null))
    .value();
};

const fetchOrderBook = async () => {
  const paramaters = getApplicationHeaders();

  return wrappedFetch(getRequestUri('orderBook'), paramaters)
    .then((res) => res.json())
    .then(async (result) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(
            await parseOrderBook(result?.body?.OrderBookDetail),
            ['time'],
            ['desc']
          )
        ),
        headers: orderTableHeaders,
      };
    });
};

const fetchTradeBook = async () => {
  const parameters = getApplicationHeaders();

  return wrappedFetch(getRequestUri('tradeBook'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(
            await parseTradeBook(result?.body?.TradeBookDetail, 'trade'),
            ['time'],
            ['desc']
          )
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = async () => {
  const parameters = getApplicationHeaders();

  return wrappedFetch(getRequestUri('positionBook'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            await parsePositionBook(
              result?.body?.NetPositionDetail,
              'position'
            ),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const parameters = getApplicationHeaders();

  return wrappedFetch(getRequestUri('holdings'), parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        holdingBookDetails: _.compact(
          parseOrderDetails(result?.body?.Data, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const cancelPlaceOrder = ({ orderDetails }) => {
  const extraDetails = _.get(orderDetails, 'extraDetails', '');
  const exchOrderID = _.get(extraDetails, 'exchOrderID', '');
  const parameters = getApplicationHeaders({
    ExchOrderID: _.toString(exchOrderID),
  });
  return wrappedFetch(getRequestUri('cancelPlaceOrder'), parameters).then(
    (response) => response.json()
  );
};

export {
  isValidAccessToken,
  placeOrder,
  fetchOrderBook,
  fetchTradeBook,
  fetchPositionBook,
  fetchHoldingBook,
  cancelPlaceOrder,
};
