import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import { parseOrderDetails, parsePlaceOrder } from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import RestEndPoints from './RestEndPoints';

export const REQUEST_PHASE_URL = () => {
  const apiKey = getDynamicAppConfigs().envs.GOODWILL_API_KEY;

  return `${RestEndPoints.URI}?appcode=${apiKey}`;
};

const getHeaders = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { GOODWILL_API_KEY } = getDynamicAppConfigs().envs;

  return {
    'x-api-key': GOODWILL_API_KEY,
    Authorization: `Bearer ${brokerClientAccessToken}`,
  };
};

const isValidAccessToken = () => {
  const parameters = { headers: getHeaders() };

  const uri = RestEndPoints.PROFILE_PATH;
  return wrappedFetch(uri, parameters)
    .then((res) => {
      return res.ok;
    })
    .catch(() => false); // make false once worked
};

const fetchOrderBook = () => {
  const parameters = { headers: getHeaders() };

  const uri = RestEndPoints.GET_ORDER_BOOK;
  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then((result) => {
      const { data } = result;
      return {
        orderBookDetails: _.compact(_.orderBy(parseOrderDetails(data, 'order'), ['time'], ['desc'])),
        headers: orderTableHeaders,
      };
    });
};

const fetchTradeBook = () => {
  const parameters = { headers: getHeaders() };

  const uri = RestEndPoints.GET_TRADE_BOOK;
  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then((result) => {
      const { data } = result;

      return {
        tradeBookDetails: _.compact(_.orderBy(parseOrderDetails(data, 'trade'), ['time'], ['desc'])),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const parameters = { headers: getHeaders() };

  const uri = RestEndPoints.UPDATE_POSITION_BOOK;
  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then((result) => {
      const { data } = result;

      return {
        positionBookDetails: _.compact(_.orderBy(
          parseOrderDetails(data, 'position'),
          ['extraDetails.isOpenPosition'],
          ['desc']
        )),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const parameters = { headers: getHeaders() };

  const uri = RestEndPoints.GET_HOLDINGS;
  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then(({ data }) => {
      return {
        holdingBookDetails: _.compact(parseOrderDetails(data, 'holdings')),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const uri = RestEndPoints.PLACE_ORDER;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, (orderConfig) => {
      const bodyDetails = parsePlaceOrder(orderConfig);
      const parameters = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(bodyDetails)
      };

      return wrappedFetch(uri, parameters)
        .then((response) => response.json())
        .then((result) => result)
        .catch((e) => [e]);
    })
  );

  return _.map(formattedConfig, ({ data }) => data?.nstordno);
};

const cancelPlaceOrder = ({ orderNo }) => {
  const bodyDetails = {
    nstordno: orderNo,
  };

  const parameters = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(bodyDetails),
  };

  const uri = RestEndPoints.CANCEL_PLACE_ORDER;

  return wrappedFetch(uri, parameters).then((response) => response.json());
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  cancelPlaceOrder,
};
