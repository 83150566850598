export const orderTableHeaders = [
  { label: 'Instrument', key: 'instrument' },
  { label: 'Type', key: 'type' },
  { label: 'Buy/Sell', key: 'buySell' },
  { label: 'Quantity', key: 'extraDetails.qty' },
  { label: 'Traded Price', key: 'extraDetails.tradedPrice' },
  { label: 'LTP', key: 'ltp' },
  { label: 'Status', key: 'status' },
];

export const tradeTableHeaders = [
  { label: 'Instrument', key: 'instrument' },
  { label: 'Type', key: 'type' },
  { label: 'Buy/Sell', key: 'buySell' },
  { label: 'Qty', key: 'extraDetails.qty' },
  { label: 'Price', key: 'extraDetails.tradedPrice' },
  { label: 'LTP', key: 'ltp' },
  { label: 'Status', key: 'status' },
];

export const postitionTableHeaders = [
  { label: '', key: 'checkbox' },
  { label: 'Instrument', key: 'instrument' },
  { label: 'Type', key: 'extraDetails.type' },
  { label: 'Buy/Sell', key: 'buySell' },
  { label: 'Qty', key: 'qty' },
  { label: 'Avg Price', key: 'avgPrice' },
  { label: 'LTP', key: 'ltp' },
  { label: 'P/L', key: 'profitLoss' },
];

export const holdingTableHeaders = [
  { label: 'Instrument', key: 'instrument' },
  { label: 'Qty', key: 'extraDetails.quantity' },
  { label: 'Buy Avg', key: 'extraDetails.buyAverage' },
  { label: 'LTP', key: 'ltp' },
  { label: 'Buy Value', key: 'extraDetails.buyValue' },
  { label: 'P&L', key: 'profitLoss' },
  { label: 'Net Chg', key: 'extraDetails.netChg' },
  { label: 'Today P&L', key: 'extraDetails.todayProfitLoss' },
  { label: 'Today Net Chg', key: 'extraDetails.todayNetChg' },
];
