const baseURI = 'https://api-t1.fyers.in/api/v3';

const RestEndPoints = {
  GET_HOLDINGS: `${baseURI}/holdings`,
  GET_POSITION_BOOK: `${baseURI}/positions`,
  GET_TRADE_BOOK: `${baseURI}/tradebook`,
  GET_ORDER_BOOK: `${baseURI}/orders`,
  PROFILE_PATH: `${baseURI}/profile`,
  PLACE_ORDER: `${baseURI}/multi-order/sync`,
  CANCEL_ORDER: `${baseURI}/orders/sync`,
  MARGIN_CALCULATOR: 'https://api-t1.fyers.in/api/v3/multiorder/margin',
  AUTH_CODE_URL: `${baseURI}/generate-authcode`,
  VALIDATE_AUTH_CODE_URL: `${baseURI}/validate-authcode`,
};

export default RestEndPoints;
