import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import RestEndPoints from './RestEndPoints';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import { parseModifyOrder, parseOrderDetails, parsePlaceOrder } from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import qs from 'qs';

export const REQUEST_PHASE_URL = () => {
  // todo for mobile app
  return '';
};

const fetchAccessToken = () => {
  // todo for mobile app
  return {};
};

const authenticateUser = (loginCredentials) => {
  const { brokerClientId, brokerClientPassword } = loginCredentials;
  const { PRABHUDAS_API_KEY } = getDynamicAppConfigs().envs;

  const requestBody = {
    user_id: brokerClientId,
    login_type: 'PASSWORD',
    password: brokerClientPassword,
    second_auth_type: 'REGISTER',
    second_auth: '',
    api_key: PRABHUDAS_API_KEY,
    source: 'WEBAPI',
  };

  const parameters = {
    method: 'POST',
    headers: { 'x-api-key': PRABHUDAS_API_KEY },
    body: JSON.stringify(requestBody),
  };

  const uri = RestEndPoints.AUTHENTICATE_USER;
  return wrappedFetch(uri, parameters)
    .then((res) => res.json())
    .then((result) => {
      return { status: result?.status };
    })
    .catch((res) => {
      return { status: 'error', message: res?.apiResponse?.message };
    });
};

const sendOtp = (brokerClientId) => {
  const { PRABHUDAS_API_KEY } = getDynamicAppConfigs().envs;
  const requestBody = {
    user_id: brokerClientId,
    api_key: PRABHUDAS_API_KEY,
    source: 'WEBAPI',
  };

  const parameters = {
    method: 'POST',
    headers: { 'x-api-key': PRABHUDAS_API_KEY },
    body: JSON.stringify(requestBody),
  };

  const uri = RestEndPoints.SEND_OTP;
  return wrappedFetch(uri, parameters)
    .then((res) => res.ok)
    .catch(() => {
      return false;
    });
};

const isValidAccessToken = () => {
  const { PRABHUDAS_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    headers: {
      'x-api-key': PRABHUDAS_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
  };

  const uri = RestEndPoints.PROFILE_PATH;
  return wrappedFetch(uri, parameters)
    .then((res) => res.ok)
    .catch(() => {
      return false;
    });
};

const fetchOrderBook = () => {
  const { PRABHUDAS_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    headers: {
      'x-api-key': PRABHUDAS_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
  };

  const queryString = qs.stringify({ offset: 1, limit: 200 });
  const ApiUrl = `${RestEndPoints.GET_ORDER_BOOK}?${queryString}`;

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => ({
      orderBookDetails: _.compact(
        _.orderBy(parseOrderDetails(result?.data, 'order'), ['time'], ['desc'])
      ),
      headers: orderTableHeaders,
    }));
};

const fetchTradeBook = () => {
  const { PRABHUDAS_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    headers: {
      'x-api-key': PRABHUDAS_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
  };

  const queryString = qs.stringify({ offset: 1, limit: 200 });
  const ApiUrl = `${RestEndPoints.GET_TRADE_BOOK}?${queryString}`;

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result?.data, 'trade'),
            ['time'],
            ['desc']
          )
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const { PRABHUDAS_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    headers: {
      'x-api-key': PRABHUDAS_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
  };

  const ApiUrl = `${RestEndPoints.UPDATE_POSITION_BOOK}/all`;

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result?.data, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const { PRABHUDAS_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    headers: {
      'x-api-key': PRABHUDAS_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
  };

  const ApiUrl = RestEndPoints.GET_HOLDINGS;

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        holdingBookDetails: _.compact(
          parseOrderDetails(result?.data, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const ApiUrl = RestEndPoints.PLACE_ORDER;
  const { PRABHUDAS_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const orderDetails = await parsePlaceOrder(orderConfig);

      const parameters = {
        method: 'POST',
        headers: {
          'x-api-key': PRABHUDAS_API_KEY,
          Authorization: `Bearer ${brokerClientAccessToken}`,
        },
        body: JSON.stringify(orderDetails),
      };

      return wrappedFetch(ApiUrl, parameters)
        .then((response) => response.json())
        .catch((e) => [e]);
    })
  );

  return _.chain(formattedConfig)
    .map(({ status, data }) => (status === 'success' ? data?.orderId : null))
    .value();
};

const cancelPlaceOrder = ({ orderNo, tradingSymbolObj }) => {
  const { PRABHUDAS_API_KEY } = getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const exchangeSegement =
    tradingSymbolObj?.isEquity() === 'NSE' ? 'NSE_EQ' : 'NSE_FO';
  const ApiUrl = `${RestEndPoints.CANCEL_PLACE_ORDER}/${exchangeSegement}/${orderNo}`;

  const parameters = {
    method: 'DELETE',
    headers: {
      'x-api-key': PRABHUDAS_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
    redirect: 'follow',
  };

  return wrappedFetch(ApiUrl, parameters).then((response) => response.json());
};

const modifyPlaceOrder = (placeOrderConfigs) => {
  const { PRABHUDAS_API_KEY } = getDynamicAppConfigs().envs;

  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const orderConfig = placeOrderConfigs?.[0];
  const { tradingSymbolObj } = orderConfig;
  const exchangeSegement =
    tradingSymbolObj?.isEquity() === 'NSE' ? 'NSE_EQ' : 'NSE_FO';

  const orderDetails = parseModifyOrder(placeOrderConfigs?.[0]);
  const requestbody = JSON.stringify(orderDetails);

  const ApiUrl = `${RestEndPoints.MODIFY_ORDER}/${exchangeSegement}/${_.get(
    placeOrderConfigs?.[0],
    'orderNo',
    ''
  )}`;

  const parameters = {
    method: 'PUT',
    headers: {
      'x-api-key': PRABHUDAS_API_KEY,
      Authorization: `Bearer ${brokerClientAccessToken}`,
    },
    body: requestbody,
    redirect: 'follow',
  };

  return wrappedFetch(ApiUrl, parameters).then((response) => response.json());
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  fetchAccessToken,
  cancelPlaceOrder,
  sendOtp,
  modifyPlaceOrder,
  authenticateUser,
};
