import _ from 'lodash';
import Instrument from 'habitual-analytics/models/instrument/index';

export const parseOrderDetailToGetTradingSymbolObj = (orderDetail) => {
  const instrument = _.get(orderDetail, 'instrument', '');
  const isFutures = instrument.includes('FUT');
  const isOptions = instrument.includes('OPT');
  const symbol = _.get(orderDetail, 'symbol', '');
  const expiryDate = (isOptions || isFutures) ? _.get(orderDetail, 'expiry_date', '') : '';
  const strikePrice = isOptions ? _.get(orderDetail, 'strike_price', '').split('.')[0] : '';
  const optionType = isOptions ? _.get(orderDetail, 'option_type', '') : ''; 

  return new Instrument({
    symbol,
    expiryDate,
    strikePrice,
    optionType
  });
};
