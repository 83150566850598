import _ from 'lodash';
import Instrument from 'habitual-analytics/models/instrument/index';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';

export const parseOrderDetailToGetTradingSymbolObj = (orderDetail) => {
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const fetchedTokens = _.findKey(
    tradingSymbolsByExchangeTokens,
    (value, key) => key === orderDetail?.security_id
  );

  if (orderDetail?.instrument === 'EQUITY') {
    return new Instrument({
      symbol: tradingSymbolsByExchangeTokens[fetchedTokens],
    });
  } else {
    const instrument = _.get(orderDetail, 'instrument', '');
    const isFutures = _.includes(['FUTIDX', 'FUTSTK'], instrument);
    const isOptions = instrument.includes('OPTIDX');
    const symbol = _.get(orderDetail, 'display_name', '').split(' ')[0];
    const expiryDate =
      isOptions || isFutures ? _.get(orderDetail, 'expiry_date', '') : '';
    const strikePrice = isOptions ? _.get(orderDetail, 'strike_price', '') : '';
    const optionType = isOptions ? _.get(orderDetail, 'opt_type', '') : '';

    return new Instrument({
      symbol,
      expiryDate,
      strikePrice,
      optionType,
    });
  }
};
