import { useNavigate, createSearchParams } from 'react-router-dom';

export const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname, params) => {
    const queryString = _.compact([
      window.location.search,
      params
    ]).join('&');
    navigate({ pathname, search: `?${createSearchParams(queryString)}` });
  };
};
