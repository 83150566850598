import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  orderTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import {
  parseOrderBook,
  parseOrderDetails,
  parsePlaceOrder,
  parsePositionBook,
  parseCancelOrder,
} from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from 'habitual-analytics/api/resturls';

export const REQUEST_PHASE_URL = () => {
  // todo for mobile app
  return '';
};

const fetchAccessToken = () => {
  // todo for mobile app
  return {};
};

const getRequestUri = (method, qs = '', path = '') => {
  const baseUrl = REST_URLS().BROKER_PROXY;

  const queryString = qs ? `&${qs}` : '';
  const pathString = path ? `/${path}` : '';

  return `${baseUrl}${pathString}?broker=paytm_money&method=${method}${queryString}`;
};

const isValidAccessToken = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'POST',
    headers: { 'x-jwt-token': brokerClientAccessToken },
  };

  return wrappedFetch(getRequestUri('validateUser'), parameters)
    .then((res) => res.json())
    .then((result) => result?.data)
    .catch(() => {
      return false;
    });
};

const fetchOrderBook = async () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'POST',
    headers: { 'x-jwt-token': brokerClientAccessToken },
  };

  const orderBookResponse = await wrappedFetch(
    getRequestUri('orderBook'),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result?.data);

  const formattedOrderConfigs = await Promise.all(
    await parseOrderBook(orderBookResponse)
  );

  return {
    orderBookDetails: _.compact(
      _.orderBy(formattedOrderConfigs, ['time'], ['desc'])
    ),
    headers: orderTableHeaders,
  };
};

const fetchPositionBook = async () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'POST',
    headers: { 'x-jwt-token': brokerClientAccessToken },
  };

  const positionBookResponse = await wrappedFetch(
    getRequestUri('positionBook'),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result);

  const formattedOrderConfigs = await Promise.all(
    await parsePositionBook(positionBookResponse?.data)
  );

  return {
    positionBookDetails: _.compact(
      _.orderBy(
        formattedOrderConfigs,
        ['extraDetails.isOpenPosition'],
        ['desc']
      )
    ),
    headers: postitionTableHeaders,
  };
};

const fetchHoldingBook = async () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'POST',
    headers: { 'x-jwt-token': brokerClientAccessToken },
  };

  const holdingBookResponse = await wrappedFetch(
    getRequestUri('holdings'),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result?.data?.results);

  const formattedHoldingConfigs = await Promise.all(
    _.compact(parseOrderDetails(holdingBookResponse, 'holdings'))
  );

  return {
    holdingBookDetails: _.compact(formattedHoldingConfigs),
    headers: holdingTableHeaders,
  };
};

const placeOrder = async (placeOrderConfigs) => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const orderDetails = await parsePlaceOrder(orderConfig);

      const parameters = {
        method: 'POST',
        headers: { 'x-jwt-token': brokerClientAccessToken },
        body: JSON.stringify(orderDetails),
      };
      return wrappedFetch(getRequestUri('placeOrder'), parameters)
        .then((response) => response.json())
        .then(({ data }) => data?.[0]?.order_no)
        .catch((e) => [e]);
    })
  );

  return formattedConfig;
};

const cancelPlaceOrder = (orderDetail) => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  const cancelOrderDetails = parseCancelOrder(orderDetail);

  const parameters = {
    method: 'POST',
    headers: { 'x-jwt-token': brokerClientAccessToken },
    body: JSON.stringify(cancelOrderDetails),
  };

  return wrappedFetch(getRequestUri('cancelPlaceOrder'), parameters)
    .then((response) => response.json())
    .catch((e) => e);
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  fetchAccessToken,
  cancelPlaceOrder,
};
