import React, { useEffect, useState } from 'react';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';
import Image from 'globals/components/Image/index';
import { getParsedDateObj } from 'habitual-analytics/utils/timezone';
import { DATE_FORMAT } from 'habitual-analytics/dateUtils/dateFormats';

const MobilePopup = () => {
  const [showModal, setShowModal] = useState(false);
  const today = getCurrentDate().toISOString().slice(0, 10);

  useEffect(() => {
    const lastModalDate = localStorage.getItem('MobilePopupLastModalDate');

    const storedDate = getParsedDateObj(lastModalDate);
    const lastWeek = getCurrentDate().subtract(5, 'days').format(DATE_FORMAT);

    if (_.isEmpty(lastModalDate) || storedDate < lastWeek) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem('MobilePopupLastModalDate', today);
    setShowModal(false);
  };

  return (
    showModal && (
      <div className="mobile-popup">
        <button type="button" className="btn btn-link close" onClick={handleCloseModal}>
          <i className="fa-solid fa-xmark"></i>
        </button>
        <div className="d-flex flex-column align-items-center gap-2">
          <img src="/images/instaoptions-logo.svg" alt="Instaoptions" className="mobile-logo" />
          <h5 className="fw-bold">
            Download instaOptions
            {' '}
            <br />
            Official App
          </h5>
          <a
            target="blank"
            className="track"
            data-track-category="Mobile Popup"
            data-track-action="visit Playstore"
            data-track-label="visit Playstore"
            onClick={handleCloseModal}
            href="https://play.google.com/store/apps/details?id=com.instaoptions&hl=en&gl=US"
          >
            <Image src="./images/playstore.png" alt="playstore" className="playstore-btn" />
          </a>
        </div>
      </div>
    )
  );
};

export default MobilePopup;
